import { useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";

export function useAuthExpiry() {
  const { logout, getAccessTokenSilently } = useAuth0();

  const refreshAccessToken = useCallback(async () => {
    const token = localStorage.getItem("token");
    const decodedToken = jwtDecode(token);
    // const expiryTime = decodedToken.exp * 1000;
    const expiryTime = decodedToken.exp * 1000;
    console.log("expiryTime", expiryTime);

    const currentTime = new Date().getTime();
    console.log("currentTime", currentTime);

    console.log("time difference", expiryTime - currentTime);

    if (expiryTime !== null && currentTime > expiryTime) {
      try {
        const token = await getAccessTokenSilently();
        localStorage.setItem("token", token);
      } catch (error) {
        console.error("Error refreshing access token:", error);
        alert(
          "For security purposes, your session has ended. Please login again."
        );
        logout({ returnTo: window.location.origin });
      }
    }
  }, [getAccessTokenSilently, logout]);

  useEffect(() => {
    refreshAccessToken();
  }, [refreshAccessToken]);
}

// // Compare the expiry time to the current time
// const currentTime = new Date().getTime();
// console.log("currentTime", currentTime);
// // Expiry time is in the format of a date object (Tue May 14 2024 16:23:49 GMT-0600 (Mountain Daylight Time))
// // We need to convert it to an epoch to compare it to the current time
// let formattedExpiryTime = new Date(expiryTime);
// formattedExpiryTime = formattedExpiryTime.getTime();
// console.log("formattedExpiryTime", formattedExpiryTime);
// if (expiryTime !== null && currentTime > formattedExpiryTime) {
//     // The token has expired
//     alert("For security purposes, your session has ended. Please login again.")
//     console.log("token expired");
//     localStorage.clear();
//     sessionStorage.clear();
//     logout({
//         logoutParams: {
//             returnTo: "https://advisorx.ai",
//         }
//     })
// }
