import { Route } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppSelector } from "./hooks";

const ProtectedAuthRoute = ({ component: Component, ...rest }) => {
  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading,
  } = useAuth0();


  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return <div>Redirecting to login...</div>;
  }

  if (isAuthenticated) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
};

export default ProtectedAuthRoute;