import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'


interface ChartState {
    messageForChart: string | null
}

const initialState: ChartState = {
    messageForChart: null,
}

export const chartSlice = createSlice({
    name: 'chart',
    initialState,
    reducers: {
        setMessageForChart: (state, action: PayloadAction<string>) => {
            if (action.payload && action.payload.length > 0) {
                state.messageForChart = action.payload
            }

        },
    },
})


export const { setMessageForChart } = chartSlice.actions

export const selectMessageForChart = (state: RootState) => state.chart.messageForChart

export default chartSlice.reducer
