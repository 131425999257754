import React from "react";

import './InfoBox.css';

const InfoBox = ({ text, title, boldText, customStyle }) => {

    const theme = {theme: "light"}

    return (
        <div className={`info-box-container-${theme.theme}`} style={customStyle}>
            <p className={`semi-bold-font-${theme.theme} info-box-header-text`}>{title}</p>
            <p className={`regular-font-${theme.theme}`}>
                {text}
                <b>{boldText}</b>
            </p>
        </div>
    )

}

export default InfoBox;