import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Avatar, IconButton } from '@mui/material';
import { Upload, X } from 'lucide-react';

const ImageLogoInput = ({ onImageSelect, customStyle, isDisabled = false, themeOverride = null, brand_logo }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(brand_logo);

  const theme = {
    theme: "light",
    backgroundColor: "var(--off-white)",
    mainFontColor: "var(--off-black)",
    sidenavBackgroundColor: "#FFFFFF",
    headerBorder: "1px solid #e0e0e0",
  };
  const styleTheme = themeOverride || theme;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
      onImageSelect(file);
    }
  };

  const handleRemove = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
    onImageSelect(null);
  };

  const AvatarWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    width: '150px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
  }));

  const StyledAvatar = styled(Avatar)(({ theme }) => ({
    width: '40px',
    height: '40px',
    backgroundColor: styleTheme.sidenavBackgroundColor,
    border: styleTheme.theme === "light" ? '1px dashed var(--divider-line-gray)' : '1px dashed rgba(255, 255, 255, 0.3)',
    color: styleTheme.mainFontColor,
    '&:hover': {
      cursor: isDisabled ? 'not-allowed' : 'pointer',
    },
  }));

  const UploadOverlay = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: '#fff',
    opacity: 0,
    transition: 'opacity 0.3s',
    '&:hover': {
      opacity: 1,
    },
  }));

  const FileNameText = styled('span')(({ theme }) => ({
    marginLeft: '10px',
    fontSize: '14px',
    color: styleTheme.mainFontColor,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '90px',
  }));

  const RemoveButton = styled(IconButton)(({ theme }) => ({
    padding: '4px',
    marginLeft: '5px',
    backgroundColor: styleTheme.sidenavBackgroundColor,
    color: styleTheme.mainFontColor,
    '&:hover': {
      backgroundColor: styleTheme.theme === "light" ? 'var(--off-white)' : 'rgba(255, 255, 255, 0.08)',
    },
  }));

  return (
    <AvatarWrapper style={customStyle}>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="logo-file-input"
        type="file"
        onChange={handleFileChange}
        disabled={isDisabled}
      />
      <label htmlFor="logo-file-input">
        <StyledAvatar src={previewUrl}>
          {!previewUrl && <Upload size={20} />}
        </StyledAvatar>
        {!isDisabled && !previewUrl && (
          <UploadOverlay>
            <Upload size={20} />
          </UploadOverlay>
        )}
      </label>
      {selectedFile && (
        <FileNameText>{selectedFile.name}</FileNameText>
      )}
      {previewUrl && !isDisabled && (
        <RemoveButton onClick={handleRemove} size="small">
          <X size={16} />
        </RemoveButton>
      )}
    </AvatarWrapper>
  );
};

export default ImageLogoInput;