import React, { useState } from "react";
import Modal from "react-modal";
import "./ViewArchive.css";
import Markdown from "../Markdown";

import { highlightNonCompliantWords } from "../../logic/chat.tsx";

Modal.setAppElement("#root"); // Replace '#root' with your app root element ID

const ViewArchive = ({
  isOpen,
  onRequestClose,
  title,
  date,
  content,
  review,
}) => {

  const [contentOrReview, setContentOrReview] = useState("content");
  
  // Highlight non-compliant words in the content
  const formattedContent = highlightNonCompliantWords(content);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Prompts Dialog"
    >
      <div className="archieve-dialog">
        <div className="archieves-header-wrapper">
          <span>{title}</span>
          <br />
          <span>{date}</span>
        </div>

        <div style={{ display: "flex" }}>
          <div className="archieve-sidebar">
            <div style={{ display: "flex", gap: "10px" }}>
              <div
                className={`archieves-selected-prompt ${
                  contentOrReview === "content" ? "selected" : ""
                }`}
                onClick={() => setContentOrReview("content")}
              >
                Content
              </div>
              <div
                className={`archieves-selected-prompt ${
                  contentOrReview === "review" ? "selected" : ""
                }`}
                onClick={() => setContentOrReview("review")}
              >
                Review
              </div>
            </div>

            <div className="archieves-selected-wrapper">
              <div className="archieves-selected-title">
                <Markdown
                  markdownContent={
                    contentOrReview === "content" ? formattedContent : review
                  }
                  className={`regular-font-light document-review-result-text`}
                />
              </div>
            </div>
          </div>
          <button onClick={onRequestClose} className="close-modal-button">
            X
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ViewArchive;
