import React from "react";
import { ReactComponent as SuccessIcon } from "../../assets/icons/toast/success.svg";
import { ReactComponent as ErrorIcon } from "../../assets/icons/toast/error.svg";
import "./Toast.css";

const Toast = ({ toastInfo, error,isVisible }) => {
  const toastStyle = error ? 'error' : 'success';
  const toastClasses = `toast-body ${isVisible ? 'show' : ''} ${toastStyle}`;
  return (
    <div className={toastClasses}>
      <div className="toast-wrapper">
        {error ? <ErrorIcon /> : <SuccessIcon />}
        <p
          className="ml-2 m-0 align-self-center"
          dangerouslySetInnerHTML={{ __html: toastInfo }}
        ></p>
      </div>
    </div>
  );
};

export default Toast;
