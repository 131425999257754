import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as knowledgeBaseServices from "../services/knowledge";

import { IconContext } from "react-icons";
import { BiLeftArrowAlt } from "react-icons/bi";
import { IoIosSettings } from "react-icons/io";
import { Tooltip } from "@mui/material";
import { FaTrashAlt } from "react-icons/fa";

import { CustomAddButton } from "../components/CustomButton";
import DeleteConfirmationModal from "../components/DeleteConfirmationModal";
import { FileList } from "../components/FileList";
import WorkflowConfigModal from "../components/WorkflowConfigModal";
import { useWorkflowHandler, convertTimeframeDaysToString, formatTimeframeStringForDisplay, formatDomainsForDisplay } from "../logic/workflow";

import { capitalizeFirstLetter, formatModelName } from "../utils/generalFunctions";
import BouncingDots from "../components/Loaders/BouncingDots/BouncingDots";
import Sidebar from "../components/Sidebar/Sidebar.js";
import { useAppSelector } from "../hooks.ts";
import { useAuthExpiry } from "../logic/auth";
//import { KnowledgeBaseType } from "../utils/knowledge_bases.ts";
import "./EditKnowledgeBase.css";


const EditKnowledgeBase = () => {

  useAuthExpiry();

  const token = sessionStorage.getItem('token');

  const history = useHistory();
  const { workflowId } = useParams();
  const data = useAppSelector((state) => state.auth.user);

  const [knowledgeBaseId, setKnowledgeBaseId] = useState("");
  const [knowledgeBaseFiles, setKnowledgeBaseFiles] = useState([]);
  const idToken = useAppSelector((state) => state.auth.idToken);

  const [showEditWorkflowModal, setShowEditWorkflowModal] = useState(false);
  const [showDeleteWorkflowModal, setShowDeleteWorkflowModal] = useState(false);

  // Workflow state variables
  const [workflow, setWorkflow] = useState({});

  const { getWorkflow, editWorkflow, deleteWorkflow } = useWorkflowHandler(token);

  async function getData() {
    setIsLoading(true);

    const workflowData = await getWorkflow(workflowId);
    setWorkflow(workflowData);
    console.log("workflowData", workflowData)
    const kbID = workflowData.knowledge_base_id;
    setKnowledgeBaseId(kbID);

    let nextPageToken = null;
    let allDocuments = [];

    let [response, status] = await knowledgeBaseServices.getAllDocumentsInKnowledgeBase(idToken, kbID, nextPageToken);
    allDocuments = response.documents;
    nextPageToken = response.next_page_token;

    while (nextPageToken !== null) {
      const [nextResponse, nextStatus] = await knowledgeBaseServices.getAllDocumentsInKnowledgeBase(idToken, kbID, response.next_page_token);
      allDocuments = allDocuments.concat(nextResponse.documents);
      nextPageToken = nextResponse.next_page_token;
    }
    
    if (status == 200 || status == 204) {
      console.log(allDocuments, "response.documents");
      setNumDocuments(allDocuments.length);
      setKnowledgeBaseFiles(allDocuments);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      alert("Error fetching data");
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [numDocuments, setNumDocuments] = useState(0);
  async function deleteDocuments(filesToDelete) {
    // set
    setIsLoading(true);
    for (let i = 0; i < filesToDelete.length; i++) {
      const fileID = filesToDelete[i];
      const status = await knowledgeBaseServices.deleteDocument(
        idToken,
        knowledgeBaseId,
        fileID
      );
    }
    await getData();
  }

  const [selectedFiles, setSelectedFiles] = useState({});
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  function handleCheckboxClick(fileID) {
    setSelectedFiles({ ...selectedFiles, [fileID]: !selectedFiles[fileID] });
    if (selectedFiles[fileID]) {
      setFilesToDelete(filesToDelete.filter((file) => file !== fileID));
    } else {
      setFilesToDelete([...filesToDelete, fileID]);
    }
  }

  function createSelectedFilesObject(allFiles = []) {
    let selectedFilesObject = {};
    if (allFiles && allFiles.length > 0) {
      allFiles.forEach((file) => {
        selectedFilesObject[file.id] = false;
      });
      setSelectedFiles(selectedFilesObject);
    }
  }

  useEffect(() => {
    createSelectedFilesObject(knowledgeBaseFiles);
  }, [knowledgeBaseFiles]);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Sidebar currentPage={"personas"} />
      <div className="knowledge-base-section">
        <div className="knowledge-bases-container">
          {isLoading && <BouncingDots />}

          {showDeleteConfirmation && (
            <DeleteConfirmationModal
              title={"Delete documents"}
              confirmationText={
                "Are you sure you want to delete the selected documents?"
              }
              closeModal={() => setShowDeleteConfirmation(false)}
              onCancel={() => setShowDeleteConfirmation(false)}
              delete={() => {
                deleteDocuments(filesToDelete);
                setShowDeleteConfirmation(false);
                setFilesToDelete([]);
              }}
            />
          )}

          {showDeleteWorkflowModal && (
            <DeleteConfirmationModal
              title={"Delete workflow"}
              confirmationText={
                "Are you sure you want to delete this workflow"
              }
              closeModal={() => setShowDeleteWorkflowModal(false)}
              onCancel={() => setShowDeleteWorkflowModal(false)}
              delete={() => {
                deleteWorkflow(workflow._id);
                setShowDeleteWorkflowModal(false);
                history.goBack();
              }}
            />
          )}


          {showEditWorkflowModal &&
            <WorkflowConfigModal
              isOpen={showEditWorkflowModal}
              closeModal={() => setShowEditWorkflowModal(false)}
              onClick={(name, description, updatedResponseConfig, updatedWebSearchConfig) => {
                editWorkflow(workflow._id, name, description, updatedResponseConfig, updatedWebSearchConfig, setWorkflow);
                setShowEditWorkflowModal(false)
              }}
              nameInput={workflow.name}
              descriptionInput={workflow.description}
              responseConfig={{
                model: workflow.model,
                systemMessage: workflow.system_message,
                writingStyle: workflow.writing_style,
                responseLength: workflow.response_length,
              }}
              webSearchConfig={{
                useWebSearch: workflow.use_web_search,
                web_search_preset: workflow.web_search_preset
              }}
              createOrUpdate={"update"}
            />
          }

          <div className="edit-knowledge-base-header-container">
            <div className="edit-knowledge-base-header-row">
              <div
                className="edit-knowledge-base-back-container"
                onClick={() => history.goBack()}
              >
                <IconContext.Provider
                  value={{
                    className: `edit-knowledge-base-back-arrow-light`,
                  }}
                >
                  <BiLeftArrowAlt />
                </IconContext.Provider>
                <p className={`edit-knowledge-base-back-text-light`}>Back</p>
              </div>

              <div className="edit-knowledge-base-title-container">
                <p className={`bold-font-larger-light`}>{workflow.name}</p>
              </div>

              <div className="edit-workflow-icon-container">
                <div>
                  <Tooltip
                    arrow
                    title="Edit Workflow"
                    placement="top"
                  >
                    <div>
                      <IoIosSettings id="ai-settings-icon" onClick={() => setShowEditWorkflowModal(true)} />
                    </div>
                  </Tooltip>
                </div>
              </div>

              <div>
                <Tooltip
                  arrow
                  title="Delete Workflow"
                  placement="top"
                >
                  <div>
                    <FaTrashAlt id="workflow-delete-icon" onClick={() => setShowDeleteWorkflowModal(true)} />
                  </div>
                </Tooltip>
              </div>

            </div>

            {/*<div className="edit-knowledge-base-header-row">
              <div className="edit-knowledge-base-title-container">
                <div>

                </div>
              </div>

              <div className="edit-knowledge-base-add-files-container">
                <CustomAddButton
                  buttonText={"Add documents"}
                  onClick={() => {
                    console.log("navigating");
                    history.push(`/document-upload/${workflow.knowledge_base_id}`);
                  }}
                />
              </div>
                </div>*/}
          </div>

          <div className="edit-knowledge-base-info-container">
            <div className="knowledge-base-files-container">
              <div className="knowledge-base-files-container-header">
                <div className="knowledge-base-files-document-header">
                  <p
                    className={`semi-bold-font-light`}
                    style={{ fontSize: "18px" }}
                  >
                    {`${numDocuments} Data Source${numDocuments === 1 ? "" : "s"}`}
                  </p>

                  <div className="edit-knowledge-base-add-files-container">
                    <CustomAddButton
                      buttonText={"Add data sources"}
                      onClick={() => {
                        console.log("navigating");
                        history.push(`/document-upload/${workflow.knowledge_base_id}`);
                      }}
                    />
                  </div>

                </div>
                {filesToDelete.length > 0 ? (
                  <div className="knowledge-base-select-delete-container">
                    <p
                      className="knowledge-base-delete-files-text"
                      onClick={() => setShowDeleteConfirmation(true)}
                    >
                      {"Delete documents"}
                    </p>
                  </div>
                ) : (
                  <div className="knowledge-base-select-delete-container"></div>
                )}
              </div>
              {numDocuments > 0 && <FileList
                files={isLoading ? [] : knowledgeBaseFiles}
                selectedFiles={selectedFiles}
                handleCheckboxClick={(fileID) => handleCheckboxClick(fileID)}
                onViewFileClick={(fileID, knowledgeBaseID) =>
                  console.log(fileID, knowledgeBaseID)
                }
                onEditFileClick={(fileID) => console.log(fileID)}
              />}
            </div>
          </div>

          <DividerLine />

          <div className="workflow-system-message-container">
            <p className="workflow-section-header-text">Model</p>
            <div>
              <p className="workflow-section-text">
                {formatModelName(workflow.model)}
              </p>
            </div>
          </div>

          <DividerLine />

          <div className="workflow-system-message-container">
            <p className="workflow-section-header-text">Response length</p>
            <div>
              <p className="workflow-section-text">
                {workflow.responseLength === undefined ? "" : capitalizeFirstLetter(workflow.responseLength)}
              </p>
            </div>
          </div>

          <DividerLine />

          <div className="workflow-system-message-container">
            <p className="workflow-section-header-text">System Message</p>
            <div>
              <p className="workflow-section-text">
                {workflow.system_message}
              </p>
            </div>
          </div>

          <DividerLine />

          <div className="workflow-writing-style-container">
            <p className="workflow-section-header-text">Writing Style</p>
            <div>
              <p className="workflow-section-text">
                {workflow.writing_style}
              </p>
            </div>
          </div>

          <DividerLine />

          <div className="workflow-writing-style-container">
            <p className="workflow-section-header-text">Live Data Feed Config</p>
            <div className="workflow-web-search-row">
              <p className="workflow-section-text">
                Use Live Data Feed:
              </p>
              <p className="workflow-section-text">
                {workflow.use_web_search ? " Yes" : " No"}
              </p>
            </div>

            <div className="workflow-web-search-row">
              <p className="workflow-section-text">
                {"Live Data Feed Timeframe: "}
              </p>
              <p className="workflow-section-text">
                {workflow.web_search_preset ? formatTimeframeStringForDisplay(convertTimeframeDaysToString(workflow.web_search_preset.timeframe_days)) : "N/A"}
              </p>
            </div>

            {/*<div className="workflow-web-search-row">
              <p className="workflow-section-text">
                {"Include domains: "}
              </p>
              <p className="workflow-section-text">
                {workflow.web_search_preset ? formatDomainsForDisplay(workflow.web_search_preset.include_domains) : "N/A"}
              </p>
              </div>*/}

            {/*<div className="workflow-web-search-row">
              <p className="workflow-section-text">
                {"Exclude domains: "}
              </p>
              <p className="workflow-section-text">
                {workflow.web_search_preset ? formatDomainsForDisplay(workflow.web_search_preset.exclude_domains) : "N/A"}
              </p>
              </div>*/}

          </div>

        </div>
      </div>
    </div >
  );
};


const DividerLine = () => {
  return (
    <div className="divider-line" />
  );
}

export default EditKnowledgeBase;
