import React, { useState, useEffect } from 'react';
import { IconContext } from 'react-icons';
import { IoSearch } from "react-icons/io5";
import { AiFillMessage } from "react-icons/ai";
import { HiDocumentArrowDown } from "react-icons/hi2";
import { BsInfoCircleFill } from "react-icons/bs";

import CustomDropdown from './CustomDropdown';
import CustomTextInput from './CustomTextInput';
import CustomTextArea from './CustomTextArea';
import { CustomButton } from './CustomButton';
import CustomModal from './CustomModal';
import { InfoIconWithText } from './InfoIcon';
import CustomSwitch from './IosSwitch.tsx';

import BouncingDots from "../components/Loaders/BouncingDots/BouncingDots";
import { FileList } from "../components/FileList";
import * as knowledgeBaseServices from "../services/knowledge";
import { useWorkflowHandler, convertTimeframeStringToDays, convertTimeframeDaysToString } from '../logic/workflow';

import './WorkflowConfigModal.css';

const WorkflowConfigModal = ({
    closeModal, isOpen, onClick, responseConfig = {}, webSearchConfig = {}, nameInput = "", descriptionInput = "",
    createOrUpdate = "create"
}) => {

    const theme = { theme: "dark" };

    const [buttonDisabled, setButtonDisabled] = useState(true);

    const [name, setName] = useState(nameInput);
    const [description, setDescription] = useState(descriptionInput);

    // Response config
    const [model, setModel] = useState(responseConfig.model == undefined ? "claude-3-opus" : responseConfig.model);
    const [responseLength, setResponseLength] = useState(responseConfig.responseLength == undefined ? "medium" : responseConfig.responseLength);
    const [systemMessage, setSystemMessage] = useState(responseConfig.systemMessage == undefined ? "" : responseConfig.systemMessage);
    const [writingStyle, setWritingStyle] = useState(responseConfig.writingStyle == undefined ? "" : responseConfig.writingStyle);

    // Web search config
    const [useWebSearch, setUseWebSearch] = useState(webSearchConfig.useWebSearch === undefined ? true : webSearchConfig.useWebSearch);
    let timeframeInput = webSearchConfig.web_search_preset === undefined ? 365 : webSearchConfig.web_search_preset.timeframe_days;
    // Format the timeframe days into a string
    timeframeInput = timeframeInput === undefined ? 365 : timeframeInput;
    timeframeInput = convertTimeframeDaysToString(timeframeInput);
    const [timeframe, setTimeframe] = useState((timeframeInput === null || timeframeInput === undefined) ? "past_year" : timeframeInput);

    // Make sure the include and exclude domains are strings (if they aren't null or undefined)
    let formattedIncludeDomains = (webSearchConfig.web_search_preset === undefined || webSearchConfig.web_search_preset === null) ? null : webSearchConfig.web_search_preset.include_domains;
    let formattedExcludeDomains = (webSearchConfig.web_search_preset === undefined || webSearchConfig.web_search_preset === null) ? null : webSearchConfig.web_search_preset.exclude_domains;
    if (formattedIncludeDomains !== null && formattedIncludeDomains !== undefined) {
        // Check if it is an array, and if so, turn it into a list
        if (Array.isArray(formattedIncludeDomains)) {
            formattedIncludeDomains = formattedIncludeDomains.join(", ");
        }
        // Strip any extra whitespace (so if there are 2 spaces)
        formattedIncludeDomains = formattedIncludeDomains.replace(/\s+/g, ' ').trim();
    }
    if (formattedExcludeDomains !== null && formattedExcludeDomains !== undefined) {
        // Check if it is an array, and if so, turn it into a list
        if (Array.isArray(formattedExcludeDomains)) {
            formattedExcludeDomains = formattedExcludeDomains.join(", ");
        }
        // Strip any extra whitespace (so if there are 2 spaces)
        formattedExcludeDomains = formattedExcludeDomains.replace(/\s+/g, ' ').trim();
    }


    const [includeDomains, setIncludeDomains] = useState(formattedIncludeDomains);
    const [excludeDomains, setExcludeDomains] = useState(formattedExcludeDomains);

    const [tabToShow, setTabToShow] = useState("info");

    // Set the default include or exclude value
    const defaultIncludeOrExclude = (webSearchConfig.excludeDomains !== null && webSearchConfig.excludeDomains !== undefined) ? "exclude" : "include";
    const [showIncludeOrExclude, setShowIncludeOrExclude] = useState(defaultIncludeOrExclude);


    function updateSettings() {

        let updatedResponseConfig = {
            model: model,
            responseLength: responseLength,
            systemMessage: systemMessage,
            writingStyle: writingStyle
        }
        let updatedWebSearchConfig = {
            useWebSearch: useWebSearch,
            includeDomains: includeDomains,
            excludeDomains: excludeDomains,
            timeframe: timeframe,
        }

        onClick(name, description, updatedResponseConfig, updatedWebSearchConfig);

    }

    useEffect(() => {
        if (name.length > 0 && systemMessage.length > 0) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [name, systemMessage])

    return (
        <CustomModal closeModal={closeModal} isOpen={isOpen}
            title={createOrUpdate === "create" ? "Create Persona" : "Update Persona"}
            customModalContainerStyle={{ maxWidth: "1000px", width: "100%", height: "90vh", maxHeight: "750px", overflow: "hidden", paddingTop: "15px", paddingBottom: "10px" }}
            customMainColStyle={{ paddingTop: "0px", marginTop: "-10px", paddingLeft: "0px", paddingBottom: "10px", height: "100%", overflow: "hidden" }}
        >

            <div className="ai-settings-modal-section">

                <div className="ai-settings-modal-container">

                    <div className="ai-settings-sidebar-col">

                        <div className={`ai-settings-sidebar-row-${theme.theme}${tabToShow === "info" ? "-selected" : ""}`}
                            onClick={() => setTabToShow("info")}>
                            <IconContext.Provider
                                value={{ className: (`ai-settings-icon-${theme.theme}`) }}>
                                <div>
                                    <BsInfoCircleFill />
                                </div>
                            </IconContext.Provider>
                            <p className={`ai-settings-sidebar-text-${theme.theme}`}>
                                Info
                            </p>
                        </div>
                        <div className={`ai-settings-sidebar-row-${theme.theme}${tabToShow === "response" ? "-selected" : ""}`}
                            onClick={() => setTabToShow("response")}>
                            <IconContext.Provider
                                value={{ className: (`ai-settings-icon-${theme.theme}`) }}>
                                <div>
                                    <AiFillMessage />
                                </div>
                            </IconContext.Provider>
                            <p className={`ai-settings-sidebar-text-${theme.theme}`}>
                                Response Config
                            </p>
                        </div>
                        <div className={`ai-settings-sidebar-row-${theme.theme}${tabToShow === "webSearch" ? "-selected" : ""}`}
                            onClick={() => setTabToShow("webSearch")}>
                            <IconContext.Provider
                                value={{ className: (`ai-settings-icon-${theme.theme}`) }}>
                                <div>
                                    <IoSearch />
                                </div>
                            </IconContext.Provider>
                            <p className={`ai-settings-sidebar-text-${theme.theme}`}>
                                Live Data Feed
                            </p>
                        </div>
                    </div>

                    <div className="ai-settings-main-col">

                        {tabToShow === "info" &&
                            <NameAndDescription
                                name={name}
                                setName={setName}
                                description={description}
                                setDescription={setDescription}
                            />
                        }

                        {tabToShow === "response" &&
                            <ResponseConfig
                                model={model}
                                setModel={setModel}
                                responseLength={responseLength}
                                setResponseLength={setResponseLength}
                                systemMessage={systemMessage}
                                setSystemMessage={setSystemMessage}
                                writingStyle={writingStyle}
                                setWritingStyle={setWritingStyle}
                            />
                        }

                        {tabToShow === "webSearch" &&
                            <WebSearchConfig
                                useWebSearch={useWebSearch}
                                setUseWebSearch={setUseWebSearch}
                                includeDomains={includeDomains}
                                setIncludeDomains={setIncludeDomains}
                                excludeDomains={excludeDomains}
                                setExcludeDomains={setExcludeDomains}
                                timeframe={timeframe}
                                setTimeframe={setTimeframe}
                                showIncludeOrExclude={showIncludeOrExclude}
                                setShowIncludeOrExclude={setShowIncludeOrExclude}
                            />
                        }

                    </div>

                </div>

                <div className="ai-settings-update-button-container">
                    <CustomButton
                        disabled={buttonDisabled}
                        buttonText={createOrUpdate === "create" ? "Create" : "Update"}
                        onClick={() => updateSettings()}
                        customStyle={{ width: "650px" }}
                    />
                    {createOrUpdate === "create" && <p className="add-documents-next-text">
                        *Note: You will be able to add data sources in the next step
                    </p>}
                </div>

            </div>

        </CustomModal>
    )

}


export const NameAndDescription = ({ name, setName, description, setDescription }) => {

    return (
        <div style={{ width: "100%" }}>
            <div className="llm-configuration-modal-options" style={{ width: "100%" }}>

                <div style={{ width: "100%" }}>
                    <InfoIconWithText
                        text={"Name"}
                        helpBoxTitle={"Name"}
                        helpBoxText={""}
                    />
                    <CustomTextInput
                        text={name}
                        sendDataToParent={(text) => setName(text)}
                    />
                </div>

            </div>

            <div className="llm-configuration-modal-message-container">
                <InfoIconWithText
                    text={"Description (optional)"}
                    helpBoxTitle={"Description"}
                    helpBoxText={"Give a description of what is contained in the data sources uploaded for this persona."}
                />
                <CustomTextArea
                    placeholderText={`Knowledge base descriptions are extremely important because they are the main piece of information (alongside the user input) to generate search queries over your data sources. It should provide:

1: A detailed overview of the contents of the knowledge base
 - This should be at least a few sentences long, but 1-2 paragraphs is even better.

2: Instructions and examples on how to best query the knowledge base. Try a variety of different queries and see what kinds give the best search results for your use case.`}
                    rows={10}
                    maxHeight={300}
                    sendDataToParent={(text) => setDescription(text)}
                    text={description}
                    minHeight={210}
                />
            </div>

        </div>
    )

}



export const ResponseConfig = ({
    model, setModel, responseLength, setResponseLength, systemMessage, setSystemMessage, writingStyle, setWritingStyle
}) => {

    const models = [
        { name: "GPT-4o", value: "gpt-4" },
        { name: "Claude 3 Haiku", value: "claude-3-haiku" },
        { name: "Claude 3.5 Sonnet", value: "claude-3-sonnet" },
        { name: "Claude 3 Opus", value: "claude-3-opus" },
        { name: "Mixtral", value: "mixtral" },
        { name: "Mistral Small", value: "mistral-small" },
        { name: "Mistral Medium", value: "mistral-medium" },
        { name: "Mistral Large", value: "mistral-large" }
    ]

    return (
        <div>
            <div className="llm-configuration-modal-options">

                <div className="llm-configuration-modal-dropdown">
                    <InfoIconWithText
                        text={"Model"}
                        helpBoxTitle={"Model"}
                        helpBoxText={"Choose the model you want to use for generating the response."}
                    />
                    <CustomDropdown
                        customStyle={{ width: 180 }}
                        valueList={models.map((model) => model.value)}
                        textList={models.map((model) => model.name)}
                        value={model}
                        sendDataToParent={(value) => setModel(value)}
                    />
                </div>

                <div className="llm-configuration-modal-dropdown">
                    <InfoIconWithText
                        text={"Response length"}
                        helpBoxTitle={"Response length"}
                        helpBoxText={"This parameter is used to control the final length of the response generated by the LLM"}
                        customInfoBoxStyle={{ top: "40px", left: "-50px" }}
                    />
                    <CustomDropdown
                        valueList={["short", "medium", "long"]}
                        textList={["Short", "Medium", "Long"]}
                        value={responseLength == undefined ? "medium" : responseLength}
                        sendDataToParent={(val) => setResponseLength(val)}
                        customStyle={{ width: 150 }}
                    />
                </div>

            </div>

            <div className="llm-configuration-modal-message-container">
                <InfoIconWithText
                    text={"Persona Instructions"}
                    helpBoxTitle={"Persona Instructions"}
                    helpBoxText={"Write your firm's investment thesis or investment philosophy, or any other message you want the AI to have access to when generating content."}
                />
                <CustomTextArea
                    placeholderText={"Tell the AI how you want it to respond"}
                    rows={6}
                    maxHeight={300}
                    sendDataToParent={(text) => setSystemMessage(text)}
                    text={systemMessage}
                />
            </div>

            <div className="llm-configuration-modal-message-container">
                <InfoIconWithText
                    text={"Writing style (optional)"}
                    helpBoxTitle={"Writing style"}
                    helpBoxText={"Insert 1-2 pages of sample text in the style you want the content to be converted into."}
                />
                <CustomTextArea
                    placeholderText={"Insert 1-2 pages of sample text in the style you want the content to be converted into."}
                    rows={6}
                    maxHeight={300}
                    sendDataToParent={(text) => setWritingStyle(text)}
                    text={writingStyle}
                />
            </div>

        </div>
    )

}


export const RetrievalConfig = ({ autoQueryGuidance, setAutoQueryGuidance }) => {

    return (
        <div>

            <div className="llm-configuration-modal-options">
                <div className="llm-configuration-modal-message-container">
                    <InfoIconWithText
                        text={"Persona Data Sources Search (optional)"}
                        helpBoxTitle={"Persona Data Sources Search"}
                        helpBoxText={
                            "This lets you add a more targeted search over data sources you uploaded to your selected persona so the AI has the precise context of what keywords or specific data sources to look for."
                        }
                    />
                    <CustomTextArea
                        placeholderText={"Search over specific data sources from your selected persona."}
                        rows={6}
                        maxHeight={300}
                        sendDataToParent={(text) => setAutoQueryGuidance(text)}
                        text={autoQueryGuidance}
                    />
                </div>
            </div>
        </div>
    )

}


export const WebSearchConfig = ({
    useWebSearch, setUseWebSearch, includeDomains, setIncludeDomains,
    excludeDomains, setExcludeDomains, timeframe, setTimeframe, showIncludeOrExclude, setShowIncludeOrExclude
}) => {

    const theme = { theme: "dark" };
    console.log("timeframe", timeframe)

    return (
        <div>

            <div className="web-search-config-modal-input-container">

                <div className="llm-configuration-modal-dropdown">
                    <InfoIconWithText
                        text={"Use Live Data Feed"}
                        helpBoxTitle={"Use Live Data Feed"}
                        helpBoxText={"Use the web search feature to retrieve information from the internet."}
                        customInfoBoxStyle={{ top: "0px" }}
                    />
                    <div style={{ marginTop: "10px" }}>
                        <CustomSwitch
                            checked={useWebSearch}
                            onChange={() => setUseWebSearch(!useWebSearch)}
                        />
                    </div>
                </div>
            </div>


            {useWebSearch && <div>

                {/*<div className="web-search-config-modal-input-container" style={{ marginTop: "25px" }}>
                    <p className={`regular-font-${theme.theme}`}>Site filter</p>
                    <div className="include-exclude-toggle-container">
                        <div className={`include-exclude-toggle-${theme.theme}${showIncludeOrExclude === "include" ? "-selected" : ""}`}
                            onClick={() => setShowIncludeOrExclude("include")}>
                            <p className={`include-exclude-font-${theme.theme}`}>Include</p>
                        </div>
                        <p className={`web-search-or-font-${theme.theme}`}>OR</p>
                        <div className={`include-exclude-toggle-${theme.theme}${showIncludeOrExclude === "exclude" ? "-selected" : ""}`}
                            onClick={() => setShowIncludeOrExclude("exclude")}>
                            <p className={`include-exclude-font-${theme.theme}`}>Exclude</p>
                        </div>
                    </div>
            </div>*/}

                {/*<div className="create-new-knowledge-base-modal-input-container">

                    {showIncludeOrExclude === "include" && <div className="create-new-web-search-modal-col">
                        <InfoIconWithText
                            text={"Include domains (optional)"}
                            helpBoxTitle={"Include domains"}
                            helpBoxText={"A list of domains to constrain the search to, in a comma separated list. If left blank, the search will include all domains."}
                            customInfoBoxStyle={{ width: "350px" }}
                            customTextStyle={{ fontSize: "12px", color: "var(--medium-gray)", marginTop: "5px", marginBottom: "2px" }}
                        />
                        <CustomTextInput
                            placeholderText={"Acme.inc, example.com"}
                            text={includeDomains}
                            sendDataToParent={(text) => setIncludeDomains(text)}
                            rows={1} />
                    </div>}

                    {showIncludeOrExclude === "exclude" && <div className="create-new-web-search-modal-col">
                        <InfoIconWithText
                            text={"Exclude domains (optional)"}
                            helpBoxTitle={"Exclude domains"}
                            helpBoxText={"Domains to exclude from the web search, in a comma separated list. If left blank, the search will include all domains."}
                            customInfoBoxStyle={{ width: "350px" }}
                            customTextStyle={{ fontSize: "12px", color: "var(--medium-gray)", marginTop: "5px", marginBottom: "2px" }}
                        />
                        <CustomTextInput
                            placeholderText={"blacklist.com, blog.blacklist.com"}
                            text={excludeDomains}
                            sendDataToParent={(text) => setExcludeDomains(text)}
                            rows={1} />
                    </div>}
            </div>*/}


                <div className="workflow-modal-web-search-input-container">

                    <div className="llm-configuration-modal-dropdown">
                        <InfoIconWithText
                            text={"Timeframe (optional)"}
                            helpBoxTitle={"Timeframe"}
                            helpBoxText={"This is the timeframe before today that the web search will return results from."}
                        />
                        <CustomDropdown
                            valueList={["any", "past_year", "past_quarter", "past_month", "past_week", "past_day"]}
                            textList={["Anytime", "Past Year", "Past Quarter", "Past Month", "Past Week", "Past Day"]}
                            value={timeframe}
                            sendDataToParent={(val) => setTimeframe(val)}
                            customStyle={{ width: 160 }}
                            isDisabled={false}
                        />
                    </div>

                </div>

            </div>}
        </div>
    )

}



export const WorkflowCustomConfigModal = ({
    workflows, preselectedWorkflow = "none", responseConfig = {}, webSearchConfig = {}, autoQueryGuidanceInput,
    closeModal, isOpen, onClick, showWorkflows = true
}) => {

    // append "none" to the list of workflows
    let workflowList = workflows.map((workflow) => workflow.name);
    workflowList.unshift("Fact-Checker [AdvisorX AI]");
    workflowList.unshift("Tax Analyst [AdvisorX AI]");
    workflowList.unshift("SEC Compliance Analyst [AdvisorX AI]");
    workflowList.unshift("X/Twitter Post Generator [AdvisorX AI]");
    workflowList.unshift("LinkedIn Post Generator [AdvisorX AI]");
    workflowList.unshift("None");
    let workflowIds = workflows.map((workflow) => workflow._id);
    workflowIds.unshift("factChecker");
    workflowIds.unshift("tax");
    workflowIds.unshift("sec");
    workflowIds.unshift("twitter");
    workflowIds.unshift("socialMedia");
    workflowIds.unshift("none");

    const [selectedWorkflow, setSelectedWorkflow] = useState((preselectedWorkflow === undefined || preselectedWorkflow === null) ? "linkedIn" : preselectedWorkflow);

    const theme = { theme: "dark" };

    const [buttonDisabled, setButtonDisabled] = useState(false);

    // Response config
    const [model, setModel] = useState(responseConfig.model == undefined ? "claude-3-opus" : responseConfig.model);
    const [responseLength, setResponseLength] = useState(responseConfig.responseLength == undefined ? "medium" : responseConfig.responseLength);
    const [systemMessage, setSystemMessage] = useState(responseConfig.systemMessage == undefined ? "" : responseConfig.systemMessage);
    const [writingStyle, setWritingStyle] = useState(responseConfig.writingStyle == undefined ? "" : responseConfig.writingStyle);

    // Retrieval config
    const [autoQueryGuidance, setAutoQueryGuidance] = useState(autoQueryGuidanceInput == undefined ? "" : autoQueryGuidanceInput);

    // Web search config
    const [useWebSearch, setUseWebSearch] = useState(webSearchConfig.useWebSearch === undefined ? true : webSearchConfig.useWebSearch);
    let timeframeInput = webSearchConfig === undefined ? 365 : webSearchConfig.timeframe_days;
    timeframeInput = timeframeInput === undefined ? 365 : timeframeInput;
    // Format the timeframe days into a string
    timeframeInput = convertTimeframeDaysToString(timeframeInput);
    const [timeframe, setTimeframe] = useState((timeframeInput === null || timeframeInput === undefined) ? "past_year" : timeframeInput);

    // Make sure the include and exclude domains are strings (if they aren't null or undefined)
    let formattedIncludeDomains = webSearchConfig.include_domains;
    let formattedExcludeDomains = webSearchConfig.exclude_domains;
    if (formattedIncludeDomains !== null && formattedIncludeDomains !== undefined) {
        // Check if it is an array, and if so, turn it into a list
        if (Array.isArray(formattedIncludeDomains)) {
            formattedIncludeDomains = formattedIncludeDomains.join(", ");
        }
        // Strip any extra whitespace (so if there are 2 spaces)
        formattedIncludeDomains = formattedIncludeDomains.replace(/\s+/g, ' ').trim();
    }
    if (formattedExcludeDomains !== null && formattedExcludeDomains !== undefined) {
        // Check if it is an array, and if so, turn it into a list
        if (Array.isArray(formattedExcludeDomains)) {
            formattedExcludeDomains = formattedExcludeDomains.join(", ");
        }
        // Strip any extra whitespace (so if there are 2 spaces)
        formattedExcludeDomains = formattedExcludeDomains.replace(/\s+/g, ' ').trim();
    }

    const [includeDomains, setIncludeDomains] = useState(formattedIncludeDomains);
    const [excludeDomains, setExcludeDomains] = useState(formattedExcludeDomains);

    const [tabToShow, setTabToShow] = useState(showWorkflows ? "persona" : "response");

    // Set the default include or exclude value
    const defaultIncludeOrExclude = (webSearchConfig.excludeDomains !== null && webSearchConfig.excludeDomains !== undefined) ? "exclude" : "include";
    const [showIncludeOrExclude, setShowIncludeOrExclude] = useState(defaultIncludeOrExclude);


    function updateSettings() {

        let formattedIncludeDomains = includeDomains;
        let formattedExcludeDomains = excludeDomains;

        if (formattedIncludeDomains !== null && formattedIncludeDomains !== undefined) {
            // Check if it is an array, and if so, turn it into a list
            if (Array.isArray(formattedIncludeDomains)) {
                // Do nothing
                //formattedIncludeDomains = formattedIncludeDomains.join(", ");
            } else {
                // Turn this into an array
                formattedIncludeDomains = formattedIncludeDomains.split(",");
                // Remove whitespace
                formattedIncludeDomains.forEach((domain, index) => {
                    formattedIncludeDomains[index] = domain.trim();
                })
            }

        }
        if (formattedExcludeDomains !== null && formattedExcludeDomains !== undefined) {
            // Check if it is an array, and if so, turn it into a list
            if (Array.isArray(formattedExcludeDomains)) {
                // Do nothing
            } else {
                // Turn this into an array
                formattedExcludeDomains = formattedExcludeDomains.split(",");
                // Remove whitespace
                formattedExcludeDomains.forEach((domain, index) => {
                    formattedExcludeDomains[index] = domain.trim();
                })
            }
        }

        // If formatted include domains is not empty, make sure formatted exclude is empty
        if (formattedIncludeDomains !== null && formattedIncludeDomains !== undefined && formattedIncludeDomains.length > 0) {
            formattedExcludeDomains = "";
        }


        let updatedResponseConfig = {
            model: model,
            responseLength: responseLength,
            systemMessage: systemMessage,
            writingStyle: writingStyle
        }
        let updatedWebSearchConfig = {
            useWebSearch: useWebSearch,
            includeDomains: formattedIncludeDomains,
            excludeDomains: formattedExcludeDomains,
            timeframe: convertTimeframeStringToDays(timeframe),
        }

        onClick(updatedResponseConfig, updatedWebSearchConfig, selectedWorkflow);

    }

    return (
        <CustomModal closeModal={closeModal} isOpen={isOpen}
            title={"Configuration"}
            customModalContainerStyle={{ maxWidth: "1000px", width: "100%", height: "90vh", maxHeight: "750px", overflow: "hidden", paddingTop: "15px", paddingBottom: "10px" }}
            customMainColStyle={{ paddingTop: "0px", marginTop: "-10px", paddingLeft: "0px", paddingBottom: "10px", height: "100%", overflow: "hidden" }}
        >

            <div className="ai-settings-modal-section">

                <div className="ai-settings-modal-container">

                    <div className="ai-settings-sidebar-col">

                        {showWorkflows && <div className={`ai-settings-sidebar-row-${theme.theme}${tabToShow === "persona" ? "-selected" : ""}`}
                            onClick={() => setTabToShow("persona")}>
                            <IconContext.Provider
                                value={{ className: (`ai-settings-icon-${theme.theme}`) }}>
                                <div>
                                    <BsInfoCircleFill />
                                </div>
                            </IconContext.Provider>
                            <p className={`ai-settings-sidebar-text-${theme.theme}`}>
                                Persona
                            </p>
                        </div>}

                        {(selectedWorkflow === "none" || !showWorkflows) && <div className={`ai-settings-sidebar-row-${theme.theme}${tabToShow === "response" ? "-selected" : ""}`}
                            onClick={() => setTabToShow("response")}>
                            <IconContext.Provider
                                value={{ className: (`ai-settings-icon-${theme.theme}`) }}>
                                <div>
                                    <AiFillMessage />
                                </div>
                            </IconContext.Provider>
                            <p className={`ai-settings-sidebar-text-${theme.theme}`}>
                                Response Config
                            </p>
                        </div>}

                        {(selectedWorkflow === "none" || !showWorkflows) && <div className={`ai-settings-sidebar-row-${theme.theme}${tabToShow === "webSearch" ? "-selected" : ""}`}
                            onClick={() => setTabToShow("webSearch")}>
                            <IconContext.Provider
                                value={{ className: (`ai-settings-icon-${theme.theme}`) }}>
                                <div>
                                    <IoSearch />
                                </div>
                            </IconContext.Provider>
                            <p className={`ai-settings-sidebar-text-${theme.theme}`}>
                                Live Data Feed
                            </p>
                        </div>}

                    </div>

                    <div className="ai-settings-main-col">

                        {tabToShow === "persona" &&
                            <PersonaTab
                                workflowIds={workflowIds}
                                workflowList={workflowList}
                                selectedWorkflow={selectedWorkflow}
                                setSelectedWorkflow={setSelectedWorkflow}
                            />
                        }

                        {tabToShow === "response" &&
                            <ResponseConfig
                                model={model}
                                setModel={setModel}
                                responseLength={responseLength}
                                setResponseLength={setResponseLength}
                                systemMessage={systemMessage}
                                setSystemMessage={setSystemMessage}
                                writingStyle={writingStyle}
                                setWritingStyle={setWritingStyle}
                            />
                        }

                        {tabToShow === "retrieval" &&
                            <RetrievalConfig
                                autoQueryGuidance={autoQueryGuidance}
                                setAutoQueryGuidance={(text) => setAutoQueryGuidance(text)}
                            />
                        }

                        {tabToShow === "webSearch" &&
                            <WebSearchConfig
                                useWebSearch={useWebSearch}
                                setUseWebSearch={setUseWebSearch}
                                includeDomains={includeDomains}
                                setIncludeDomains={setIncludeDomains}
                                excludeDomains={excludeDomains}
                                setExcludeDomains={setExcludeDomains}
                                timeframe={timeframe}
                                setTimeframe={setTimeframe}
                                showIncludeOrExclude={showIncludeOrExclude}
                                setShowIncludeOrExclude={setShowIncludeOrExclude}
                            />
                        }

                    </div>

                </div>

                <div className="ai-settings-update-button-container">
                    <CustomButton
                        disabled={buttonDisabled}
                        buttonText={"Save"}
                        onClick={() => updateSettings()}
                        customStyle={{ width: "650px" }}
                    />
                </div>

            </div>

        </CustomModal>
    )

}



const PersonaTab = ({ workflowIds, workflowList, selectedWorkflow, setSelectedWorkflow }) => {

    return (

        <div>
            <div className="llm-configuration-modal-dropdown">
                <InfoIconWithText
                    text={"Persona"}
                    helpBoxTitle={"Persona"}
                    helpBoxText={
                        "Choose the pre-defined persona you want to use for this request. If you don't want to use a persona, select 'None'."
                    }
                />
                <CustomDropdown
                    customStyle={{ width: 200 }}
                    valueList={workflowIds}
                    textList={workflowList}
                    value={selectedWorkflow}
                    sendDataToParent={(value) => setSelectedWorkflow(value)}
                />
            </div>

        </div>

    )
    /*return (
        <div className="llm-configuration-modal-dropdown">
            <InfoIconWithText
                text={"Workflow"}
                helpBoxTitle={"Workflow"}
                helpBoxText={
                    "Choose the pre-defined workflow you want to use for this request. If you don't want to use a workflow, select 'None'."
                }
            />
            <CustomDropdown
                customStyle={{ width: 200 }}
                valueList={workflowIds}
                textList={workflowList}
                value={selectedWorkflow}
                sendDataToParent={(value) => setSelectedWorkflow(value)}
            />
        </div>
    )*/
}

/*

{numDocuments > 0 &&
                        <FileList
                            files={isLoading ? [] : knowledgeBaseFiles}
                            selectedFiles={[]}
                            handleCheckboxClick={() => console.log("")}
                        />
                    }

{(selectedWorkflow === "none" || !showWorkflows) && <div className={`ai-settings-sidebar-row-${theme.theme}${tabToShow === "retrieval" ? "-selected" : ""}`}
                            onClick={() => setTabToShow("retrieval")}>
                            <IconContext.Provider
                                value={{ className: (`ai-settings-icon-${theme.theme}`) }}>
                                <div>
                                    <HiDocumentArrowDown />
                                </div>
                            </IconContext.Provider>
                            <p className={`ai-settings-sidebar-text-${theme.theme}`}>
                                Retrieval Config
                            </p>
                        </div>}
*/


export const PersonaSearchModal = ({ closeModal, isOpen, persona, save, defaultPersonaSearchInstructions = "" }) => {

    const token = sessionStorage.getItem('token');
    console.log("defaultPersonaSearchInstructions", defaultPersonaSearchInstructions)

    const [personaSearchInstructions, setPersonaSearchInstructions] = useState(defaultPersonaSearchInstructions);

    const [isLoading, setIsLoading] = useState(false);
    const [numDocuments, setNumDocuments] = useState(0);
    const [knowledgeBaseFiles, setKnowledgeBaseFiles] = useState([]);

    const { getWorkflow } = useWorkflowHandler(token);

    async function getData() {
        setIsLoading(true);

        if (persona === undefined || persona === null) {
            setIsLoading(false);
            return;
        }

        const workflowData = await getWorkflow(persona._id);
        const kbID = workflowData.knowledge_base_id;

        const [response, status] =
            await knowledgeBaseServices.getAllDocumentsInKnowledgeBase(token, kbID);
        if (status == 200 || status == 204) {
            console.log(response.documents, "response.documents");
            setNumDocuments(response.documents.length);
            setKnowledgeBaseFiles(response.documents);
            setIsLoading(false);
        } else {
            setIsLoading(false);
            alert("Error fetching data");
        }
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <CustomModal
            closeModal={closeModal} isOpen={isOpen}
            title={`Persona Data Sources Search ${(persona === undefined || persona === null) ? "" : " - " + persona.name}`}
            customModalContainerStyle={{ maxWidth: "1000px", width: "100%", height: "94vh", maxHeight: "750px", overflow: "hidden", paddingTop: "15px", paddingBottom: "10px" }}
            customMainColStyle={{ paddingTop: "0px", marginTop: "-10px", paddingLeft: "0px", paddingBottom: "10px", height: "100%", overflow: "hidden" }}>

            <div className="ai-settings-modal-section">

                <div className="persona-search-modal-container">

                    {isLoading && <BouncingDots />}

                    <div className="llm-configuration-modal-options">
                        <div className="llm-configuration-modal-message-container">
                            <InfoIconWithText
                                text={"Persona Data Sources Search"}
                                helpBoxTitle={"Persona Data Sources Search"}
                                helpBoxText={
                                    "This lets you add a more targeted search over data sources you uploaded to your selected persona, as well as the live data feed, so the AI has the precise context of what keywords or specific data sources to look for."
                                }
                            />
                            <CustomTextArea
                                placeholderText={"Search over specific data sources from your selected persona."}
                                rows={8}
                                maxHeight={200}
                                minHeight={200}
                                sendDataToParent={(text) => setPersonaSearchInstructions(text)}
                                text={personaSearchInstructions}
                            />
                        </div>
                    </div>

                    <div className="persona-files-container">

                        <p className="persona-search-documents-header-text">Data Sources to search</p>

                        {numDocuments > 0 && knowledgeBaseFiles.map((file, index) =>
                            <div className="persona-search-file-row" key={index}>
                                <div>
                                    <p className="persona-search-file-title">
                                        {file.document_type === "url"
                                            ? file.link_to_source
                                            : file.title}
                                    </p>
                                </div>
                            </div>
                        )}

                        {numDocuments == 0 && !isLoading &&
                            <p className="persona-search-file-title">
                                No data sources uploaded
                            </p>
                        }

                    </div>

                </div>

                <div className="ai-settings-update-button-container">
                    <CustomButton
                        disabled={false}
                        buttonText={"Save"}
                        onClick={() => save(personaSearchInstructions)}
                        customStyle={{ width: "650px" }}
                    />
                </div>

            </div>

        </CustomModal>
    )

}


export default WorkflowConfigModal;
