import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Route } from "react-router-dom";

const ProtectedOnboardRoute = ({ component: Component, ...rest }) => {
  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading,
  } = useAuth0();

 
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return <div>Redirecting to login...</div>;
  }



  if (isAuthenticated) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
};

export default ProtectedOnboardRoute;
