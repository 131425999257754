import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import { MuiColorInput } from "mui-color-input";
import React from "react";
const CustomColorInput = ({
  value,
  sendDataToParent,
  customStyle,
  isDisabled = false,
  themeOverride = null,
}) => {
  const format = "hex";
  const theme = {
    theme: "light",
    backgroundColor: "var(--off-white)",
    mainFontColor: "var(--off-black)",
    sidenavBackgroundColor: "#FFFFFF",
    headerBorder: "1px solid #e0e0e0",
  };
  const styleTheme = themeOverride == null ? theme : themeOverride;

  const handleChange = (event) => {
    sendDataToParent(event);
  };
  const inputStyles = {
    "& .MuiInputBase-input": {
      position: "relative",
      backgroundColor: styleTheme.sidenavBackgroundColor,
      border:
        styleTheme.theme === "light"
          ? "1px solid var(--divider-line-gray)"
          : "none",
      padding: "10px 26px 10px 12px",
      "&:focus": {
        borderColor: "var(--divider-line-gray)",
        boxShadow: "none",
      },
    },


    borderRadius: "4px",
    paddingTop: "0px",
    paddingBottom: "0px",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "600",
    color: isDisabled ? "#8F8F8F" : styleTheme.mainFontColor,
    backgroundColor: styleTheme.sidenavBackgroundColor,
  };

  return (
    <div style={{ width: "100%" }}>
      <FormControl sx={{ ...customStyle }} size="small" disabled={isDisabled}>
        <MuiColorInput
          value={value || "#000000"}
          format={format}
          onChange={handleChange}
          sx={inputStyles}
          isAlphaHidden={true}
        />
      </FormControl>
    </div>
  );
};

export default CustomColorInput;
