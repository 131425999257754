import React, { createContext, useContext, useState } from 'react';
import Toast from '../components/Toast/Toast';

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(true);

  const toggleToast = (msg, error = true) => {
    setMessage(msg);
    setIsError(error);
    setIsVisible(true);
    setTimeout(() => setIsVisible(false), 3000); // Automatically hide after 3 seconds
  };

  return (
    <ToastContext.Provider value={{ toggleToast }}>
      {children}
      {isVisible && <Toast toastInfo={message} error={isError} isVisible={isVisible} />}
    </ToastContext.Provider>
  );
};
