import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { User, WebSearchPreset } from "./user_schema";

export interface AuthState {
  idToken: string;
  user: User | null;
}

const initialState: AuthState = {
  idToken: "",
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIdToken: (state, action: PayloadAction<string>) => {
      state.idToken = action.payload;
    },
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setSystemMessage: (state, action: PayloadAction<string>) => {
      if (state.user) {
        state.user.system_message = action.payload;
      }
    },
    setWebSearch: (state, action: PayloadAction<boolean>) => {
      if (state.user) {
        state.user.chat_config.use_web_search = action.payload;
      }
    },

    setChatConfig: (state, action: PayloadAction<any>) => {
      if (state.user) {
        state.user.chat_config = action.payload;
      }
    },
    setKnowledgeBases: (state, action: PayloadAction<[]>) => {
      if (state.user) {
        state.user.knowledge_bases = action.payload;
      }
    },
    logout: (state) => {
      state.idToken = "";
      state.user = null;
    },
    // update brand colors
    setBrandColors: (state, action: PayloadAction<string[]>) => {
      if (state.user) {
        state.user.brand_colors = action.payload;
      }
    },
    // set brand logo
    setBrandLogo: (state, action: PayloadAction<string>) => {
      if (state.user) {
        state.user.brand_logo = action.payload;
      }
      console.log("brand logo", state.user?.brand_logo);

    }

  },

});

export const {
  setIdToken,
  setUser,
  logout,
  setSystemMessage,
  setWebSearch,
  setKnowledgeBases,
  setChatConfig,
  setBrandColors,
  setBrandLogo
} = authSlice.actions;

export const selectIdToken = (state: RootState) => state.auth.idToken;
export const selectUser = (state: RootState) => state.auth.user;

export default authSlice.reducer;
