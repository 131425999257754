const { REACT_APP_API_URL, REACT_APP_AI_URL } = process.env;

export async function reviewDocument(
  idToken,
  knowledgeBaseId,
  documentId,
  referenceKnowledgeBaseIds,
  readingInstructions,
  reviewInstructions,
  segmentLength,
  readingModel,
  reviewModel,
  autoQueryGuidance,
  username
) {
  let url = `${REACT_APP_AI_URL}/knowledge_bases/${knowledgeBaseId}`;
  if (documentId !== "all") {
    url += `/documents/${documentId}/review`;
  } else {
    url += `/review`;
  }
  const payload = {
    active_reading_instructions:
      readingInstructions == null ? "" : readingInstructions,
    final_review_instructions:
      reviewInstructions == null ? "" : reviewInstructions,
    reference_knowledge_base_ids: referenceKnowledgeBaseIds,
    segment_length: segmentLength == null ? "medium" : segmentLength,
    active_reading_model: "gpt-3.5-turbo",
    final_review_model: "gpt-3.5-turbo",
    auto_query_guidance: autoQueryGuidance == null ? "" : autoQueryGuidance,
    supp_id: username,
  };

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify(payload),
  });

  const resData = await response.json();
  const status = response.status;

  return [resData, status];
}

export async function pollDocumentReviewStatus(idToken, id) {
  let url = `${REACT_APP_AI_URL}/jobs/${id}`;
  // const payload = {
  // pollURL: pollURL,
  // };

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    // body: JSON.stringify(payload),
  });

  const resData = await response.json();
  console.log("resData", resData);
  const status = response.status;

  return [resData, status];
}

export async function requestPreviousReviews(idToken, nextPageToken, username) {
  // let url = `https://api.superpowered.ai/v1/knowledge_bases/reviews?limit=100`;
  // let url = `${REACT_APP_API_URL}/list_reviews`;
  let url = `${REACT_APP_AI_URL}/knowledge_bases/reviews?limit=100`;
  // const payload = {
  // next_page_token: nextPageToken,
  // username: username,
  // };
  if (nextPageToken) {
    url += `&next_page_token=${nextPageToken}`;
  }
  if (username) {
    url += `&supp_id=${username}`;
  }

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    // body: JSON.stringify(payload),
  });

  const resData = await response.json();
  console.log("resData", resData);
  const status = response.status;

  return [resData, status];
}

export async function getDocument(idToken, knowledgeBaseId, documentId) {
  // let url = `${REACT_APP_API_URL}/get_document`;
  let url = `${REACT_APP_AI_URL}/knowledge_bases/${knowledgeBaseId}/documents/${documentId}`;

  // const payload = {
  // knowledgeBaseId: knowledgeBaseId,
  // documentId: documentId,
  // };

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    // body: JSON.stringify(payload),
  });

  const resData = await response.json();
  console.log("resData", resData);
  const status = response.status;

  return [resData, status];
}
