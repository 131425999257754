import { useState } from 'react';
import CryptoJS from "crypto-js";


// Read a Blob as an ArrayBuffer
async function readBlobAsArrayBuffer(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = () => {
            reject(new Error('Error reading Blob as ArrayBuffer'));
        };
        reader.readAsArrayBuffer(blob);
    });
}


export async function readFiles(allFiles, setFileData, setFileHashes, setAllUploadData) {

    // This works for any filetype. It reads the file as a binary string and then hashes it using MD5

    for (let i = 0; i < allFiles.length; i++) {
        let fileName = allFiles[i].name;

        const reader = new FileReader();
        reader.onload = async (e) => {
            const content = e.target.result;

            const binaryBlob = new Blob([new Uint8Array(content)])

            // Calculate MD5 hash of the binaryBlob
            const binaryContent = await readBlobAsArrayBuffer(binaryBlob);
            const md5Hash = CryptoJS.MD5(CryptoJS.lib.WordArray.create(binaryContent));
            const base64Hash = CryptoJS.enc.Base64.stringify(md5Hash);

            let newFileContent = {};
            newFileContent[fileName] = content;
            setFileData(prevState => {
                return {
                    ...newFileContent,
                    ...prevState
                }
            })

            /*console.log("filename", fileName)
            setAllUploadData(prevState => {
                return [
                    ...prevState,
                    {
                        name: fileName,
                        type: "file"
                    }
                ]
            });*/

            let newFile = {};
            newFile[fileName] = base64Hash;
            setFileHashes(prevState => {
                return {
                    ...newFile,
                    ...prevState
                }
            })


        };
        reader.readAsArrayBuffer(allFiles[i]);
    }

};


export function checkForDuplicateFiles(newFilesChosen, fileData) {
    // A duplicate file is one with the same filename and content
    let uniqueFiles = {};
    // Loop through the file data keys
    const keys = Object.keys(newFilesChosen);
    for (let i = 0; i < keys.length; i++) {
        const fileName = keys[i];
        const fileContent = newFilesChosen[fileName];
        if (newFilesChosen[fileName] === fileData[fileName]) {
            continue;
        } else {
            uniqueFiles[fileName] = fileContent;
        }
    }
    // Get the unique filenames
    const uniqueFileNames = Object.keys(uniqueFiles);
    return [uniqueFiles, uniqueFileNames];

}


export function useFileUploadHandler() {

    const [fileData, setFileData] = useState({});
    const [fileHashes, setFileHashes] = useState({});
    const [allUploadData, setAllUploadData] = useState([]); // This will be a list of everything to upload

    function removeFile(fileName) {
        console.log("filename", fileName)
        // Remove this file from the files chosen and the file data
        let newFileData = fileData;
        delete newFileData[fileName];
        let newFileHashes = fileHashes;
        delete newFileHashes[fileName];
        setFileData(newFileData);
        setFileHashes(newFileHashes);
        const newUploadData = allUploadData.filter(file => file.name !== fileName);
        setAllUploadData(newUploadData);
    }

    function handleURLUpload(url, autoContext) {
        const newUploadData = [{
            name: url,
            type: "url",
            auto_context: autoContext,
            chunk_header: "",
            title: "",
            supp_id: "",
            description: ""
        }, ...allUploadData];
        setAllUploadData(newUploadData);
    }

    async function handleFileUpload(event) {
        const files = event.target.files;
        readFiles(files, setFileData, setFileHashes, setAllUploadData);
    }

    function resetFileData() {
        console.log("resetting data")
        setFileData({});
        setFileHashes({});
        setAllUploadData([]);
    }

    return { handleFileUpload, handleURLUpload, removeFile, setAllUploadData, resetFileData, fileData, fileHashes, allUploadData }

}