
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'

interface ComplianceState {
    messageForReview: string | null
}

const initialState: ComplianceState = {
    messageForReview: null,
}

export const complianceSlice = createSlice({
    name: 'compliance',
    initialState,
    reducers: {
        setMessageForReview: (state, action: PayloadAction<string>) => {
            if (action.payload && action.payload.length > 0) {
                state.messageForReview = action.payload
            }

        },
    },
})


export const { setMessageForReview } = complianceSlice.actions
 
export const selectMessageForReview = (state: RootState) => state.compliance.messageForReview

export default complianceSlice.reducer
