import { v4 as uuidv4 } from "uuid";

const { REACT_APP_SERVICE_BASE_URL, REACT_APP_API_URL, REACT_APP_AI_URL } =
  process.env;


export async function uploadDocument(url, fileHash, fileData) {
  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-MD5": fileHash,
    },
    body: fileData,
  });
  console.log("upload data", response.status);
  return response.status;
}

export function convertToValidFilenameWithExtension(filename) {
  // Separate the base name and the extension
  const extension = filename.slice(filename.lastIndexOf("."));
  const baseName = filename.slice(0, filename.lastIndexOf("."));

  // Ensure the base name is less than 251 characters to leave room for a 4-character extension
  let validBaseName =
    baseName.length > 250 ? baseName.substring(0, 250) : baseName;

  // Replace any character that is not alphanumeric, a space, a hyphen, or an underscore with an underscore
  validBaseName = validBaseName.replace(/[^a-zA-Z0-9 _-]/g, "_");

  // const uuid = uuidv4();

  // Reconstruct the filename with the sanitized base name and original extension
  // return validBaseName + "_" + uuid + extension;
  return validBaseName + "_"  + extension;
}

export async function requestSignedURL(
  idToken,
  filename,
  knowledgeBaseId,
  md5Hash
) {
  // Filename CoWriter (1).pdf is not valid. Must be less than 256 characters and only contain alphanumeric characters, spaces, hyphens, and underscores.

  const newFilename = convertToValidFilenameWithExtension(filename);

  let payload = {
    filename: newFilename,
    encoded_md5: md5Hash,
    auto_context: true,
    method: "PUT",
  };

  const url = `${REACT_APP_AI_URL}/knowledge_bases/${knowledgeBaseId}/documents/request_signed_file_url`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify(payload),
  });
  const data = await response.json();
  return [data, response.status];
}
export async function uploadText(text, kbID, authToken) {
  // const url = `${REACT_APP_API_URL}/upload_document`;
  const url = `${REACT_APP_AI_URL}/knowledge_bases/${kbID}/documents/raw_text`;

  // let url = `https://api.superpowered.ai/v1/knowledge_bases/${knowledgeBaseId}/documents/raw_text`;

  const payload = {
    knowledgeBaseId: kbID,
    content: text,
  };

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(payload),
  });

  const resData = await response.json();
  console.log("resData", resData);
  console.log("status", response.status);
  const status = response.status;

  return [resData, status];
}

export async function getDocumentStatus(idToken, kbID, documentId) {
  // let url = `${REACT_APP_API_URL}/get_document_status`;
  // let url = `https://api.superpowered.ai/v1/knowledge_bases/${knowledgeBaseId}/documents/${documentId}`;
  let url = `${REACT_APP_AI_URL}/knowledge_bases/${kbID}/documents/${documentId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
  });

  const resData = await response.json();
  console.log("resData doc status", resData);
  const status = response.status;

  return [resData, status];
}

export async function deleteDocument(idToken, kbID, documentId) {
  console.log("deleting document");

  let url = `${REACT_APP_API_URL}/delete_document`;
  const payload = {
    knowledgeBaseId: kbID,
    documentId: documentId,
  };

  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify(payload),
  });

  const resData = await response.json();
  console.log("resData doc status", resData);
  const status = response.status;

  return status;
}
