export const DEFAULT_PROMPTS = [
  {
    title: "X/ Twitter",
    id: "twitter",
    categories: [
      {
        id: "retirement-planning",
        title: "Retirement Planning",
        prompts: [
          {
            shortTitle: "Little-Known Strategies",
            id: "Little-Known-Strategies",
            title:
              "Craft a tweet highlighting a little-known retirement planning strategy that can significantly boost savings for professionals aged 40-55.",
          },
          {
            shortTitle: "4% Rule",
            id: "4%-Rule",
            title:
              "Compose a tweet explaining the 4% rule in retirement planning and its relevance in today's economic climate.",
          },
          {
            shortTitle: "Healthcare Costs",
            id: "Healthcare-Costs",
            title:
              "Write a tweet addressing the importance of accounting for healthcare costs in retirement planning.",
          },
          {
            shortTitle: "IRA Benefits",
            id: "IRA-Benefits",
            title:
              "Create a tweet comparing traditional IRA and Roth IRA benefits for different career stages.",
          },
          {
            shortTitle: "Catch-Up Contributions",
            id: "Catch-Up-Contributions",
            title:
              "Draft a tweet discussing the concept of 'catch-up contributions' for those nearing retirement age.",
          },
          {
            shortTitle: "Social Security Benefits",
            id: "Social-Security-Benefits",
            title:
              "Pen a tweet explaining how Social Security benefits factor into comprehensive retirement planning.",
          },
          {
            shortTitle: "401(k) Risks",
            id: "401(k)-Risks",
            title:
              "Craft a tweet highlighting the risks of relying solely on a 401(k) for retirement savings.",
          },
          {
            shortTitle: "RMD Misconceptions",
            id: "RMD-Misconceptions",
            title:
              "Write a tweet addressing common misconceptions about required minimum distributions (RMDs).",
          },
          {
            shortTitle: "Annuities",
            id: "Annuities",
            title:
              "Craft a tweet discussing the role of annuities in creating a stable retirement income stream.",
          },
          {
            shortTitle: "Plan Review",
            id: "Plan-Review",
            title:
              "Create a tweet explaining the importance of regularly reviewing and adjusting retirement plans.",
          },
        ],
      },

      {
        id: "tax-optimization",
        title: "Tax Optimization",
        prompts: [
          {
            shortTitle: "Tax-Saving Tips",
            id: "Tax-Saving-Tips",
            title:
              "Write a tweet explaining a tax-saving tip for high-income earners that's often overlooked.",
          },
          {
            shortTitle: "Tax-Loss Harvesting",
            id: "Tax-Loss-Harvesting",
            title:
              "Compose a tweet highlighting the benefits of tax-loss harvesting in investment portfolios.",
          },
          {
            shortTitle: "Tax-Efficient Fund Placement",
            id: "Tax-Efficient-Fund-Placement",
            title:
              "Draft a tweet explaining the concept of tax-efficient fund placement in various account types.",
          },
          {
            shortTitle: "Roth IRA Conversions",
            id: "Roth-IRA-Conversions",
            title:
              "Create a tweet discussing the tax implications of Roth IRA conversions for different income levels.",
          },
          {
            shortTitle: "Capital Gains Taxes",
            id: "Capital-Gains-Taxes",
            title:
              "Write a tweet addressing common misconceptions about capital gains taxes on property sales.",
          },
          {
            shortTitle: "HSA Benefits",
            id: "HSA-Benefits",
            title:
              "Pen a tweet explaining the tax benefits of Health Savings Accounts (HSAs) beyond healthcare costs.",
          },
          {
            shortTitle: "Small Business Deductions",
            id: "Small-Business-Deductions",
            title:
              "Craft a tweet highlighting often-overlooked tax deductions for small business owners.",
          },
          {
            shortTitle: "Charitable Giving",
            id: "Charitable-Giving",
            title:
              "Compose a tweet discussing the tax advantages of certain charitable giving strategies.",
          },
          {
            shortTitle: "Alternative Minimum Tax",
            id: "Alternative-Minimum-Tax",
            title:
              "Write a tweet explaining the alternative minimum tax (AMT) and who might be affected by it.",
          },
          {
            shortTitle: "Stock Options",
            id: "Stock-Options",
            title:
              "Create a tweet addressing the tax implications of exercising employee stock options.",
          },
        ],
      },

      {
        id: "investment-strategies",
        title: "Investment Strategies",
        prompts: [
          {
            shortTitle: "Defensive Strategy",
            id: "Defensive-Strategy",
            title:
              "Create a tweet discussing a defensive investment strategy for volatile markets.",
          },
          {
            shortTitle: "Dollar-Cost Averaging",
            id: "Dollar-Cost-Averaging",
            title:
              "Compose a tweet explaining the concept of dollar-cost averaging and its benefits for long-term investors.",
          },
          {
            shortTitle: "Diversification",
            id: "Diversification",
            title:
              "Write a tweet highlighting the importance of diversification across asset classes and geographies.",
          },
          {
            shortTitle: "Role of Bonds",
            id: "Role-of-Bonds",
            title:
              "Draft a tweet discussing the role of bonds in a balanced investment portfolio.",
          },
          {
            shortTitle: "Risk-Adjusted Returns",
            id: "Risk-Adjusted-Returns",
            title:
              "Craft a tweet explaining the concept of risk-adjusted returns and why it matters to investors.",
          },
          {
            shortTitle: "Passive vs. Active",
            id: "Passive-vs-Active",
            title:
              "Pen a tweet addressing common misconceptions about passive vs. active investment strategies.",
          },
          {
            shortTitle: "Alternative Investments",
            id: "Alternative-Investments",
            title:
              "Create a tweet discussing the potential benefits and risks of alternative investments.",
          },
          {
            shortTitle: "Rebalancing Portfolios",
            id: "Rebalancing-Portfolios",
            title:
              "Compose a tweet explaining the importance of rebalancing investment portfolios and how often to do it.",
          },
          {
            shortTitle: "Core and Satellite",
            id: "Core-and-Satellite",
            title:
              "Write a tweet highlighting the concept of 'core and satellite' approach to portfolio construction.",
          },
          {
            shortTitle: "Impact of Fees",
            id: "Impact-of-Fees",
            title:
              "Draft a tweet discussing the impact of fees on long-term investment performance.",
          },
        ],
      },
      {
        id: "risk-management",
        title: "Risk Management",
        prompts: [
          {
            shortTitle: "Neglected Risk",
            id: "Neglected-Risk",
            title:
              "Draft a tweet highlighting an often-neglected aspect of financial risk management for small business owners.",
          },
          {
            shortTitle: "Umbrella Insurance",
            id: "Umbrella-Insurance",
            title:
              "Compose a tweet explaining the importance of umbrella insurance policies for high-net-worth individuals.",
          },
          {
            shortTitle: "Life Insurance",
            id: "Life-Insurance",
            title:
              "Write a tweet discussing the role of life insurance in a comprehensive financial plan.",
          },
          {
            shortTitle: "Disability Insurance",
            id: "Disability-Insurance",
            title:
              "Create a tweet highlighting common gaps in disability insurance coverage and their potential impact.",
          },
          {
            shortTitle: "Long-Term Care Insurance",
            id: "Long-Term-Care-Insurance",
            title:
              "Craft a tweet explaining the concept of long-term care insurance and who should consider it.",
          },
          {
            shortTitle: "Cyber Risk Management",
            id: "Cyber-Risk-Management",
            title:
              "Pen a tweet addressing the importance of cyber risk management in personal finance.",
          },
          {
            shortTitle: "Concentration Risk",
            id: "Concentration-Risk",
            title:
              "Draft a tweet discussing strategies for managing concentration risk in investment portfolios.",
          },
          {
            shortTitle: "Emergency Fund",
            id: "Emergency-Fund",
            title:
              "Compose a tweet explaining the role of an emergency fund in personal risk management.",
          },
          {
            shortTitle: "Insurance Policy Reviews",
            id: "Insurance-Policy-Reviews",
            title:
              "Write a tweet highlighting the importance of regular insurance policy reviews and updates.",
          },
          {
            shortTitle: "Self-Insurance",
            id: "Self-Insurance",
            title:
              "Create a tweet discussing the concept of self-insurance and when it might be appropriate.",
          },
        ],
      },
      {
        id: "financial-independence",
        title: "Financial Independence",
        prompts: [
          {
            shortTitle: "Practical Tip",
            id: "Practical-Tip",
            title:
              "Write a tweet sharing a practical tip for achieving financial independence before traditional retirement age.",
          },
          {
            shortTitle: "Coast FIRE",
            id: "Coast-FIRE",
            title:
              "Craft a tweet explaining the concept of 'Coast FIRE' and how it differs from traditional FIRE.",
          },
          {
            shortTitle: "Passive Income Streams",
            id: "Passive-Income-Streams",
            title:
              "Compose a tweet discussing the role of passive income streams in achieving financial independence.",
          },
          {
            shortTitle: "Lifestyle Design",
            id: "Lifestyle-Design",
            title:
              "Draft a tweet highlighting the importance of lifestyle design in the journey to financial independence.",
          },
          {
            shortTitle: "25x Rule",
            id: "25x-Rule",
            title:
              "Create a tweet explaining the '25x rule' in determining the amount needed for financial independence.",
          },
          {
            shortTitle: "Common Misconceptions",
            id: "Common-Misconceptions",
            title:
              "Pen a tweet addressing common misconceptions about the FIRE movement.",
          },
          {
            shortTitle: "Psychological Aspects",
            id: "Psychological-Aspects",
            title:
              "Write a tweet discussing the psychological aspects of pursuing financial independence.",
          },
          {
            shortTitle: "Healthcare Planning",
            id: "Healthcare-Planning",
            title:
              "Craft a tweet highlighting the importance of healthcare planning in early retirement scenarios.",
          },
          {
            shortTitle: "Barista FIRE",
            id: "Barista-FIRE",
            title:
              "Compose a tweet explaining the concept of 'Barista FIRE' and its potential benefits.",
          },
          {
            shortTitle: "Geographic Arbitrage",
            id: "Geographic-Arbitrage",
            title:
              "Draft a tweet discussing the role of geographic arbitrage in accelerating financial independence.",
          },
        ],
      },

      {
        id: "wealth-preservation",
        title: "Wealth Preservation",
        prompts: [
          {
            shortTitle: "Generational Wealth Transfer",
            id: "Generational-Wealth-Transfer",
            title:
              "Compose a tweet discussing a wealth preservation strategy for generational wealth transfer.",
          },
          {
            shortTitle: "Asset Protection Trusts",
            id: "Asset-Protection-Trusts",
            title:
              "Write a tweet explaining the role of asset protection trusts in wealth preservation.",
          },
          {
            shortTitle: "Inflation Protection",
            id: "Inflation-Protection",
            title:
              "Craft a tweet highlighting the importance of inflation protection in long-term wealth preservation.",
          },
          {
            shortTitle: "Family Limited Partnerships",
            id: "Family-Limited-Partnerships",
            title:
              "Create a tweet discussing the concept of family limited partnerships in preserving family wealth.",
          },
          {
            shortTitle: "Tax Planning",
            id: "Tax-Planning",
            title:
              "Draft a tweet explaining how proper tax planning contributes to wealth preservation over time.",
          },
          {
            shortTitle: "Insurance",
            id: "Insurance",
            title:
              "Pen a tweet addressing the role of insurance in protecting and preserving wealth.",
          },
          {
            shortTitle: "Financial Literacy",
            id: "Financial-Literacy",
            title:
              "Compose a tweet discussing the importance of teaching financial literacy to preserve generational wealth.",
          },
          {
            shortTitle: "Lawsuits or Creditors",
            id: "Lawsuits-or-Creditors",
            title:
              "Write a tweet highlighting strategies for protecting wealth from potential lawsuits or creditors.",
          },
          {
            shortTitle: "Stealth Wealth",
            id: "Stealth-Wealth",
            title:
              "Craft a tweet explaining the concept of 'stealth wealth' and its role in wealth preservation.",
          },
          {
            shortTitle: "Wealth Preservation Strategy Reviews",
            id: "Wealth-Preservation-Strategy-Reviews",
            title:
              "Create a tweet discussing the importance of regular wealth preservation strategy reviews.",
          },
        ],
      },
      {
        id: "charitable-giving",
        title: "Charitable Giving",
        prompts: [
          {
            shortTitle: "Tax-Efficient Strategy",
            id: "Tax-Efficient-Strategy",
            title:
              "Create a tweet explaining a tax-efficient charitable giving strategy that benefits both the donor and the cause.",
          },
          {
            shortTitle: "Donor-Advised Fund",
            id: "Donor-Advised-Fund",
            title:
              "Compose a tweet discussing the benefits of establishing a donor-advised fund for charitable giving.",
          },
          {
            shortTitle: "Qualified Charitable Distributions",
            id: "Qualified-Charitable-Distributions",
            title:
              "Write a tweet highlighting the concept of qualified charitable distributions from IRAs.",
          },
          {
            shortTitle: "Appreciated Securities",
            id: "Appreciated-Securities",
            title:
              "Draft a tweet explaining the potential benefits of donating appreciated securities instead of cash.",
          },
          {
            shortTitle: "Charitable Trusts",
            id: "Charitable-Trusts",
            title:
              "Craft a tweet discussing the role of charitable trusts in estate and tax planning.",
          },
          {
            shortTitle: "Due Diligence",
            id: "Due-Diligence",
            title:
              "Pen a tweet addressing the importance of due diligence when selecting charities to support.",
          },
          {
            shortTitle: "Bunching Donations",
            id: "Bunching-Donations",
            title:
              "Create a tweet explaining the concept of 'bunching' charitable donations for tax efficiency.",
          },
          {
            shortTitle: "Next Generation",
            id: "Next-Generation",
            title:
              "Compose a tweet discussing strategies for involving the next generation in family philanthropy.",
          },
          {
            shortTitle: "Corporate Matching Programs",
            id: "Corporate-Matching-Programs",
            title:
              "Write a tweet highlighting the potential benefits of corporate matching programs for charitable giving.",
          },
          {
            shortTitle: "Integrated Planning",
            id: "Integrated-Planning",
            title:
              "Draft a tweet explaining how charitable giving can be integrated into a comprehensive financial plan.",
          },
        ],
      },

      {
        id: "college-savings",
        title: "College Savings",
        prompts: [
          {
            shortTitle: "Comparison of Savings Vehicles",
            id: "Comparison-of-Savings-Vehicles",
            title:
              "Draft a tweet comparing two popular college savings vehicles, highlighting a key advantage of one over the other.",
          },
          {
            shortTitle: "Tax Benefits of 529 Plans",
            id: "Tax-Benefits-of-529-Plans",
            title:
              "Compose a tweet explaining the tax benefits of 529 college savings plans.",
          },
          {
            shortTitle: "Roth IRAs for College Savings",
            id: "Roth-IRAs-for-College-Savings",
            title:
              "Write a tweet discussing the pros and cons of using Roth IRAs for college savings.",
          },
          {
            shortTitle: "Maximizing Financial Aid Eligibility",
            id: "Maximizing-Financial-Aid-Eligibility",
            title:
              "Create a tweet highlighting strategies for maximizing financial aid eligibility while saving for college.",
          },
          {
            shortTitle: "Front-Loading 529 Plans",
            id: "Front-Loading-529-Plans",
            title:
              "Craft a tweet explaining the concept of front-loading 529 plans and its potential benefits.",
          },
          {
            shortTitle: "Custodial Accounts",
            id: "Custodial-Accounts",
            title:
              "Pen a tweet addressing common misconceptions about custodial accounts (UGMA/UTMA) for college savings.",
          },
          {
            shortTitle: "Balancing College Savings with Retirement",
            id: "Balancing-College-Savings-with-Retirement",
            title:
              "Draft a tweet discussing the importance of balancing college savings with retirement planning.",
          },
          {
            shortTitle: "Changing 529 Plan Beneficiaries",
            id: "Changing-529-Plan-Beneficiaries",
            title:
              "Compose a tweet explaining how to change 529 plan beneficiaries and the rules surrounding it.",
          },
          {
            shortTitle: "Catching Up on College Savings",
            id: "Catching-Up-on-College-Savings",
            title:
              "Write a tweet highlighting strategies for catching up on college savings for late starters.",
          },
          {
            shortTitle: "Alternative Uses for 529 Plan Funds",
            id: "Alternative-Uses-for-529-Plan-Funds",
            title:
              "Create a tweet discussing alternative uses for 529 plan funds if the beneficiary doesn't attend college.",
          },
        ],
      },
      {
        id: "business-succession",
        title: "Business Succession",
        prompts: [
          {
            shortTitle: "Critical Consideration",
            id: "Critical-Consideration",
            title:
              "Write a tweet addressing a critical consideration in business succession planning that owners often overlook.",
          },
          {
            shortTitle: "Early Planning",
            id: "Early-Planning",
            title:
              "Craft a tweet explaining the importance of starting business succession planning early.",
          },
          {
            shortTitle: "Key Person Insurance",
            id: "Key-Person-Insurance",
            title:
              "Compose a tweet discussing the role of key person insurance in business succession planning.",
          },
          {
            shortTitle: "Exit Strategies",
            id: "Exit-Strategies",
            title:
              "Draft a tweet highlighting different exit strategies for business owners and their implications.",
          },
          {
            shortTitle: "Buy-Sell Agreement",
            id: "Buy-Sell-Agreement",
            title:
              "Create a tweet explaining the concept of a buy-sell agreement and its importance in succession planning.",
          },
          {
            shortTitle: "Tax Implications",
            id: "Tax-Implications",
            title:
              "Pen a tweet addressing the tax implications of various business succession strategies.",
          },
          {
            shortTitle: "Grooming Successors",
            id: "Grooming-Successors",
            title:
              "Write a tweet discussing the importance of grooming successors in family-owned businesses.",
          },
          {
            shortTitle: "Business Valuation",
            id: "Business-Valuation",
            title:
              "Craft a tweet highlighting the role of business valuation in effective succession planning.",
          },
          {
            shortTitle: "ESOPs",
            id: "ESOPs",
            title:
              "Compose a tweet explaining how ESOPs can be used as a business succession strategy.",
          },
          {
            shortTitle: "Communication",
            id: "Communication",
            title:
              "Draft a tweet discussing the importance of communication in successful business succession planning.",
          },
        ],
      },
    ],
  },

  {
    title: "Blogs/Articles",
    id: "blogs-articles",
    categories: [
      {
        id: "retirement-planning",
        title: "Retirement Planning",
        prompts: [
          {
            shortTitle: "Sustainable Withdrawal Strategy",
            id: "Sustainable-Withdrawal-Strategy",
            title:
              "Create a comprehensive guide on creating a sustainable retirement withdrawal strategy.",
          },
          {
            shortTitle: "Retirement Account Types",
            id: "Retirement-Account-Types",
            title:
              "Write an in-depth analysis of the pros and cons of different retirement account types (401(k), IRA, Roth IRA, etc.).",
          },
          {
            shortTitle: "Healthcare Costs",
            id: "Healthcare-Costs",
            title:
              "Compose a blog post on navigating healthcare costs in retirement, including Medicare options.",
          },
          {
            shortTitle: "Late Starters",
            id: "Late-Starters",
            title:
              "Draft an article on strategies for catching up on retirement savings for late starters.",
          },
          {
            shortTitle: "Phased Retirement",
            id: "Phased-Retirement",
            title:
              "Create a post explaining the concept of phased retirement and its potential benefits.",
          },
          {
            shortTitle: "Retirement Budget",
            id: "Retirement-Budget",
            title:
              "Write a detailed guide on how to create a retirement budget that accounts for inflation and unexpected expenses.",
          },
          {
            shortTitle: "Psychological Aspects",
            id: "Psychological-Aspects",
            title:
              "Compose an article discussing the psychological aspects of transitioning into retirement.",
          },
          {
            shortTitle: "Estate Planning",
            id: "Estate-Planning",
            title:
              "Draft a post on the importance of estate planning as part of comprehensive retirement planning.",
          },
          {
            shortTitle: "Annuities",
            id: "Annuities",
            title:
              "Create a guide on how to effectively use annuities in retirement income planning.",
          },
          {
            shortTitle: "Economic Scenarios",
            id: "Economic-Scenarios",
            title:
              "Write an analysis of how different economic scenarios could impact retirement plans and strategies to prepare.",
          },
        ],
      },
      {
        id: "tax-optimization",
        title: "Tax Optimization",
        prompts: [
          {
            shortTitle: "Tax-Efficient Investing",
            id: "Tax-Efficient-Investing",
            title:
              "Compose a comprehensive guide on tax-efficient investing strategies for high-net-worth individuals.",
          },
          {
            shortTitle: "Capital Gains Taxes",
            id: "Capital-Gains-Taxes",
            title:
              "Draft an in-depth article on understanding and optimizing capital gains taxes.",
          },
          {
            shortTitle: "Tax-Loss Harvesting",
            id: "Tax-Loss-Harvesting",
            title:
              "Create a blog post explaining advanced tax-loss harvesting techniques.",
          },
          {
            shortTitle: "Small Business Owners",
            id: "Small-Business-Owners",
            title:
              "Write a detailed guide on tax planning strategies for small business owners.",
          },
          {
            shortTitle: "Charitable Giving",
            id: "Charitable-Giving",
            title:
              "Compose an article on how to effectively use charitable giving to reduce tax liability.",
          },
          {
            shortTitle: "Alternative Minimum Tax",
            id: "Alternative-Minimum-Tax",
            title:
              "Draft a post on understanding and navigating the alternative minimum tax (AMT).",
          },
          {
            shortTitle: "Retirement Withdrawals",
            id: "Retirement-Withdrawals",
            title:
              "Create a comprehensive guide on tax-efficient retirement withdrawal strategies.",
          },
          {
            shortTitle: "Investment Vehicles",
            id: "Investment-Vehicles",
            title:
              "Write an analysis of the tax implications of different investment vehicles (mutual funds, ETFs, individual stocks).",
          },
          {
            shortTitle: "Year-End Planning",
            id: "Year-End-Planning",
            title:
              "Compose a blog post on year-end tax planning strategies for high-income earners.",
          },
          {
            shortTitle: "Tax Law Changes",
            id: "Tax-Law-Changes",
            title:
              "Draft an article on how recent tax law changes affect retirement and estate planning.",
          },
        ],
      },

      {
        id: "Estate Planning",
        title: "Estate Planning",
        prompts: [
          {
            shortTitle: "Comprehensive Estate Plan",
            id: "Comprehensive-Estate-Plan",
            title:
              "Create a comprehensive guide on creating a robust estate plan, from basic wills to complex trusts.",
          },
          {
            shortTitle: "Different Trust Types",
            id: "Different-Trust-Types",
            title:
              "Write an in-depth analysis of different trust types and their uses in estate planning.",
          },
          {
            shortTitle: "Minimizing Estate Taxes",
            id: "Minimizing-Estate-Taxes",
            title:
              "Compose a blog post on strategies for minimizing estate taxes for high-net-worth individuals.",
          },
          {
            shortTitle: "Healthcare Directives",
            id: "Healthcare-Directives",
            title:
              "Draft an article on the importance of healthcare directives and power of attorney in estate planning.",
          },
          {
            shortTitle: "Life Insurance",
            id: "Life-Insurance",
            title:
              "Create a post explaining how to effectively use life insurance in estate planning.",
          },
          {
            shortTitle: "Blended Families",
            id: "Blended-Families",
            title:
              "Write a detailed guide on estate planning considerations for blended families.",
          },
          {
            shortTitle: "Digital Assets",
            id: "Digital-Assets",
            title:
              "Compose an article discussing digital asset management in estate planning.",
          },
          {
            shortTitle: "Charitable Giving",
            id: "Charitable-Giving",
            title:
              "Draft a post on how to incorporate charitable giving into your estate plan.",
          },
          {
            shortTitle: "Successors for Family Businesses",
            id: "Successors-for-Family-Businesses",
            title:
              "Create a guide on how to choose and prepare successors for family businesses as part of estate planning.",
          },
          {
            shortTitle: "Common Mistakes",
            id: "Common-Mistakes",
            title:
              "Write an analysis of common estate planning mistakes and how to avoid them.",
          },
        ],
      },

      {
        id: "Investment Strategies",
        title: "Investment Strategies",
        prompts: [
          {
            shortTitle: "Diversified Portfolio",
            id: "Diversified-Portfolio",
            title:
              "Compose a comprehensive guide on building a diversified investment portfolio.",
          },
          {
            shortTitle: "Factor Investing",
            id: "Factor-Investing",
            title:
              "Draft an in-depth article on factor investing and its potential benefits.",
          },
          {
            shortTitle: "Risk Parity",
            id: "Risk-Parity",
            title:
              "Create a blog post explaining the concept of risk parity and its application in portfolio construction.",
          },
          {
            shortTitle: "Alternative Investments",
            id: "Alternative-Investments",
            title:
              "Write a detailed guide on incorporating alternative investments into a traditional portfolio.",
          },
          {
            shortTitle: "Active vs. Passive",
            id: "Active-vs-Passive",
            title:
              "Compose an article on the pros and cons of active vs. passive investment strategies.",
          },
          {
            shortTitle: "Life Stages",
            id: "Life-Stages",
            title:
              "Draft a post on how to adjust investment strategies for different life stages.",
          },
          {
            shortTitle: "International Investing",
            id: "International-Investing",
            title:
              "Create a comprehensive guide on international investing, including emerging markets.",
          },
          {
            shortTitle: "Macroeconomic Factors",
            id: "Macroeconomic-Factors",
            title:
              "Write an analysis of the impact of macroeconomic factors on investment strategies.",
          },
          {
            shortTitle: "Sustainable Investing",
            id: "Sustainable-Investing",
            title:
              "Compose an article on sustainable and ESG investing strategies.",
          },
          {
            shortTitle: "Low-Yield Environment",
            id: "Low-Yield-Environment",
            title:
              "Draft an article on effective strategies for investing in a low-yield environment.",
          },
        ],
      },

      {
        id: "Risk Management",
        title: "Risk Management",
        prompts: [
          {
            shortTitle: "Holistic Risk Management",
            id: "Holistic-Risk-Management",
            title:
              "Create a comprehensive guide on creating a holistic risk management plan for high-net-worth individuals.",
          },

          {
            shortTitle: "Types of Insurance",
            id: "Types-of-Insurance",
            title:
              "Write an in-depth analysis of different types of insurance and their role in risk management.",
          },
          {
            shortTitle: "Sequence of Returns Risk",
            id: "Sequence-of-Returns-Risk",
            title:
              "Compose a blog post on strategies for managing sequence of returns risk in retirement.",
          },
          {
            shortTitle: "Cybersecurity",
            id: "Cybersecurity",
            title:
              "Draft an article on cybersecurity best practices for protecting personal financial information.",
          },
          {
            shortTitle: "Portfolio Protection",
            id: "Portfolio-Protection",
            title:
              "Create a post explaining how to use options for portfolio protection.",
          },
          {
            shortTitle: "Concentration Risk",
            id: "Concentration-Risk",
            title:
              "Write a detailed guide on managing concentration risk in investment portfolios.",
          },
          {
            shortTitle: "Asset Protection",
            id: "Asset-Protection",
            title:
              "Compose an article discussing strategies for protecting assets from potential lawsuits.",
          },
          {
            shortTitle: "Risk Management Tools",
            id: "Risk-Management-Tools",
            title:
              "Draft a post on how to effectively use stop-loss orders and other risk management tools.",
          },
          {
            shortTitle: "Currency Risk",
            id: "Currency-Risk",
            title:
              "Create a guide on managing currency risk in international investments.",
          },
          {
            shortTitle: "Behavioral Biases",
            id: "Behavioral-Biases",
            title:
              "Write an analysis of how behavioral biases can increase financial risk and strategies to mitigate them.",
          },
        ],
      },
      {
        id: "financial-independence",
        title: "Financial Independence",
        prompts: [
          {
            shortTitle: "Financial Independence Plan",
            id: "Financial-Independence-Plan",
            title:
              "Compose a comprehensive guide on creating a plan to achieve financial independence.",
          },
          {
            shortTitle: "Paths to Financial Independence",
            id: "Paths-to-Financial-Independence",
            title:
              "Draft an in-depth article on different paths to financial independence (traditional retirement, FIRE, entrepreneurship).",
          },
          {
            shortTitle: "Financial Independence Number",
            id: "Financial-Independence-Number",
            title:
              "Create a blog post explaining how to calculate your personal financial independence number.",
          },
          {
            shortTitle: "Increasing Savings Rate",
            id: "Increasing-Savings-Rate",
            title:
              "Write a detailed guide on increasing savings rate to accelerate the path to financial independence.",
          },
          {
            shortTitle: "Multiple Income Streams",
            id: "Multiple-Income-Streams",
            title:
              "Compose an article on creating and growing multiple income streams for financial independence.",
          },
          {
            shortTitle: "Balancing Present and Future",
            id: "Balancing-Present-and-Future",
            title:
              "Draft a post on balancing the pursuit of financial independence with enjoying life in the present.",
          },
          {
            shortTitle: "Economic Downturns",
            id: "Economic-Downturns",
            title:
              "Create a comprehensive guide on maintaining financial independence during economic downturns.",
          },
          {
            shortTitle: "Psychological Aspects",
            id: "Psychological-Aspects",
            title:
              "Write an analysis of the psychological aspects of pursuing and achieving financial independence.",
          },
          {
            shortTitle: "Late Starters",
            id: "Late-Starters",
            title:
              "Compose a blog post on strategies for achieving financial independence as a late starter.",
          },
          {
            shortTitle: "Changing Life Goals",
            id: "Changing-Life-Goals",
            title:
              "Draft an article on how to adjust financial independence plans for changing life goals and circumstances.",
          },
        ],
      },

      {
        id: "wealth-preservation",
        title: "Wealth Preservation",
        prompts: [
          {
            shortTitle: "Wealth Preservation Strategies",
            id: "Wealth-Preservation-Strategies",
            title:
              "Create a comprehensive guide on wealth preservation strategies for high-net-worth individuals.",
          },
          {
            shortTitle: "Role of Trusts",
            id: "Role-of-Trusts",
            title:
              "Write an in-depth analysis of the role of trusts in wealth preservation.",
          },
          {
            shortTitle: "Generational Wealth Transfer",
            id: "Generational-Wealth-Transfer",
            title:
              "Compose a blog post on strategies for preserving wealth across multiple generations.",
          },
          {
            shortTitle: "Inflation Protection",
            id: "Inflation-Protection",
            title:
              "Draft an article on how to protect wealth from inflation and economic uncertainties.",
          },
          {
            shortTitle: "Family Offices",
            id: "Family-Offices",
            title:
              "Create a post explaining the concept of family offices and their role in wealth preservation.",
          },
          {
            shortTitle: "Insurance Products",
            id: "Insurance-Products",
            title:
              "Write a detailed guide on using insurance products for wealth preservation.",
          },
          {
            shortTitle: "Financial Education",
            id: "Financial-Education",
            title:
              "Compose an article discussing the importance of financial education in preserving family wealth.",
          },
          {
            shortTitle: "Major Life Transitions",
            id: "Major-Life-Transitions",
            title:
              "Draft a post on strategies for preserving wealth during major life transitions (divorce, business sale, etc.).",
          },

          {
            shortTitle: "Asset Protection",
            id: "Asset-Protection",
            title:
              "Create a guide on how to use asset protection strategies to preserve wealth.",
          },

          {
            shortTitle: "Common Pitfalls",
            id: "Common-Pitfalls",
            title:
              "Write an analysis of common pitfalls in wealth preservation and how to avoid them.",
          },
        ],
      },

      {
        id: "charitable-giving",
        title: "Charitable Giving",
        prompts: [
          {
            shortTitle: "Strategic Charitable Giving Plan",
            id: "Strategic-Charitable-Giving-Plan",
            title:
              "Compose a comprehensive guide on creating a strategic charitable giving plan.",
          },
          {
            shortTitle: "Charitable Giving Vehicles",
            id: "Charitable-Giving-Vehicles",
            title:
              "Draft an in-depth article on different charitable giving vehicles (donor-advised funds, private foundations, etc.).",
          },
          {
            shortTitle: "Maximizing Impact",
            id: "Maximizing-Impact",
            title:
              "Create a blog post explaining how to maximize the impact of charitable donations.",
          },
          {
            shortTitle: "Tax-Efficient Strategies",
            id: "Tax-Efficient-Strategies",
            title:
              "Write a detailed guide on tax-efficient charitable giving strategies.",
          },
          {
            shortTitle: "Incorporating Charitable Giving",
            id: "Incorporating-Charitable-Giving",
            title:
              "Compose an article on how to incorporate charitable giving into overall financial and estate plans.",
          },
          {
            shortTitle: "Family Involvement",
            id: "Family-Involvement",
            title:
              "Draft a post on strategies for involving family members in philanthropic efforts.",
          },
          {
            shortTitle: "Impact Investing",
            id: "Impact-Investing",
            title:
              "Create a comprehensive guide on impact investing as a form of charitable giving.",
          },
          {
            shortTitle: "Evaluating Charities",
            id: "Evaluating-Charities",
            title:
              "Write an analysis of how to evaluate charities and measure the impact of donations.",
          },
          {
            shortTitle: "Corporate Social Responsibility",
            id: "Corporate-Social-Responsibility",
            title:
              "Compose a blog post on corporate social responsibility and its role in charitable giving.",
          },
          {
            shortTitle: "Philanthropic Legacy",
            id: "Philanthropic-Legacy",
            title:
              "Draft an article on how to create a lasting philanthropic legacy.",
          },
        ],
      },
      {
        id: "college-savings",
        title: "College Savings",
        prompts: [
          {
            shortTitle: "College Savings Vehicles",
            id: "College-Savings-Vehicles",
            title:
              "Create a comprehensive guide on different college savings vehicles and their pros and cons.",
          },
          {
            shortTitle: "529 Plans",
            id: "529-Plans",
            title:
              "Write an in-depth analysis of 529 plans, including investment options and tax benefits.",
          },
          {
            shortTitle: "Financial Aid Eligibility",
            id: "Financial-Aid-Eligibility",
            title:
              "Compose a blog post on strategies for maximizing financial aid eligibility while saving for college.",
          },
          {
            shortTitle: "Balancing Savings Goals",
            id: "Balancing-Savings-Goals",
            title:
              "Draft an article on balancing retirement savings with college savings goals.",
          },
          {
            shortTitle: "Coverdell ESAs",
            id: "Coverdell-ESAs",
            title:
              "Create a post explaining how to use Coverdell Education Savings Accounts effectively.",
          },
          {
            shortTitle: "Grandparents' Strategies",
            id: "Grandparents-Strategies",
            title:
              "Write a detailed guide on college savings strategies for grandparents.",
          },
          {
            shortTitle: "Alternative Funding",
            id: "Alternative-Funding",
            title:
              "Compose an article discussing alternative education funding options beyond traditional college savings.",
          },
          {
            shortTitle: "Changing Strategies",
            id: "Changing-Strategies",
            title:
              "Draft a post on how to adjust college savings strategies as children approach college age.",
          },
          {
            shortTitle: "Divorce and Family Changes",
            id: "Divorce-and-Family-Changes",
            title:
              "Create a guide on handling college savings in cases of divorce or family changes.",
          },
          {
            shortTitle: "Student Loans vs. Savings",
            id: "Student-Loans-vs-Savings",
            title:
              "Write an analysis of the pros and cons of student loans vs. college savings.",
          },
        ],
      },

      {
        id: "business-succession",
        title: "Business Succession",
        prompts: [
          {
            shortTitle: "Effective Succession Plan",
            id: "Effective-Succession-Plan",
            title:
              "Compose a comprehensive guide on creating an effective business succession plan.",
          },
          {
            shortTitle: "Ownership Transfer Options",
            id: "Ownership-Transfer-Options",
            title:
              "Draft an in-depth article on different options for transferring business ownership.",
          },
          {
            shortTitle: "Identifying Successors",
            id: "Identifying-Successors",
            title:
              "Create a blog post explaining strategies for identifying and developing potential successors.",
          },
          {
            shortTitle: "Business Valuation",
            id: "Business-Valuation",
            title:
              "Write a detailed guide on valuation methods for closely-held businesses in succession planning.",
          },
          {
            shortTitle: "Tax Considerations",
            id: "Tax-Considerations",
            title:
              "Compose an article on tax considerations in business succession planning.",
          },
          {
            shortTitle: "Personal Retirement Integration",
            id: "Personal-Retirement-Integration",
            title:
              "Draft a post on how to integrate personal retirement planning with business succession.",
          },
          {
            shortTitle: "Family-Owned Businesses",
            id: "Family-Owned-Businesses",
            title:
              "Create a comprehensive guide on succession planning for family-owned businesses.",
          },
          {
            shortTitle: "Common Pitfalls",
            id: "Common-Pitfalls",
            title:
              "Write an analysis of common pitfalls in business succession planning and how to avoid them.",
          },
          {
            shortTitle: "Key Employee Retention",
            id: "Key-Employee-Retention",
            title:
              "Compose a blog post on strategies for retaining key employees during business transition.",
          },
          {
            shortTitle: "Contingency Planning",
            id: "Contingency-Planning",
            title:
              "Draft an article on how to create a contingency plan for unexpected events in business succession.",
          },
        ],
      },
    ],
  },

  {
    title: "LinkedIn",
    id: "linkedin",
    categories: [
      {
        id: "retirement-planning",
        title: "Retirement Planning",
        prompts: [
          {
            shortTitle: "Sequence of Returns Risk",
            id: "Sequence-of-Returns-Risk",
            title:
              "Create a LinkedIn post explaining the concept of sequence of returns risk in retirement planning.",
          },
          {
            shortTitle: "Part-Time Work",
            id: "Part-Time-Work",
            title:
              "Write a post discussing the pros and cons of working part-time during retirement.",
          },
          {
            shortTitle: "Maximizing Social Security",
            id: "Maximizing-Social-Security",
            title:
              "Compose a post highlighting strategies for maximizing Social Security benefits in retirement.",
          },
          {
            shortTitle: "Stress-Testing Retirement Plans",
            id: "Stress-Testing-Retirement-Plans",
            title:
              "Draft a post explaining the importance of stress-testing retirement plans for various market scenarios.",
          },
          {
            shortTitle: "Real Estate Investments",
            id: "Real-Estate-Investments",
            title:
              "Create a post discussing the role of real estate investments in retirement planning.",
          },
          {
            shortTitle: "Retirement Planning Mistakes",
            id: "Retirement-Planning-Mistakes",
            title:
              "Write a post addressing common retirement planning mistakes and how to avoid them.",
          },
          {
            shortTitle: "Bucket Strategy",
            id: "Bucket-Strategy",
            title:
              "Compose a post explaining the concept of 'bucket strategy' for retirement income planning.",
          },
          {
            shortTitle: "Long-Term Care Expenses",
            id: "Long-Term-Care-Expenses",
            title:
              "Draft a post highlighting the importance of planning for long-term care expenses in retirement.",
          },
          {
            shortTitle: "RMDs",
            id: "RMDs",
            title:
              "Create a post discussing strategies for managing Required Minimum Distributions (RMDs) efficiently.",
          },
          {
            shortTitle: "Adjusting Retirement Plans",
            id: "Adjusting-Retirement-Plans",
            title:
              "Write a post explaining how to adjust retirement plans in response to major life changes.",
          },
        ],
      },
      {
        id: "tax-optimization",
        title: "Tax Optimization",
        prompts: [
          {
            shortTitle: "Tax-Gain Harvesting",
            id: "Tax-Gain-Harvesting",
            title:
              "Compose a post explaining the concept of tax-gain harvesting and when it might be beneficial.",
          },
          {
            shortTitle: "Tax Management in Retirement",
            id: "Tax-Management-in-Retirement",
            title:
              "Draft a post highlighting strategies for managing taxes in retirement.",
          },
          {
            shortTitle: "Investment Account Tax Implications",
            id: "Investment-Account-Tax-Implications",
            title:
              "Create a post discussing the tax implications of different types of investment accounts.",
          },
          {
            shortTitle: "Opportunity Zone Investments",
            id: "Opportunity-Zone-Investments",
            title:
              "Write a post explaining the potential tax benefits of opportunity zone investments.",
          },
          {
            shortTitle: "Tax Planning for Small Business Owners",
            id: "Tax-Planning-for-Small-Business-Owners",
            title:
              "Compose a post highlighting tax planning strategies for small business owners.",
          },
          {
            shortTitle: "Municipal Bonds",
            id: "Municipal-Bonds",
            title:
              "Draft a post discussing the tax advantages and disadvantages of municipal bonds.",
          },
          {
            shortTitle: "Tax Credits",
            id: "Tax-Credits",
            title:
              "Create a post explaining how to use tax credits effectively to reduce overall tax liability.",
          },
          {
            shortTitle: "Minimizing Taxes on Investment Income",
            id: "Minimizing-Taxes-on-Investment-Income",
            title:
              "Write a post highlighting strategies for minimizing taxes on investment income.",
          },
          {
            shortTitle: "Selling a Primary Residence",
            id: "Selling-a-Primary-Residence",
            title:
              "Compose a post discussing the tax implications of selling a primary residence.",
          },
          {
            shortTitle: "Tax-Efficient Withdrawal Strategies",
            id: "Tax-Efficient-Withdrawal-Strategies",
            title:
              "Draft a post explaining how to use tax-efficient withdrawal strategies in retirement.",
          },
        ],
      },
      {
        id: "estate-planning",
        title: "Estate Planning",
        prompts: [
          {
            shortTitle: "Will and Trust",
            id: "Will-and-Trust",
            title:
              "Create a post discussing the importance of having both a will and a trust in comprehensive estate planning.",
          },
          {
            shortTitle: "Per Stirpes vs. Per Capita",
            id: "Per-Stirpes-vs-Per-Capita",
            title:
              "Write a post explaining the concept of per stirpes vs. per capita distribution in estate planning.",
          },
          {
            shortTitle: "Minimizing Estate Taxes",
            id: "Minimizing-Estate-Taxes",
            title:
              "Compose a post highlighting strategies for minimizing estate taxes for high-net-worth individuals.",
          },
          {
            shortTitle: "Life Insurance in Estate Planning",
            id: "Life-Insurance-in-Estate-Planning",
            title:
              "Draft a post discussing the role of life insurance in estate planning.",
          },
          {
            shortTitle: "Gifting Strategies",
            id: "Gifting-Strategies",
            title:
              "Create a post explaining how to use gifting strategies in estate planning.",
          },
          {
            shortTitle: "Business Succession Planning",
            id: "Business-Succession-Planning",
            title:
              "Write a post addressing the importance of business succession planning as part of estate planning.",
          },
          {
            shortTitle: "Planning for Incapacity",
            id: "Planning-for-Incapacity",
            title:
              "Compose a post discussing strategies for planning for incapacity in estate planning.",
          },
          {
            shortTitle: "Revocable vs. Irrevocable Trusts",
            id: "Revocable-vs-Irrevocable-Trusts",
            title:
              "Draft a post highlighting the benefits and drawbacks of revocable vs. irrevocable trusts.",
          },
          {
            shortTitle: "Charitable Giving in Estate Plans",
            id: "Charitable-Giving-in-Estate-Plans",
            title:
              "Create a post explaining how to incorporate charitable giving into estate plans.",
          },
          {
            shortTitle: "Regular Estate Plan Reviews",
            id: "Regular-Estate-Plan-Reviews",
            title:
              "Write a post discussing the importance of reviewing and updating estate plans regularly.",
          },
        ],
      },
      {
        id: "investment-strategies",
        title: "Investment Strategies",
        prompts: [
          {
            shortTitle: "Factor Investing",
            id: "Factor-Investing",
            title:
              "Compose a post explaining the concept of factor investing and its potential benefits.",
          },
          {
            shortTitle: "International Investments",
            id: "International-Investments",
            title:
              "Draft a post discussing the role of international investments in a diversified portfolio.",
          },
          {
            shortTitle: "High Inflation Environment",
            id: "High-Inflation-Environment",
            title:
              "Create a post highlighting strategies for investing in a high inflation environment.",
          },
          {
            shortTitle: "Value vs. Growth Investing",
            id: "Value-vs-Growth-Investing",
            title:
              "Write a post explaining the concept of value vs. growth investing and current market trends.",
          },
          {
            shortTitle: "REITs",
            id: "REITs",
            title:
              "Compose a post discussing the potential benefits and risks of investing in REITs.",
          },
          {
            shortTitle: "Portfolio Risk Management",
            id: "Portfolio-Risk-Management",
            title:
              "Draft a post highlighting strategies for managing portfolio risk in uncertain markets.",
          },
          {
            shortTitle: "Tax-Efficient Investing",
            id: "Tax-Efficient-Investing",
            title:
              "Create a post explaining the concept of tax-efficient investing and its importance.",
          },
          {
            shortTitle: "Fixed Income Investments",
            id: "Fixed-Income-Investments",
            title:
              "Write a post discussing the role of fixed income investments in different market environments.",
          },
          {
            shortTitle: "Investing During Economic Cycles",
            id: "Investing-During-Economic-Cycles",
            title:
              "Compose a post highlighting strategies for investing during different economic cycles.",
          },
          {
            shortTitle: "ESG Investing",
            id: "ESG-Investing",
            title:
              "Draft a post explaining how to incorporate ESG factors into investment decisions.",
          },
        ],
      },
      {
        id: "risk-management",
        title: "Risk Management",
        prompts: [
          {
            shortTitle: "Comprehensive Risk Management Strategy",
            id: "Comprehensive-Risk-Management-Strategy",
            title:
              "Create a post discussing the importance of having a comprehensive risk management strategy.",
          },
          {
            shortTitle: "Types of Insurance Policies",
            id: "Types-of-Insurance-Policies",
            title:
              "Write a post explaining different types of insurance policies and their roles in risk management.",
          },
          {
            shortTitle: "Investment Risk Management in Retirement",
            id: "Investment-Risk-Management-in-Retirement",
            title:
              "Compose a post highlighting strategies for managing investment risk in retirement.",
          },
          {
            shortTitle: "Reviewing and Updating Insurance Coverage",
            id: "Reviewing-and-Updating-Insurance-Coverage",
            title:
              "Draft a post discussing the importance of regularly reviewing and updating insurance coverage.",
          },
          {
            shortTitle: "Using Options for Portfolio Protection",
            id: "Using-Options-for-Portfolio-Protection",
            title:
              "Create a post explaining how to use options for portfolio protection.",
          },
          {
            shortTitle: "Cybersecurity Risks",
            id: "Cybersecurity-Risks",
            title:
              "Write a post addressing cybersecurity risks and strategies for protecting personal financial information.",
          },
          {
            shortTitle: "Diversification in Managing Investment Risk",
            id: "Diversification-in-Managing-Investment-Risk",
            title:
              "Compose a post discussing the role of diversification in managing investment risk.",
          },
          {
            shortTitle: "Managing Currency Risk",
            id: "Managing-Currency-Risk",
            title:
              "Draft a post highlighting strategies for managing currency risk in international investments.",
          },
          {
            shortTitle: "Using Stop-Loss Orders",
            id: "Using-Stop-Loss-Orders",
            title:
              "Create a post explaining how to use stop-loss orders to manage downside risk.",
          },
          {
            shortTitle: "Emergency Fund for Risk Management",
            id: "Emergency-Fund-for-Risk-Management",
            title:
              "Write a post discussing the importance of having an emergency fund as part of risk management.",
          },
        ],
      },
      {
        id: "financial-independence",
        title: "Financial Independence",
        prompts: [
          {
            shortTitle: "Crossover Point",
            id: "Crossover-Point",
            title:
              "Compose a post explaining the concept of the 'crossover point' in achieving financial independence.",
          },
          {
            shortTitle: "Increasing Savings Rate",
            id: "Increasing-Savings-Rate",
            title:
              "Draft a post discussing strategies for increasing savings rate to accelerate financial independence.",
          },
          {
            shortTitle: "Mindset in Achieving Financial Independence",
            id: "Mindset-in-Achieving-Financial-Independence",
            title:
              "Create a post highlighting the importance of mindset in achieving financial independence.",
          },
          {
            shortTitle: "Calculating Financial Independence Number",
            id: "Calculating-Financial-Independence-Number",
            title:
              "Write a post explaining how to calculate your personal financial independence number.",
          },
          {
            shortTitle: "Side Hustles for Financial Independence",
            id: "Side-Hustles-for-Financial-Independence",
            title:
              "Compose a post discussing the role of side hustles in achieving financial independence.",
          },
          {
            shortTitle: "Maintaining Financial Independence in Downturns",
            id: "Maintaining-Financial-Independence-in-Downturns",
            title:
              "Draft a post highlighting strategies for maintaining financial independence during economic downturns.",
          },
          {
            shortTitle: "Balancing Financial Independence and Enjoyment",
            id: "Balancing-Financial-Independence-and-Enjoyment",
            title:
              "Create a post explaining how to balance pursuing financial independence with enjoying life in the present.",
          },
          {
            shortTitle: "Health Management for Financial Independence",
            id: "Health-Management-for-Financial-Independence",
            title:
              "Write a post discussing the importance of health management in achieving and maintaining financial independence.",
          },
          {
            shortTitle: "Achieving Financial Independence as a Late Starter",
            id: "Achieving-Financial-Independence-as-a-Late-Starter",
            title:
              "Compose a post highlighting strategies for achieving financial independence as a late starter.",
          },
          {
            shortTitle: "Adjusting Financial Independence Plans",
            id: "Adjusting-Financial-Independence-Plans",
            title:
              "Draft a post explaining how to adjust financial independence plans for changing life goals.",
          },
        ],
      },
      {
        id: "wealth-preservation",
        title: "Wealth Preservation",
        prompts: [
          {
            shortTitle: "Preserving Wealth in High Inflation Periods",
            id: "Preserving-Wealth-in-High-Inflation-Periods",
            title:
              "Create a post discussing strategies for preserving wealth during high inflation periods.",
          },
          {
            shortTitle: "Dynasty Trusts",
            id: "Dynasty-Trusts",
            title:
              "Write a post explaining the concept of dynasty trusts for long-term wealth preservation.",
          },
          {
            shortTitle: "Diversification in Wealth Preservation",
            id: "Diversification-in-Wealth-Preservation",
            title:
              "Compose a post highlighting the role of diversification in wealth preservation.",
          },
          {
            shortTitle: "Preserving Wealth Across Generations",
            id: "Preserving-Wealth-Across-Generations",
            title:
              "Draft a post discussing strategies for preserving wealth across multiple generations.",
          },
          {
            shortTitle: "Insurance Products for Wealth Preservation",
            id: "Insurance-Products-for-Wealth-Preservation",
            title:
              "Create a post explaining how to use insurance products for wealth preservation.",
          },
          {
            shortTitle: "Common Pitfalls in Wealth Preservation",
            id: "Common-Pitfalls-in-Wealth-Preservation",
            title:
              "Write a post addressing common pitfalls in wealth preservation and how to avoid them.",
          },
          {
            shortTitle: "Portfolio Rebalancing in Wealth Preservation",
            id: "Portfolio-Rebalancing-in-Wealth-Preservation",
            title:
              "Compose a post discussing the importance of regular portfolio rebalancing in wealth preservation.",
          },
          {
            shortTitle: "Preserving Wealth in Different Economic Environments",
            id: "Preserving-Wealth-in-Different-Economic-Environments",
            title:
              "Draft a post highlighting strategies for preserving wealth in different economic environments.",
          },
          {
            shortTitle: "Using Trusts for Wealth Preservation",
            id: "Using-Trusts-for-Wealth-Preservation",
            title:
              "Create a post explaining how to use trusts effectively for wealth preservation.",
          },
          {
            shortTitle: "Financial Education in Wealth Preservation",
            id: "Financial-Education-in-Wealth-Preservation",
            title:
              "Write a post discussing the role of financial education in preserving family wealth.",
          },
        ],
      },
      {
        id: "charitable-giving",
        title: "Charitable Giving",
        prompts: [
          {
            shortTitle: "Impact Investing",
            id: "Impact-Investing",
            title:
              "Compose a post explaining the concept of impact investing as a form of charitable giving.",
          },
          {
            shortTitle: "Maximizing Impact of Charitable Donations",
            id: "Maximizing-Impact-of-Charitable-Donations",
            title:
              "Draft a post discussing strategies for maximizing the impact of charitable donations.",
          },
          {
            shortTitle: "Private Foundation Benefits",
            id: "Private-Foundation-Benefits",
            title:
              "Create a post highlighting the benefits of establishing a private foundation for charitable giving.",
          },
          {
            shortTitle: "Charitable Giving in Financial Planning",
            id: "Charitable-Giving-in-Financial-Planning",
            title:
              "Write a post explaining how to integrate charitable giving into overall financial planning.",
          },
          {
            shortTitle: "Corporate Social Responsibility",
            id: "Corporate-Social-Responsibility",
            title:
              "Compose a post discussing the role of corporate social responsibility in charitable giving.",
          },
          {
            shortTitle: "Teaching Children About Charitable Giving",
            id: "Teaching-Children-About-Charitable-Giving",
            title:
              "Draft a post highlighting strategies for teaching children about charitable giving.",
          },
          {
            shortTitle: "Charitable Remainder Trusts",
            id: "Charitable-Remainder-Trusts",
            title:
              "Create a post explaining how to use charitable remainder trusts in estate planning.",
          },
          {
            shortTitle: "Direct Giving vs. Giving Through Organizations",
            id: "Direct-Giving-vs-Giving-Through-Organizations",
            title:
              "Write a post discussing the pros and cons of direct giving vs. giving through charitable organizations.",
          },
          {
            shortTitle: "Sustainable Charitable Giving",
            id: "Sustainable-Charitable-Giving",
            title:
              "Compose a post highlighting strategies for sustainable and long-term charitable giving.",
          },
          {
            shortTitle: "Measuring Impact of Charitable Contributions",
            id: "Measuring-Impact-of-Charitable-Contributions",
            title:
              "Draft a post explaining how to measure the impact of charitable contributions.",
          },
        ],
      },
      {
        id: "college-savings",
        title: "College Savings",
        prompts: [
          {
            shortTitle: "Balancing College Savings",
            id: "Balancing-College-Savings",
            title:
              "Create a post discussing strategies for balancing college savings with other financial goals.",
          },
          {
            shortTitle: "College Savings Vehicles",
            id: "College-Savings-Vehicles",
            title:
              "Write a post explaining the differences between various college savings vehicles.",
          },
          {
            shortTitle: "Grandparents Contributing to College Savings",
            id: "Grandparents-Contributing-to-College-Savings",
            title:
              "Compose a post highlighting strategies for grandparents contributing to college savings.",
          },
          {
            shortTitle: "Student Loans vs. College Savings",
            id: "Student-Loans-vs-College-Savings",
            title:
              "Draft a post discussing the pros and cons of student loans vs. college savings.",
          },
          {
            shortTitle: "Coverdell Education Savings Accounts",
            id: "Coverdell-Education-Savings-Accounts",
            title:
              "Create a post explaining how to use Coverdell Education Savings Accounts effectively.",
          },
          {
            shortTitle: "College Savings Mistakes",
            id: "College-Savings-Mistakes",
            title:
              "Write a post addressing common mistakes in college savings planning and how to avoid them.",
          },
          {
            shortTitle: "College Savings for Older Children",
            id: "College-Savings-for-Older-Children",
            title:
              "Compose a post discussing strategies for catching up on college savings for older children.",
          },
          {
            shortTitle: "College Savings in Divorce Settlements",
            id: "College-Savings-in-Divorce-Settlements",
            title:
              "Draft a post highlighting the importance of considering college savings in divorce settlements.",
          },
          {
            shortTitle: "Adjusting College Savings Strategies",
            id: "Adjusting-College-Savings-Strategies",
            title:
              "Create a post explaining how to adjust college savings strategies as children approach college age.",
          },
          {
            shortTitle: "Alternative Education Funding",
            id: "Alternative-Education-Funding",
            title:
              "Write a post discussing alternative education funding options beyond traditional college savings plans.",
          },
        ],
      },

      {
        id: "business-succession",
        title: "Business Succession",
        prompts: [
          {
            shortTitle: "Formal Business Succession Plan",
            id: "Formal-Business-Succession-Plan",
            title:
              "Compose a post explaining the importance of having a formal business succession plan.",
          },
          {
            shortTitle: "Identifying and Developing Successors",
            id: "Identifying-and-Developing-Successors",
            title:
              "Draft a post discussing strategies for identifying and developing potential successors in a business.",
          },
          {
            shortTitle: "Business Valuation in Succession Planning",
            id: "Business-Valuation-in-Succession-Planning",
            title:
              "Create a post highlighting the role of business valuation in succession planning.",
          },
          {
            shortTitle: "Transferring Business Ownership",
            id: "Transferring-Business-Ownership",
            title:
              "Write a post explaining different options for transferring business ownership and their implications.",
          },
          {
            shortTitle: "Communication in Succession Planning",
            id: "Communication-in-Succession-Planning",
            title:
              "Compose a post discussing the importance of communication in business succession planning.",
          },
          {
            shortTitle: "Minimizing Taxes in Business Succession",
            id: "Minimizing-Taxes-in-Business-Succession",
            title:
              "Draft a post highlighting strategies for minimizing taxes in business succession.",
          },
          {
            shortTitle: "Personal Retirement Planning Integration",
            id: "Personal-Retirement-Planning-Integration",
            title:
              "Create a post explaining how to integrate personal retirement planning with business succession planning.",
          },
          {
            shortTitle: "Key Employee Retention",
            id: "Key-Employee-Retention",
            title:
              "Write a post discussing the role of key employee retention in successful business succession.",
          },
          {
            shortTitle: "Managing Family Dynamics",
            id: "Managing-Family-Dynamics",
            title:
              "Compose a post highlighting strategies for managing family dynamics in family business succession.",
          },
          {
            shortTitle: "Contingency Planning",
            id: "Contingency-Planning",
            title:
              "Draft a post explaining how to create a contingency plan for unexpected events in business succession.",
          },
        ],
      },
    ],
  },

  {
    title: "Email Campaigns",
    id: "email-campaigns",
    categories: [
      {
        id: "retirement-planning",
        title: "Retirement Planning",
        prompts: [
          {
            shortTitle: "Basics of Retirement Planning",
            id: "Basics-of-Retirement-Planning",
            title:
              "Craft an email series introducing the basics of retirement planning for young professionals.",
          },
          {
            shortTitle: "Starting Retirement Savings Early",
            id: "Starting-Retirement-Savings-Early",
            title:
              "Develop an email campaign explaining the benefits of starting retirement savings early.",
          },
          {
            shortTitle: "Catch-Up Strategies",
            id: "Catch-Up-Strategies",
            title:
              "Create an email sequence on catch-up strategies for those behind on retirement savings.",
          },
          {
            shortTitle: "Retirement Account Options",
            id: "Retirement-Account-Options",
            title:
              "Design an email campaign discussing different retirement account options and their benefits.",
          },
          {
            shortTitle: "Sustainable Withdrawal Strategy",
            id: "Sustainable-Withdrawal-Strategy",
            title:
              "Compose an email campaign about creating a sustainable retirement withdrawal strategy.",
          },
          {
            shortTitle: "Planning for Healthcare Costs",
            id: "Planning-for-Healthcare-Costs",
            title:
              "Develop an email sequence on planning for healthcare costs in retirement.",
          },
          {
            shortTitle: "Common Retirement Planning Mistakes",
            id: "Common-Retirement-Planning-Mistakes",
            title:
              "Create an email series addressing common retirement planning mistakes and how to avoid them.",
          },
          {
            shortTitle: "Adjusting Retirement Plans",
            id: "Adjusting-Retirement-Plans",
            title:
              "Craft an email campaign on the importance of adjusting retirement plans throughout one's career.",
          },
          {
            shortTitle: "Phased Retirement",
            id: "Phased-Retirement",
            title:
              "Design an email sequence explaining the concept of phased retirement.",
          },
          {
            shortTitle: "Retirement Budgeting for Inflation",
            id: "Retirement-Budgeting-for-Inflation",
            title:
              "Develop an email series on how to create a retirement budget that accounts for inflation.",
          },
        ],
      },
      {
        id: "tax-optimization",
        title: "Tax Optimization",
        prompts: [
          {
            shortTitle: "Tax Optimization Basics",
            id: "Tax-Optimization-Basics",
            title:
              "Create an email campaign introducing basic tax optimization strategies for investors.",
          },
          {
            shortTitle: "Tax-Loss Harvesting",
            id: "Tax-Loss-Harvesting",
            title:
              "Craft an email series explaining the concept and benefits of tax-loss harvesting.",
          },
          {
            shortTitle: "Tax-Efficient Investing",
            id: "Tax-Efficient-Investing",
            title:
              "Develop an email sequence on tax-efficient investing strategies.",
          },
          {
            shortTitle: "Year-End Tax Planning",
            id: "Year-End-Tax-Planning",
            title: "Design an email campaign about year-end tax planning tips.",
          },
          {
            shortTitle: "Charitable Giving for Tax Benefits",
            id: "Charitable-Giving-for-Tax-Benefits",
            title:
              "Compose an email series explaining how to use charitable giving to reduce tax liability.",
          },
          {
            shortTitle: "Tax Planning for Small Business Owners",
            id: "Tax-Planning-for-Small-Business-Owners",
            title:
              "Craft an email campaign on tax planning strategies for small business owners.",
          },
          {
            shortTitle: "Capital Gains Taxes",
            id: "Capital-Gains-Taxes",
            title:
              "Develop an email series on understanding and optimizing capital gains taxes.",
          },
          {
            shortTitle: "Tax-Efficient Withdrawal Strategies",
            id: "Tax-Efficient-Withdrawal-Strategies",
            title:
              "Design an email sequence on tax-efficient withdrawal strategies in retirement.",
          },
          {
            shortTitle: "Recent Tax Law Changes",
            id: "Recent-Tax-Law-Changes",
            title:
              "Create an email campaign explaining recent tax law changes and their implications.",
          },
        ],
      },
      {
        id: "estate-planning",
        title: "Estate Planning",
        prompts: [
          {
            shortTitle: "Basics of Estate Planning",
            id: "Basics-of-Estate-Planning",
            title:
              "Craft an email series introducing the basics of estate planning.",
          },
          {
            shortTitle: "Importance of Wills and Trusts",
            id: "Importance-of-Wills-and-Trusts",
            title:
              "Develop an email campaign explaining the importance of having a will and keeping it updated.",
          },
          {
            shortTitle: "Types of Trusts",
            id: "Types-of-Trusts",
            title:
              "Create an email sequence on different types of trusts and their uses in estate planning.",
          },
          {
            shortTitle: "Life Insurance in Estate Planning",
            id: "Life-Insurance-in-Estate-Planning",
            title:
              "Design an email series about the role of life insurance in estate planning.",
          },
          {
            shortTitle: "Estate Planning for Blended Families",
            id: "Estate-Planning-for-Blended-Families",
            title:
              "Compose an email campaign on estate planning considerations for blended families.",
          },
          {
            shortTitle: "Healthcare Directives and Power of Attorney",
            id: "Healthcare-Directives-and-Power-of-Attorney",
            title:
              "Develop an email sequence on the importance of healthcare directives and power of attorney.",
          },
          {
            shortTitle: "Minimizing Estate Taxes",
            id: "Minimizing-Estate-Taxes",
            title:
              "Create an email series explaining how to minimize estate taxes.",
          },
          {
            shortTitle: "Digital Asset Management",
            id: "Digital-Asset-Management",
            title:
              "Craft an email campaign on digital asset management in estate planning.",
          },
          {
            shortTitle: "Choosing an Executor",
            id: "Choosing-an-Executor",
            title:
              "Design an email sequence on how to choose an executor for your estate.",
          },
          {
            shortTitle: "Common Estate Planning Mistakes",
            id: "Common-Estate-Planning-Mistakes",
            title:
              "Develop an email series on common estate planning mistakes and how to avoid them.",
          },
        ],
      },

      {
        id: "investment-strategies",
        title: "Investment Strategies",
        prompts: [
          {
            shortTitle: "Diversification Basics",
            id: "Diversification-Basics",
            title:
              "Create an email campaign introducing the basics of diversification in investing.",
          },
          {
            shortTitle: "Asset Classes",
            id: "Asset-Classes",
            title:
              "Craft an email series explaining different asset classes and their roles in a portfolio.",
          },
          {
            shortTitle: "Active vs. Passive Investing",
            id: "Active-vs-Passive-Investing",
            title:
              "Develop an email sequence on the pros and cons of active vs. passive investing.",
          },
          {
            shortTitle: "Investing During Market Volatility",
            id: "Investing-During-Market-Volatility",
            title:
              "Design an email campaign about strategies for investing during market volatility.",
          },
          {
            shortTitle: "International Investments",
            id: "International-Investments",
            title:
              "Compose an email series on incorporating international investments into a portfolio.",
          },
          {
            shortTitle: "Dollar-Cost Averaging",
            id: "Dollar-Cost-Averaging",
            title:
              "Create an email sequence explaining the concept of dollar-cost averaging.",
          },
          {
            shortTitle: "Portfolio Rebalancing",
            id: "Portfolio-Rebalancing",
            title:
              "Craft an email campaign on the importance of rebalancing a portfolio.",
          },
          {
            shortTitle: "Sustainable and ESG Investing",
            id: "Sustainable-and-ESG-Investing",
            title:
              "Develop an email series on sustainable and ESG investing strategies.",
          },
          {
            shortTitle: "Bonds in a Portfolio",
            id: "Bonds-in-a-Portfolio",
            title:
              "Design an email sequence on the role of bonds in a diversified portfolio.",
          },
          {
            shortTitle: "Factor Investing",
            id: "Factor-Investing",
            title:
              "Create an email campaign explaining factor investing and its potential benefits.",
          },
        ],
      },
      {
        id: "risk-management",
        title: "Risk Management",
        prompts: [
          {
            shortTitle: "Financial Risk Management Basics",
            id: "Financial-Risk-Management-Basics",
            title:
              "Craft an email series introducing the basics of financial risk management.",
          },
          {
            shortTitle: "Types of Insurance",
            id: "Types-of-Insurance",
            title:
              "Develop an email campaign explaining different types of insurance and their importance.",
          },
          {
            shortTitle: "Asset Protection Strategies",
            id: "Asset-Protection-Strategies",
            title:
              "Create an email sequence on strategies for protecting assets from potential lawsuits.",
          },
          {
            shortTitle: "Cybersecurity Best Practices",
            id: "Cybersecurity-Best-Practices",
            title:
              "Design an email series about cybersecurity best practices for personal finance.",
          },
          {
            shortTitle: "Investment Risk Management in Retirement",
            id: "Investment-Risk-Management-in-Retirement",
            title:
              "Compose an email campaign on managing investment risk in retirement.",
          },
          {
            shortTitle: "Emergency Fund Importance",
            id: "Emergency-Fund-Importance",
            title:
              "Develop an email sequence on the importance of having an emergency fund.",
          },
          {
            shortTitle: "Options for Portfolio Protection",
            id: "Options-for-Portfolio-Protection",
            title:
              "Create an email series explaining how to use options for portfolio protection.",
          },
          {
            shortTitle: "Sequence of Returns Risk",
            id: "Sequence-of-Returns-Risk",
            title:
              "Craft an email campaign on managing sequence of returns risk in retirement.",
          },
          {
            shortTitle: "Diversification in Risk Management",
            id: "Diversification-in-Risk-Management",
            title:
              "Design an email sequence on the role of diversification in risk management.",
          },
          {
            shortTitle: "Comprehensive Risk Management",
            id: "Comprehensive-Risk-Management",
            title:
              "Develop an email series on how to create a comprehensive risk management plan.",
          },
        ],
      },
      {
        id: "financial-independence",
        title: "Financial Independence",
        prompts: [
          {
            shortTitle: "Introduction to Financial Independence",
            id: "Introduction-to-Financial-Independence",
            title:
              "Create an email campaign introducing the concept of financial independence.",
          },
          {
            shortTitle: "Increasing Savings Rate",
            id: "Increasing-Savings-Rate",
            title:
              "Craft an email series on strategies for increasing savings rate to achieve financial independence.",
          },
          {
            shortTitle: "Calculating Financial Independence Number",
            id: "Calculating-Financial-Independence-Number",
            title:
              "Develop an email sequence explaining how to calculate your personal financial independence number.",
          },
          {
            shortTitle: "Multiple Income Streams",
            id: "Multiple-Income-Streams",
            title:
              "Design an email campaign about creating multiple income streams for financial independence.",
          },
          {
            shortTitle: "FIRE Movement",
            id: "FIRE-Movement",
            title:
              "Compose an email series on the FIRE (Financial Independence, Retire Early) movement.",
          },
          {
            shortTitle: "Balancing Goals and Lifestyle",
            id: "Balancing-Goals-and-Lifestyle",
            title:
              "Create an email sequence on balancing financial independence goals with current lifestyle.",
          },
          {
            shortTitle: "Financial Independence in Downturns",
            id: "Financial-Independence-in-Downturns",
            title:
              "Craft an email campaign on maintaining financial independence during economic downturns.",
          },
          {
            shortTitle: "Achieving Financial Independence as a Late Starter",
            id: "Achieving-Financial-Independence-as-a-Late-Starter",
            title:
              "Design an email sequence on achieving financial independence as a late starter.",
          },
          {
            shortTitle: "Psychological Aspects of Financial Independence",
            id: "Psychological-Aspects-of-Financial-Independence",
            title:
              "Create an email campaign on the psychological aspects of pursuing financial independence.",
          },
          {
            shortTitle: "Adjusting Financial Independence Plans",
            id: "Adjusting-Financial-Independence-Plans",
            title:
              "Develop an email series on adjusting financial independence plans for changing life goals.",
          },
        ],
      },
      {
        id: "wealth-preservation",
        title: "Wealth Preservation",
        prompts: [
          {
            shortTitle: "Wealth Preservation Basics",
            id: "Wealth-Preservation-Basics",
            title:
              "Craft an email series introducing wealth preservation strategies for high-net-worth individuals.",
          },
          {
            shortTitle: "Role of Trusts in Wealth Preservation",
            id: "Role-of-Trusts-in-Wealth-Preservation",
            title:
              "Develop an email campaign explaining the role of trusts in wealth preservation.",
          },
          {
            shortTitle: "Preserving Wealth Across Generations",
            id: "Preserving-Wealth-Across-Generations",
            title:
              "Create an email sequence on strategies for preserving wealth across multiple generations.",
          },
          {
            shortTitle: "Protecting Wealth from Inflation",
            id: "Protecting-Wealth-from-Inflation",
            title:
              "Design an email series about protecting wealth from inflation and economic uncertainties.",
          },
          {
            shortTitle: "Financial Education for Wealth Preservation",
            id: "Financial-Education-for-Wealth-Preservation",
            title:
              "Compose an email campaign on the importance of financial education in preserving family wealth.",
          },
          {
            shortTitle: "Insurance Products for Wealth Preservation",
            id: "Insurance-Products-for-Wealth-Preservation",
            title:
              "Develop an email sequence on using insurance products for wealth preservation.",
          },
          {
            shortTitle: "Asset Protection Strategies",
            id: "Asset-Protection-Strategies",
            title:
              "Create an email series explaining asset protection strategies.",
          },
          {
            shortTitle: "Diversification in Wealth Preservation",
            id: "Diversification-in-Wealth-Preservation",
            title:
              "Craft an email campaign on the role of diversification in wealth preservation.",
          },
          {
            shortTitle: "Wealth Preservation in Life Transitions",
            id: "Wealth-Preservation-in-Life-Transitions",
            title:
              "Design an email sequence on preserving wealth during major life transitions.",
          },
          {
            shortTitle: "Common Pitfalls in Wealth Preservation",
            id: "Common-Pitfalls-in-Wealth-Preservation",
            title:
              "Develop an email series on common pitfalls in wealth preservation and how to avoid them.",
          },
        ],
      },
      {
        id: "charitable-giving",
        title: "Charitable Giving",
        prompts: [
          {
            shortTitle: "Strategic Charitable Giving",
            id: "Strategic-Charitable-Giving",
            title:
              "Craft an email campaign introducing the basics of strategic charitable giving.",
          },
          {
            shortTitle: "Charitable Giving Vehicles",
            id: "Charitable-Giving-Vehicles",
            title:
              "Develop an email series explaining different charitable giving vehicles (donor-advised funds, private foundations, etc.).",
          },
          {
            shortTitle: "Tax-Efficient Charitable Giving",
            id: "Tax-Efficient-Charitable-Giving",
            title:
              "Create an email sequence on tax-efficient charitable giving strategies.",
          },
          {
            shortTitle: "Charitable Giving in Financial Plans",
            id: "Charitable-Giving-in-Financial-Plans",
            title:
              "Design an email campaign about incorporating charitable giving into overall financial plans.",
          },
          {
            shortTitle: "Maximizing Impact of Charitable Donations",
            id: "Maximizing-Impact-of-Charitable-Donations",
            title:
              "Compose an email series on how to maximize the impact of charitable donations.",
          },
          {
            shortTitle: "Family Involvement in Philanthropy",
            id: "Family-Involvement-in-Philanthropy",
            title:
              "Develop an email sequence on strategies for involving family members in philanthropic efforts.",
          },
          {
            shortTitle: "Impact Investing",
            id: "Impact-Investing",
            title:
              "Craft an email campaign on the concept of impact investing as a form of charitable giving.",
          },
          {
            shortTitle: "Evaluating Charities",
            id: "Evaluating-Charities",
            title:
              "Design an email sequence on how to evaluate charities and measure the impact of donations.",
          },
          {
            shortTitle: "Creating a Philanthropic Legacy",
            id: "Creating-a-Philanthropic-Legacy",
            title:
              "Develop an email campaign on creating a lasting philanthropic legacy.",
          },
          {
            shortTitle: "Corporate Social Responsibility",
            id: "Corporate-Social-Responsibility",
            title:
              "Compose an email series on the role of corporate social responsibility in charitable giving.",
          },
        ],
      },
      {
        id: "college-savings",
        title: "College Savings",
        prompts: [
          {
            shortTitle: "College Savings Basics",
            id: "College-Savings-Basics",
            title:
              "Craft an email series introducing different college savings vehicles.",
          },
          {
            shortTitle: "529 Plans",
            id: "529-Plans",
            title:
              "Develop an email campaign explaining the benefits and mechanics of 529 plans.",
          },
          {
            shortTitle: "Maximizing Financial Aid Eligibility",
            id: "Maximizing-Financial-Aid-Eligibility",
            title:
              "Create an email sequence on strategies for maximizing financial aid eligibility.",
          },
          {
            shortTitle: "Balancing College Savings",
            id: "Balancing-College-Savings",
            title:
              "Design an email series about balancing college savings with other financial goals.",
          },
          {
            shortTitle: "College Savings for Grandparents",
            id: "College-Savings-for-Grandparents",
            title:
              "Compose an email campaign on college savings strategies for grandparents.",
          },
          {
            shortTitle: "Coverdell Education Savings Accounts",
            id: "Coverdell-Education-Savings-Accounts",
            title:
              "Develop an email sequence on how to use Coverdell Education Savings Accounts.",
          },
          {
            shortTitle: "Alternative Education Funding",
            id: "Alternative-Education-Funding",
            title:
              "Create an email series explaining alternative education funding options.",
          },
          {
            shortTitle: "Adjusting College Savings Strategies",
            id: "Adjusting-College-Savings-Strategies",
            title:
              "Design an email campaign on adjusting college savings strategies as children approach college age.",
          },
          {
            shortTitle: "College Savings in Divorce",
            id: "College-Savings-in-Divorce",
            title:
              "Develop an email sequence on handling college savings in cases of divorce or family changes.",
          },
          {
            shortTitle: "Student Loans vs. College Savings",
            id: "Student-Loans-vs-College-Savings",
            title:
              "Create an email campaign on the pros and cons of student loans vs. college savings.",
          },
        ],
      },
      {
        id: "business-succession",
        title: "Business Succession",
        prompts: [
          {
            shortTitle: "Business Succession Basics",
            id: "Business-Succession-Basics",
            title:
              "Craft an email campaign introducing the basics of business succession planning.",
          },
          {
            shortTitle: "Transferring Business Ownership",
            id: "Transferring-Business-Ownership",
            title:
              "Design an email series on different options for transferring business ownership.",
          },
          {
            shortTitle: "Identifying and Developing Successors",
            id: "Identifying-and-Developing-Successors",
            title:
              "Develop an email sequence on strategies for identifying and developing potential successors.",
          },
          {
            shortTitle: "Valuation Methods for Businesses",
            id: "Valuation-Methods-for-Businesses",
            title:
              "Create an email campaign about valuation methods for closely-held businesses in succession planning.",
          },
          {
            shortTitle: "Tax Considerations in Succession Planning",
            id: "Tax-Considerations-in-Succession-Planning",
            title:
              "Compose an email series on tax considerations in business succession planning.",
          },
          {
            shortTitle: "Personal Retirement Planning Integration",
            id: "Personal-Retirement-Planning-Integration",
            title:
              "Develop an email sequence on integrating personal retirement planning with business succession.",
          },
          {
            shortTitle: "Succession Planning for Family Businesses",
            id: "Succession-Planning-for-Family-Businesses",
            title:
              "Design an email campaign on succession planning for family-owned businesses.",
          },
          {
            shortTitle: "Key Employee Retention",
            id: "Key-Employee-Retention",
            title:
              "Compose an email series on retaining key employees during business transition.",
          },
          {
            shortTitle: "Contingency Planning",
            id: "Contingency-Planning",
            title:
              "Develop an email sequence on creating a contingency plan for unexpected events in business succession.",
          },
          {
            shortTitle: "Common Pitfalls in Succession Planning",
            id: "Common-Pitfalls-in-Succession-Planning",
            title:
              "Create an email campaign on common pitfalls in business succession planning and how to avoid them.",
          },
        ],
      },
    ],
  },

  {
    title: "Newsletters",
    id: "newsletters",

    categories: [
      {
        id: "retirement-planning",
        title: "Retirement Planning",
        prompts: [
          {
            shortTitle: "Retirement Planning Mistakes",
            id: "Retirement-Planning-Mistakes",
            title:
              "Compose a newsletter featuring the top 5 retirement planning mistakes to avoid.",
          },
          {
            shortTitle: "Retirement Account Changes",
            id: "Retirement-Account-Changes",
            title:
              "Create a newsletter explaining the latest changes in retirement account contribution limits.",
          },
          {
            shortTitle: "Maximizing Social Security Benefits",
            id: "Maximizing-Social-Security-Benefits",
            title:
              "Draft a newsletter highlighting strategies for maximizing Social Security benefits.",
          },
          {
            shortTitle: "Retiring Abroad Pros and Cons",
            id: "Retiring-Abroad-Pros-and-Cons",
            title:
              "Develop a newsletter discussing the pros and cons of retiring abroad.",
          },
          {
            shortTitle: "Phased Retirement Strategies",
            id: "Phased-Retirement-Strategies",
            title:
              "Write a newsletter exploring the concept of phased retirement and its potential benefits.",
          },
          {
            shortTitle: "Sustainable Withdrawal Strategy",
            id: "Sustainable-Withdrawal-Strategy",
            title:
              "Craft a newsletter on how to create a sustainable retirement withdrawal strategy.",
          },
          {
            shortTitle: "Required Minimum Distributions (RMDs)",
            id: "Required-Minimum-Distributions-RMDs",
            title:
              "Produce a newsletter addressing common questions about Required Minimum Distributions (RMDs).",
          },
          {
            shortTitle: "Healthcare Planning in Retirement",
            id: "Healthcare-Planning-in-Retirement",
            title:
              "Compose a newsletter on the importance of healthcare planning in retirement.",
          },
          {
            shortTitle: "Catch-Up on Retirement Savings",
            id: "Catch-Up-on-Retirement-Savings",
            title:
              "Develop a newsletter on strategies for catching up on retirement savings.",
          },
          {
            shortTitle: "Inflation Impact on Retirement",
            id: "Inflation-Impact-on-Retirement",
            title:
              "Draft a newsletter on how inflation impacts retirement planning and strategies to combat it.",
          },
        ],
      },
      {
        id: "tax-optimization",
        title: "Tax Optimization",
        prompts: [
          {
            shortTitle: "Tax Law Changes Newsletter",
            id: "Tax-Law-Changes-Newsletter",
            title:
              "Write a newsletter explaining recent tax law changes and their implications for investors.",
          },
          {
            shortTitle: "Year-End Tax Planning Newsletter",
            id: "Year-End-Tax-Planning-Newsletter",
            title:
              "Develop a newsletter highlighting year-end tax planning strategies.",
          },
          {
            shortTitle: "Tax-Efficient Investing Techniques",
            id: "Tax-Efficient-Investing-Techniques",
            title:
              "Craft a newsletter on tax-efficient investing techniques for high-income earners.",
          },
          {
            shortTitle: "Tax-Loss Harvesting Newsletter",
            id: "Tax-Loss-Harvesting-Newsletter",
            title:
              "Produce a newsletter explaining the basics of tax-loss harvesting.",
          },
          {
            shortTitle: "Tax Considerations for Investments",
            id: "Tax-Considerations-for-Investments",
            title:
              "Compose a newsletter on tax considerations for different investment vehicles.",
          },
          {
            shortTitle: "Tax-Efficient Charitable Giving Strategies",
            id: "Tax-Efficient-Charitable-Giving-Strategies",
            title:
              "Create a newsletter discussing tax-efficient charitable giving strategies.",
          },
          {
            shortTitle: "Tax Planning for Small Business Owners",
            id: "Tax-Planning-for-Small-Business-Owners",
            title:
              "Draft a newsletter on tax planning strategies for small business owners.",
          },
          {
            shortTitle: "Capital Gains Taxes Newsletter",
            id: "Capital-Gains-Taxes-Newsletter",
            title:
              "Write a newsletter exploring the tax implications of capital gains taxes.",
          },
          {
            shortTitle: "Retirement Account Withdrawal Taxes",
            id: "Retirement-Account-Withdrawal-Taxes",
            title:
              "Develop a newsletter on tax strategies for retirement account withdrawals.",
          },
          {
            shortTitle: "Real Estate Tax Strategies",
            id: "Real-Estate-Tax-Strategies",
            title:
              "Craft a newsletter on tax strategies for real estate investors.",
          },
        ],
      },
      {
        id: "estate-planning",
        title: "Estate Planning",
        prompts: [
          {
            shortTitle: "Comprehensive Estate Plan Newsletter",
            id: "Comprehensive-Estate-Plan-Newsletter",
            title:
              "Compose a newsletter on the importance of having a comprehensive estate plan.",
          },
          {
            shortTitle: "Wills vs. Trusts Newsletter",
            id: "Wills-vs-Trusts-Newsletter",
            title:
              "Create a newsletter explaining the differences between wills and trusts.",
          },
          {
            shortTitle: "Minimizing Estate Taxes Newsletter",
            id: "Minimizing-Estate-Taxes-Newsletter",
            title:
              "Draft a newsletter discussing strategies for minimizing estate taxes.",
          },
          {
            shortTitle: "Blended Families Estate Planning Newsletter",
            id: "Blended-Families-Estate-Planning-Newsletter",
            title:
              "Develop a newsletter on estate planning considerations for blended families.",
          },
          {
            shortTitle: "Life Insurance in Estate Planning Newsletter",
            id: "Life-Insurance-in-Estate-Planning-Newsletter",
            title:
              "Write a newsletter exploring the role of life insurance in estate planning.",
          },
          {
            shortTitle: "Choosing and Preparing an Executor Newsletter",
            id: "Choosing-and-Preparing-an-Executor-Newsletter",
            title:
              "Craft a newsletter on how to choose and prepare an executor for your estate.",
          },
          {
            shortTitle: "Common Estate Planning Mistakes Newsletter",
            id: "Common-Estate-Planning-Mistakes-Newsletter",
            title:
              "Produce a newsletter addressing common estate planning mistakes and how to avoid them.",
          },
          {
            shortTitle: "Review and Update Estate Plans Newsletter",
            id: "Review-and-Update-Estate-Plans-Newsletter",
            title:
              "Compose a newsletter on the importance of regularly reviewing and updating estate plans.",
          },
          {
            shortTitle: "Digital Asset Management Newsletter",
            id: "Digital-Asset-Management-Newsletter",
            title:
              "Create a newsletter discussing digital asset management in estate planning.",
          },
          {
            shortTitle: "Charitable Giving in Estate Plans Newsletter",
            id: "Charitable-Giving-in-Estate-Plans-Newsletter",
            title:
              "Draft a newsletter on how to incorporate charitable giving into your estate plan.",
          },
        ],
      },
      {
        id: "investment-strategies",
        title: "Investment Strategies",
        prompts: [
          {
            shortTitle: "Asset Allocation Basics Newsletter",
            id: "Asset-Allocation-Basics-Newsletter",
            title:
              "Write a newsletter explaining the concept of asset allocation and its importance.",
          },
          {
            shortTitle: "Active vs. Passive Investing Newsletter",
            id: "Active-vs-Passive-Investing-Newsletter",
            title:
              "Develop a newsletter discussing the pros and cons of active vs. passive investing.",
          },
          {
            shortTitle: "Investing During Market Volatility Newsletter",
            id: "Investing-During-Market-Volatility-Newsletter",
            title:
              "Craft a newsletter on strategies for investing during market volatility.",
          },
          {
            shortTitle: "Emerging Market Opportunities Newsletter",
            id: "Emerging-Market-Opportunities-Newsletter",
            title:
              "Produce a newsletter exploring emerging market investment opportunities.",
          },
          {
            shortTitle: "Alternative Investments Newsletter",
            id: "Alternative-Investments-Newsletter",
            title:
              "Compose a newsletter on the role of alternative investments in a diversified portfolio.",
          },
          {
            shortTitle: "Factor Investing Newsletter",
            id: "Factor-Investing-Newsletter",
            title:
              "Create a newsletter discussing factor investing and its potential benefits.",
          },
          {
            shortTitle: "Sustainable and ESG Investing Newsletter",
            id: "Sustainable-and-ESG-Investing-Newsletter",
            title:
              "Draft a newsletter on sustainable and ESG investing strategies.",
          },
          {
            shortTitle: "Geopolitical Events Impact on Investments Newsletter",
            id: "Geopolitical-Events-Impact-on-Investments-Newsletter",
            title:
              "Write a newsletter exploring the impact of geopolitical events on investment strategies.",
          },
          {
            shortTitle: "Portfolio Rebalancing Newsletter",
            id: "Portfolio-Rebalancing-Newsletter",
            title:
              "Develop a newsletter on the importance of rebalancing investment portfolios.",
          },
          {
            shortTitle: "Mutual Funds or ETFs Selection Newsletter",
            id: "Mutual-Funds-or-ETFs-Selection-Newsletter",
            title:
              "Craft a newsletter on how to evaluate and select mutual funds or ETFs.",
          },
        ],
      },
      {
        id: "risk-management",
        title: "Risk Management",
        prompts: [
          {
            shortTitle: "Risk Management Plan Newsletter",
            id: "Risk-Management-Plan-Newsletter",
            title:
              "Compose a newsletter on creating a comprehensive risk management plan.",
          },
          {
            shortTitle: "Insurance Types Newsletter",
            id: "Insurance-Types-Newsletter",
            title:
              "Create a newsletter explaining different types of insurance and their roles in financial planning.",
          },
          {
            shortTitle: "Sequence of Returns Risk Newsletter",
            id: "Sequence-of-Returns-Risk-Newsletter",
            title:
              "Draft a newsletter discussing strategies for managing sequence of returns risk in retirement.",
          },
          {
            shortTitle: "Cybersecurity Best Practices Newsletter",
            id: "Cybersecurity-Best-Practices-Newsletter",
            title:
              "Develop a newsletter on cybersecurity best practices for protecting personal financial information.",
          },
          {
            shortTitle: "Portfolio Protection with Options Newsletter",
            id: "Portfolio-Protection-with-Options-Newsletter",
            title:
              "Write a newsletter exploring how to use options for portfolio protection.",
          },
          {
            shortTitle: "Concentration Risk Management Newsletter",
            id: "Concentration-Risk-Management-Newsletter",
            title:
              "Craft a newsletter on managing concentration risk in investment portfolios.",
          },
          {
            shortTitle: "Emergency Fund Importance Newsletter",
            id: "Emergency-Fund-Importance-Newsletter",
            title:
              "Produce a newsletter addressing the importance of having an adequate emergency fund.",
          },
          {
            shortTitle: "Asset Protection Strategies Newsletter",
            id: "Asset-Protection-Strategies-Newsletter",
            title:
              "Compose a newsletter on strategies for protecting assets from potential lawsuits.",
          },
          {
            shortTitle: "Currency Risk Management Newsletter",
            id: "Currency-Risk-Management-Newsletter",
            title:
              "Create a newsletter discussing how to manage currency risk in international investments.",
          },
          {
            shortTitle: "Diversification in Risk Management Newsletter",
            id: "Diversification-in-Risk-Management-Newsletter",
            title:
              "Draft a newsletter on the role of diversification in risk management.",
          },
        ],
      },
      {
        id: "financial-independence",
        title: "Financial Independence",
        prompts: [
          {
            shortTitle: "Financial Independence Introduction Newsletter",
            id: "Financial-Independence-Introduction-Newsletter",
            title:
              "Write a newsletter introducing the concept of financial independence and its benefits.",
          },
          {
            shortTitle: "Increasing Savings Rate Newsletter",
            id: "Increasing-Savings-Rate-Newsletter",
            title:
              "Develop a newsletter on strategies for increasing savings rate to achieve financial independence.",
          },
          {
            shortTitle: "Calculating Financial Independence Number Newsletter",
            id: "Calculating-Financial-Independence-Number-Newsletter",
            title:
              "Craft a newsletter explaining how to calculate your personal financial independence number.",
          },
          {
            shortTitle: "FIRE Movement Newsletter",
            id: "FIRE-Movement-Newsletter",
            title:
              "Produce a newsletter discussing the pros and cons of the FIRE (Financial Independence, Retire Early) movement.",
          },
          {
            shortTitle: "Multiple Income Streams Newsletter",
            id: "Multiple-Income-Streams-Newsletter",
            title:
              "Compose a newsletter on creating and growing multiple income streams for financial independence.",
          },
          {
            shortTitle: "Late Starter Financial Independence Newsletter",
            id: "Late-Starter-Financial-Independence-Newsletter",
            title:
              "Create a newsletter exploring strategies for achieving financial independence as a late starter.",
          },
          {
            shortTitle: "Balancing Goals and Lifestyle Newsletter",
            id: "Balancing-Goals-and-Lifestyle-Newsletter",
            title:
              "Draft a newsletter on balancing the pursuit of financial independence with enjoying life in the present.",
          },
          {
            shortTitle: "Financial Independence in Downturns Newsletter",
            id: "Financial-Independence-in-Downturns-Newsletter",
            title:
              "Write a newsletter on maintaining financial independence during economic downturns.",
          },
          {
            shortTitle:
              "Psychological Aspects of Financial Independence Newsletter",
            id: "Psychological-Aspects-of-Financial-Independence-Newsletter",
            title:
              "Develop a newsletter discussing the psychological aspects of pursuing financial independence.",
          },
          {
            shortTitle: "Adjusting Financial Independence Plans Newsletter",
            id: "Adjusting-Financial-Independence-Plans-Newsletter",
            title:
              "Craft a newsletter on how to adjust financial independence plans for changing life goals.",
          },
        ],
      },
      {
        id: "wealth-preservation",
        title: "Wealth Preservation",
        prompts: [
          {
            shortTitle: "Wealth Preservation Basics Newsletter",
            id: "Wealth-Preservation-Basics-Newsletter",
            title:
              "Compose a newsletter on wealth preservation strategies for high-net-worth individuals.",
          },
          {
            shortTitle: "Trusts in Wealth Preservation Newsletter",
            id: "Trusts-in-Wealth-Preservation-Newsletter",
            title:
              "Create a newsletter explaining the role of trusts in wealth preservation.",
          },
          {
            shortTitle: "Wealth Preservation Across Generations Newsletter",
            id: "Wealth-Preservation-Across-Generations-Newsletter",
            title:
              "Draft a newsletter discussing strategies for preserving wealth across multiple generations.",
          },
          {
            shortTitle: "Protecting Wealth from Inflation Newsletter",
            id: "Protecting-Wealth-from-Inflation-Newsletter",
            title:
              "Develop a newsletter on protecting wealth from inflation and economic uncertainties.",
          },
          {
            shortTitle: "Family Offices in Wealth Preservation Newsletter",
            id: "Family-Offices-in-Wealth-Preservation-Newsletter",
            title:
              "Write a newsletter exploring the concept of family offices and their role in wealth preservation.",
          },
          {
            shortTitle: "Insurance Products for Wealth Preservation Newsletter",
            id: "Insurance-Products-for-Wealth-Preservation-Newsletter",
            title:
              "Craft a newsletter on using insurance products for wealth preservation.",
          },
          {
            shortTitle: "Financial Education in Wealth Preservation Newsletter",
            id: "Financial-Education-in-Wealth-Preservation-Newsletter",
            title:
              "Produce a newsletter addressing the importance of financial education in preserving family wealth.",
          },
          {
            shortTitle: "Preserving Wealth in Life Transitions Newsletter",
            id: "Preserving-Wealth-in-Life-Transitions-Newsletter",
            title:
              "Compose a newsletter on strategies for preserving wealth during major life transitions.",
          },
          {
            shortTitle: "Asset Protection Strategies Newsletter",
            id: "Asset-Protection-Strategies-Newsletter",
            title:
              "Create a newsletter discussing how to use asset protection strategies to preserve wealth.",
          },
          {
            shortTitle: "Common Pitfalls in Wealth Preservation Newsletter",
            id: "Common-Pitfalls-in-Wealth-Preservation-Newsletter",
            title:
              "Draft a newsletter on common pitfalls in wealth preservation and how to avoid them.",
          },
        ],
      },
      {
        id: "charitable-giving",
        title: "Charitable Giving",
        prompts: [
          {
            shortTitle: "Strategic Charitable Giving Plan Newsletter",
            id: "Strategic-Charitable-Giving-Plan-Newsletter",
            title:
              "Write a newsletter on creating a strategic charitable giving plan.",
          },
          {
            shortTitle: "Charitable Giving Vehicles Newsletter",
            id: "Charitable-Giving-Vehicles-Newsletter",
            title:
              "Develop a newsletter explaining different charitable giving vehicles (donor-advised funds, private foundations, etc.).",
          },
          {
            shortTitle: "Tax-Efficient Charitable Giving Newsletter",
            id: "Tax-Efficient-Charitable-Giving-Newsletter",
            title:
              "Craft a newsletter on tax-efficient charitable giving strategies.",
          },
          {
            shortTitle: "Maximizing Impact of Charitable Donations Newsletter",
            id: "Maximizing-Impact-of-Charitable-Donations-Newsletter",
            title:
              "Produce a newsletter discussing how to maximize the impact of charitable donations.",
          },
          {
            shortTitle: "Incorporating Charitable Giving Newsletter",
            id: "Incorporating-Charitable-Giving-Newsletter",
            title:
              "Compose a newsletter on incorporating charitable giving into overall financial and estate plans.",
          },
          {
            shortTitle: "Family Involvement in Philanthropy Newsletter",
            id: "Family-Involvement-in-Philanthropy-Newsletter",
            title:
              "Create a newsletter exploring strategies for involving family members in philanthropic efforts.",
          },
          {
            shortTitle: "Impact Investing Newsletter",
            id: "Impact-Investing-Newsletter",
            title:
              "Draft a newsletter on impact investing as a form of charitable giving.",
          },
          {
            shortTitle: "Evaluating Charities Newsletter",
            id: "Evaluating-Charities-Newsletter",
            title:
              "Write a newsletter on how to evaluate charities and measure the impact of donations.",
          },
          {
            shortTitle: "Philanthropic Legacy Planning Newsletter",
            id: "Philanthropic-Legacy-Planning-Newsletter",
            title:
              "Develop a newsletter discussing the concept of legacy planning through charitable giving.",
          },
          {
            shortTitle: "Corporate Social Responsibility Newsletter",
            id: "Corporate-Social-Responsibility-Newsletter",
            title:
              "Craft a newsletter on the role of corporate social responsibility in charitable giving.",
          },
        ],
      },
      {
        id: "college-savings",
        title: "College Savings",
        prompts: [
          {
            shortTitle: "College Savings Vehicles Comparison",
            id: "College-Savings-Vehicles-Comparison",
            title:
              "Compose a newsletter comparing different college savings vehicles.",
          },
          {
            shortTitle: "529 Plans Benefits Newsletter",
            id: "529-Plans-Benefits-Newsletter",
            title:
              "Create a newsletter explaining the benefits and mechanics of 529 plans.",
          },
          {
            shortTitle: "Financial Aid Eligibility Maximization Newsletter",
            id: "Financial-Aid-Eligibility-Maximization-Newsletter",
            title:
              "Draft a newsletter discussing strategies for maximizing financial aid eligibility.",
          },
          {
            shortTitle: "Balancing College Savings Newsletter",
            id: "Balancing-College-Savings-Newsletter",
            title:
              "Develop a newsletter on balancing college savings with other financial goals.",
          },
          {
            shortTitle: "College Savings for Grandparents Newsletter",
            id: "College-Savings-for-Grandparents-Newsletter",
            title:
              "Write a newsletter exploring college savings strategies for grandparents.",
          },
          {
            shortTitle: "Coverdell Education Savings Accounts Newsletter",
            id: "Coverdell-Education-Savings-Accounts-Newsletter",
            title:
              "Craft a newsletter on how to use Coverdell Education Savings Accounts effectively.",
          },
          {
            shortTitle: "Alternative Education Funding Newsletter",
            id: "Alternative-Education-Funding-Newsletter",
            title:
              "Produce a newsletter addressing alternative education funding options.",
          },
          {
            shortTitle: "Adjusting College Savings Strategies Newsletter",
            id: "Adjusting-College-Savings-Strategies-Newsletter",
            title:
              "Compose a newsletter on adjusting college savings strategies as children approach college age.",
          },
          {
            shortTitle: "College Savings in Divorce Newsletter",
            id: "College-Savings-in-Divorce-Newsletter",
            title:
              "Create a newsletter discussing how to handle college savings in cases of divorce or family changes.",
          },
          {
            shortTitle: "Student Loans vs. College Savings Newsletter",
            id: "Student-Loans-vs-College-Savings-Newsletter",
            title:
              "Draft a newsletter on the pros and cons of student loans vs. college savings.",
          },
        ],
      },
      {
        id: "business-succession",
        title: "Business Succession",
        prompts: [
          {
            shortTitle: "Effective Business Succession Plan Newsletter",
            id: "Effective-Business-Succession-Plan-Newsletter",
            title:
              "Write a newsletter on creating an effective business succession plan.",
          },
          {
            shortTitle: "Business Ownership Transfer Options Newsletter",
            id: "Business-Ownership-Transfer-Options-Newsletter",
            title:
              "Develop a newsletter explaining different options for transferring business ownership.",
          },
          {
            shortTitle: "Identifying and Developing Successors Newsletter",
            id: "Identifying-and-Developing-Successors-Newsletter",
            title:
              "Craft a newsletter on strategies for identifying and developing potential successors.",
          },
          {
            shortTitle: "Valuation Methods for Businesses Newsletter",
            id: "Valuation-Methods-for-Businesses-Newsletter",
            title:
              "Produce a newsletter discussing valuation methods for closely-held businesses in succession planning.",
          },
          {
            shortTitle: "Tax Considerations in Succession Planning Newsletter",
            id: "Tax-Considerations-in-Succession-Planning-Newsletter",
            title:
              "Compose a newsletter on tax considerations in business succession planning.",
          },
          {
            shortTitle: "Personal Retirement Planning Integration Newsletter",
            id: "Personal-Retirement-Planning-Integration-Newsletter",
            title:
              "Create a newsletter exploring how to integrate personal retirement planning with business succession.",
          },
          {
            shortTitle: "Succession Planning for Family Businesses Newsletter",
            id: "Succession-Planning-for-Family-Businesses-Newsletter",
            title:
              "Draft a newsletter on succession planning for family-owned businesses.",
          },
          {
            shortTitle: "Common Pitfalls in Succession Planning Newsletter",
            id: "Common-Pitfalls-in-Succession-Planning-Newsletter",
            title:
              "Write a newsletter addressing common pitfalls in business succession planning and how to avoid them.",
          },
          {
            shortTitle: "Key Employee Retention Newsletter",
            id: "Key-Employee-Retention-Newsletter",
            title:
              "Develop a newsletter on strategies for retaining key employees during business transition.",
          },
          {
            shortTitle: "Contingency Planning Newsletter",
            id: "Contingency-Planning-Newsletter",
            title:
              "Craft a newsletter on creating a contingency plan for unexpected events in business succession.",
          },
        ],
      },
    ],
  },

  {
    title: "Marketing Compliance",
    id: "marketing-compliance",
    categories: [
      {
        id: "testimonial-marketing",
        title: "Testimonial Marketing",
        prompts: [
          {
            shortTitle: "Testimonial Compliance Checklist",
            id: "Testimonial-Compliance-Checklist",
            title:
              "Draft a checklist for ensuring client testimonials comply with the SEC's Marketing Rule.",
          },
          {
            shortTitle: "Client Consent Guidelines",
            id: "Client-Consent-Guidelines",
            title:
              "Compose guidelines for obtaining and documenting client consent for testimonials.",
          },
          {
            shortTitle: "Balanced Testimonial Criteria",
            id: "Balanced-Testimonial-Criteria",
            title:
              "Create a set of criteria for selecting balanced and representative testimonials.",
          },
          {
            shortTitle: "Conflict of Interest Disclosures",
            id: "Conflict-of-Interest-Disclosures",
            title:
              "Develop a framework for disclosing material conflicts of interest in testimonials.",
          },
          {
            shortTitle: "Attribution and Dating Guidelines",
            id: "Attribution-and-Dating-Guidelines",
            title:
              "Write a guide on how to properly attribute and date testimonials in marketing materials.",
          },
          {
            shortTitle: "Review and Update Policy",
            id: "Review-and-Update-Policy",
            title:
              "Create a process for regularly reviewing and updating published testimonials.",
          },
          {
            shortTitle: "Negative Testimonial Handling",
            id: "Negative-Testimonial-Handling",
            title:
              "Draft a policy for handling and responding to negative testimonials or reviews.",
          },
          {
            shortTitle: "Employee Testimonial Guidelines",
            id: "Employee-Testimonial-Guidelines",
            title:
              "Compose guidelines for using employee and related person testimonials.",
          },
          {
            shortTitle: "Staff Training Module",
            id: "Staff-Training-Module",
            title:
              "Develop a training module for staff on compliant use of testimonials in marketing.",
          },
          {
            shortTitle: "Compensation Disclosure Template",
            id: "Compensation-Disclosure-Template",
            title:
              "Create a template for disclosing compensation related to testimonials.",
          },
        ],
      },
      {
        id: "                        performance-advertising",
        title: "Performance Advertising",
        prompts: [
          {
            shortTitle: "Performance Advertising Checklist",
            id: "Performance-Advertising-Checklist",
            title:
              "Draft a checklist for reviewing performance advertising materials for compliance.",
          },
          {
            shortTitle: "Hypothetical Performance Guidelines",
            id: "Hypothetical-Performance-Guidelines",
            title:
              "Compose guidelines for presenting hypothetical performance in marketing materials.",
          },
          {
            shortTitle: "Fair and Balanced Presentation",
            id: "Fair-and-Balanced-Presentation",
            title:
              "Develop a framework for ensuring fair and balanced presentation of performance data.",
          },
          {
            shortTitle: "Net-of-Fees Performance Process",
            id: "Net-of-Fees-Performance-Process",
            title:
              "Create a process for calculating and presenting net-of-fees performance.",
          },
          {
            shortTitle: "Benchmarks in Performance Advertising",
            id: "Benchmarks-in-Performance-Advertising",
            title:
              "Write a guide on proper use of benchmarks in performance advertising.",
          },
          {
            shortTitle: "Model Portfolio Performance Compliance",
            id: "Model-Portfolio-Performance-Compliance",
            title:
              "Create a checklist for compliance when advertising model portfolio performance.",
          },
          {
            shortTitle: "Material Risks Disclosure Policies",
            id: "Material-Risks-Disclosure-Policies",
            title:
              "Draft policies for disclosing material risks associated with investment strategies.",
          },
          {
            shortTitle: "Related Performance Information Guidelines",
            id: "Related-Performance-Information-Guidelines",
            title:
              "Compose guidelines for presenting related performance information.",
          },
          {
            shortTitle: "Performance Data Review Process",
            id: "Performance-Data-Review-Process",
            title:
              "Develop a process for reviewing and updating performance data in ongoing campaigns.",
          },
          {
            shortTitle: "Standardized Performance Disclosure Template",
            id: "Standardized-Performance-Disclosure-Template",
            title:
              "Create a template for standardized performance disclosure statements.",
          },
        ],
      },
      {
        id: "social-media-compliance",
        title: "Social Media Compliance",
        prompts: [
          {
            shortTitle: "Social Media Policy Draft",
            id: "Social-Media-Policy-Draft",
            title:
              "Draft a social media policy that aligns with SEC marketing regulations.",
          },
          {
            shortTitle: "Employee Social Media Use Guidelines",
            id: "Employee-Social-Media-Use-Guidelines",
            title:
              "Compose guidelines for employees' personal use of social media related to the firm.",
          },
          {
            shortTitle: "Archiving and Monitoring Process",
            id: "Archiving-and-Monitoring-Process",
            title:
              "Create a process for archiving and monitoring social media activities.",
          },
          {
            shortTitle: "Client Comment Response Framework",
            id: "Client-Comment-Response-Framework",
            title:
              "Develop a framework for responding to client comments or messages on social media.",
          },
          {
            shortTitle: "Third-Party Content Use Guide",
            id: "Third-Party-Content-Use-Guide",
            title:
              "Write a guide on compliant use of third-party content on social media platforms.",
          },
          {
            shortTitle: "Social Media Post Review Checklist",
            id: "Social-Media-Post-Review-Checklist",
            title:
              "Create a checklist for reviewing and approving social media posts before publication.",
          },
          {
            shortTitle: "Consistent Material Information Policies",
            id: "Consistent-Material-Information-Policies",
            title:
              "Draft policies for disclosing material information consistently across all platforms.",
          },
          {
            shortTitle: "Hashtags and Captions Guidelines",
            id: "Hashtags-and-Captions-Guidelines",
            title:
              "Compose guidelines for using hashtags and captions in a compliant manner.",
          },
          {
            shortTitle: "Social Media Best Practices Training",
            id: "Social-Media-Best-Practices-Training",
            title:
              "Develop a training program on social media best practices for financial advisors.",
          },
          {
            shortTitle: "Standardized Profile Disclosure Template",
            id: "Standardized-Profile-Disclosure-Template",
            title:
              "Create a template for standardized social media profile disclosures.",
          },
        ],
      },
      {
        id: "disclosures-and-disclaimers",
        title: "Disclosures and Disclaimers",
        prompts: [
          {
            shortTitle: "Required Disclosure List",
            id: "Required-Disclosure-List",
            title:
              "Draft a comprehensive list of required disclosures for different types of marketing materials.",
          },
          {
            shortTitle: "Clear and Prominent Disclosure Guidelines",
            id: "Clear-and-Prominent-Disclosure-Guidelines",
            title:
              "Compose guidelines for ensuring disclosures are clear, prominent, and not misleading.",
          },
          {
            shortTitle: "Review and Update Process",
            id: "Review-and-Update-Process",
            title:
              "Create a process for regularly reviewing and updating disclosure statements.",
          },
          {
            shortTitle: "Audience-Specific Disclosure Framework",
            id: "Audience-Specific-Disclosure-Framework",
            title:
              "Develop a framework for tailoring disclosures to specific audience sophistication levels.",
          },
          {
            shortTitle: "Placement and Formatting Guide",
            id: "Placement-and-Formatting-Guide",
            title:
              "Write a guide on proper placement and formatting of disclosures in various media.",
          },
          {
            shortTitle: "Disclosure Checklist",
            id: "Disclosure-Checklist",
            title:
              "Create a checklist for reviewing marketing materials to ensure all necessary disclosures are included.",
          },
          {
            shortTitle: "Conflict of Interest Disclosure Policies",
            id: "Conflict-of-Interest-Disclosure-Policies",
            title:
              "Draft policies for disclosing potential conflicts of interest in marketing materials.",
          },
          {
            shortTitle: "Layered Disclosure Guidelines",
            id: "Layered-Disclosure-Guidelines",
            title:
              "Compose guidelines for creating layered disclosures in digital marketing.",
          },
          {
            shortTitle: "Consistent Disclosure Process",
            id: "Consistent-Disclosure-Process",
            title:
              "Develop a process for ensuring consistent disclosures across all marketing channels.",
          },
          {
            shortTitle: "Standardized Risk Disclosure Template",
            id: "Standardized-Risk-Disclosure-Template",
            title:
              "Create a template for standardized risk disclosure statements.",
          },
        ],
      },
      {
        id: "third-party-ratings-and-rankings",
        title: "Third-Party Ratings and Rankings",
        prompts: [
          {
            shortTitle: "Rating Vetting Checklist",
            id: "Rating-Vetting-Checklist",
            title:
              "Draft a checklist for vetting third-party ratings before use in marketing materials.",
          },
          {
            shortTitle: "Rating Criteria Disclosure Guidelines",
            id: "Rating-Criteria-Disclosure-Guidelines",
            title:
              "Compose guidelines for disclosing the criteria and methodology of third-party ratings.",
          },
          {
            shortTitle: "Fair and Balanced Presentation Process",
            id: "Fair-and-Balanced-Presentation-Process",
            title:
              "Create a process for ensuring fair and balanced presentation of ratings and rankings.",
          },
          {
            shortTitle: "Conflict of Interest Addressing Framework",
            id: "Conflict-of-Interest-Addressing-Framework",
            title:
              "Develop a framework for addressing potential conflicts of interest in third-party ratings.",
          },
          {
            shortTitle: "Rating Attribution and Dating Guide",
            id: "Rating-Attribution-and-Dating-Guide",
            title:
              "Write a guide on proper attribution and dating of third-party ratings in marketing.",
          },
          {
            shortTitle: "Rating Changes Response Policy",
            id: "Rating-Changes-Response-Policy",
            title:
              "Create a policy for responding to changes or updates in third-party ratings.",
          },
          {
            shortTitle: "Digital Marketing Rating Use Guidelines",
            id: "Digital-Marketing-Rating-Use-Guidelines",
            title:
              "Draft guidelines for using third-party ratings in social media and digital marketing.",
          },
          {
            shortTitle: "Rating Feature Selection Criteria",
            id: "Rating-Feature-Selection-Criteria",
            title:
              "Compose a set of criteria for selecting which ratings or rankings to feature in marketing.",
          },
          {
            shortTitle: "Staff Training Module",
            id: "Staff-Training-Module",
            title:
              "Develop a training module on compliant use of third-party ratings for marketing staff.",
          },
          {
            shortTitle: "Standardized Rating Disclosure Template",
            id: "Standardized-Rating-Disclosure-Template",
            title:
              "Create a template for standardized disclosures related to third-party ratings and rankings.",
          },
        ],
      },

      {
        id: "recordkeeping-for-marketing-materials",
        title: "Recordkeeping for Marketing Materials",
        prompts: [
          {
            shortTitle: "Recordkeeping Policy Draft",
            id: "Recordkeeping-Policy-Draft",
            title:
              "Draft a comprehensive recordkeeping policy for all marketing materials and activities.",
          },
          {
            shortTitle: "Review and Approval Documentation Guidelines",
            id: "Review-and-Approval-Documentation-Guidelines",
            title:
              "Compose guidelines for maintaining records of the review and approval process for marketing materials.",
          },
          {
            shortTitle: "Archiving and Versioning System",
            id: "Archiving-and-Versioning-System",
            title:
              "Create a system for archiving different versions of marketing materials over time.",
          },
          {
            shortTitle: "Rationale Documentation Framework",
            id: "Rationale-Documentation-Framework",
            title:
              "Develop a framework for documenting the rationale behind marketing claims and strategies.",
          },
          {
            shortTitle: "Performance Calculation Documentation Guide",
            id: "Performance-Calculation-Documentation-Guide",
            title:
              "Write a guide on proper documentation of performance calculation methodologies.",
          },
          {
            shortTitle: "Marketing Material Checklist",
            id: "Marketing-Material-Checklist",
            title:
              "Create a checklist for ensuring all necessary elements are included in marketing material records.",
          },
          {
            shortTitle: "Retention and Disposal Policies",
            id: "Retention-and-Disposal-Policies",
            title:
              "Draft policies for retention and disposal of marketing records in compliance with SEC rules.",
          },
          {
            shortTitle: "Client Communication Recordkeeping",
            id: "Client-Communication-Recordkeeping",
            title:
              "Compose guidelines for maintaining records of client communications related to marketing.",
          },
          {
            shortTitle: "Regular Audit Process",
            id: "Regular-Audit-Process",
            title:
              "Develop a process for regular audits of marketing material records.",
          },
          {
            shortTitle: "Standardized Log Template",
            id: "Standardized-Log-Template",
            title:
              "Create a template for standardized record-keeping logs for marketing activities.",
          },
        ],
      },
      {
        id: "website-and-digital-marketing-compliance",
        title: "Website and Digital Marketing Compliance",
        prompts: [
          {
            shortTitle: "Website Compliance Checklist",
            id: "Website-Compliance-Checklist",
            title:
              "Draft a checklist for ensuring website content complies with SEC marketing regulations.",
          },
          {
            shortTitle: "Advisor Credentials Presentation Guidelines",
            id: "Advisor-Credentials-Presentation-Guidelines",
            title:
              "Compose guidelines for presenting advisor credentials and experience on the firm website.",
          },
          {
            shortTitle: "Content Review and Update Process",
            id: "Content-Review-and-Update-Process",
            title:
              "Create a process for regularly reviewing and updating website content for compliance.",
          },
          {
            shortTitle: "Consistent Disclosure Framework",
            id: "Consistent-Disclosure-Framework",
            title:
              "Develop a framework for ensuring consistent disclosures across all web pages.",
          },
          {
            shortTitle: "Secure Client Communication Guidelines",
            id: "Secure-Client-Communication-Guidelines",
            title:
              "Write a guide on compliant use of client portals and secure client communications.",
          },
          {
            shortTitle: "Blog Post Management Policy",
            id: "Blog-Post-Management-Policy",
            title:
              "Create a policy for managing and monitoring blog posts and other dynamic content.",
          },
          {
            shortTitle: "Landing Page Compliance Guidelines",
            id: "Landing-Page-Compliance-Guidelines",
            title:
              "Draft guidelines for compliant use of landing pages and lead generation forms.",
          },
          {
            shortTitle: "SEO Best Practices",
            id: "SEO-Best-Practices",
            title:
              "Compose a set of best practices for search engine optimization (SEO) that align with SEC rules.",
          },
          {
            shortTitle: "Third-Party Widget Review Process",
            id: "Third-Party-Widget-Review-Process",
            title:
              "Develop a process for reviewing and approving third-party widgets or plugins on the website.",
          },
          {
            shortTitle: "Standardized Footer Disclosure Template",
            id: "Standardized-Footer-Disclosure-Template",
            title:
              "Create a template for standardized website footer disclosures.",
          },
        ],
      },

      {
        id: "advertising-review-process",
        title: "Advertising Review Process",
        prompts: [
          {
            shortTitle: "Review Workflow Draft",
            id: "Review-Workflow-Draft",
            title:
              "Draft a comprehensive workflow for the review and approval of marketing materials.",
          },
          {
            shortTitle: "Review Level Guidelines",
            id: "Review-Level-Guidelines",
            title:
              "Compose guidelines for different levels of review based on content type and risk level.",
          },
          {
            shortTitle: "Compliance Checklist",
            id: "Compliance-Checklist",
            title:
              "Create a checklist for reviewers to ensure all compliance aspects are considered.",
          },
          {
            shortTitle: "Compliance Issue Documentation Framework",
            id: "Compliance-Issue-Documentation-Framework",
            title:
              "Develop a framework for documenting and addressing compliance issues in marketing materials.",
          },
          {
            shortTitle: "Peer Review Guide",
            id: "Peer-Review-Guide",
            title:
              "Write a guide on how to conduct effective peer reviews of marketing content.",
          },
          {
            shortTitle: "Rush Request Handling Policy",
            id: "Rush-Request-Handling-Policy",
            title:
              "Create a policy for handling rush requests and time-sensitive marketing materials.",
          },
          {
            shortTitle: "Legal and Compliance Input Integration Guidelines",
            id: "Legal-and-Compliance-Input-Integration-Guidelines",
            title:
              "Draft guidelines for incorporating legal and compliance team input in the review process.",
          },
          {
            shortTitle: "External Legal Review Criteria",
            id: "External-Legal-Review-Criteria",
            title:
              "Compose a set of criteria for determining when external legal review is necessary.",
          },
          {
            shortTitle: "Review Process Training Program",
            id: "Review-Process-Training-Program",
            title:
              "Develop a training program for marketing staff on the review and approval process.",
          },
          {
            shortTitle: "Standardized Submission Form Template",
            id: "Standardized-Submission-Form-Template",
            title:
              "Create a template for standardized marketing material submission forms.",
          },
        ],
      },
      {
        id: "regulatory-filing-requirements",
        title: "Regulatory Filing Requirements",
        prompts: [
          {
            shortTitle: "Filing Requirement Checklist",
            id: "Filing-Requirement-Checklist",
            title:
              "Draft a checklist of all marketing-related regulatory filing requirements.",
          },
          {
            shortTitle: "Form ADV Update Guidelines",
            id: "Form-ADV-Update-Guidelines",
            title:
              "Compose guidelines for preparing and submitting Form ADV updates related to marketing practices.",
          },
          {
            shortTitle: "Filing Deadline Tracking Process",
            id: "Filing-Deadline-Tracking-Process",
            title:
              "Create a process for tracking and meeting filing deadlines for marketing materials.",
          },
          {
            shortTitle: "Consistency Assurance Framework",
            id: "Consistency-Assurance-Framework",
            title:
              "Develop a framework for ensuring consistency between marketing materials and regulatory filings.",
          },

          {
            shortTitle: "Regulatory Inquiry Response Guide",
            id: "Regulatory-Inquiry-Response-Guide",
            title:
              "Write a guide on how to respond to regulatory inquiries about marketing practices.",
          },
          {
            shortTitle: "Internal Review Policy",
            id: "Internal-Review-Policy",
            title:
              "Create a policy for internal review of regulatory filings before submission.",
          },
          {
            shortTitle: "Filing Recordkeeping Guidelines",
            id: "Filing-Recordkeeping-Guidelines",
            title:
              "Draft guidelines for maintaining records of all marketing-related regulatory filings.",
          },
          {
            shortTitle: "Change Disclosure Best Practices",
            id: "Change-Disclosure-Best-Practices",
            title:
              "Compose a set of best practices for disclosing changes in marketing practices to regulators.",
          },
          {
            shortTitle: "Regulatory Changes Monitoring Process",
            id: "Regulatory-Changes-Monitoring-Process",
            title:
              "Develop a process for staying informed about changes in regulatory filing requirements.",
          },
          {
            shortTitle: "Internal Reporting Template",
            id: "Internal-Reporting-Template",
            title:
              "Create a template for standardized internal reports on regulatory filing status.",
          },
        ],
      },
      {
        id: "ethical-marketing-practices",
        title: "Ethical Marketing Practices",
        prompts: [
          {
            shortTitle: "Marketing Code of Ethics Draft",
            id: "Marketing-Code-of-Ethics-Draft",
            title:
              "Draft a code of ethics specifically addressing marketing and advertising practices.",
          },
          {
            shortTitle: "False or Misleading Content Guidelines",
            id: "False-or-Misleading-Content-Guidelines",
            title:
              "Compose guidelines for ensuring marketing materials are not false or misleading.",
          },
          {
            shortTitle: "Claim Accuracy Verification Process",
            id: "Claim-Accuracy-Verification-Process",
            title:
              "Create a process for verifying the accuracy of all claims made in marketing materials.",
          },
          {
            shortTitle: "Conflict of Interest Addressing Framework",
            id: "Conflict-of-Interest-Addressing-Framework",
            title:
              "Develop a framework for addressing potential conflicts of interest in marketing.",
          },
          {
            shortTitle: "Fees and Compensation Transparency Policy",
            id: "Fees-and-Compensation-Transparency-Policy",
            title:
              "Write a guide on how to present fees and compensation in a transparent manner.",
          },
          {
            shortTitle: "Competitor Comparison Handling Policy",
            id: "Competitor-Comparison-Handling-Policy",
            title:
              "Create a policy for handling competitor comparisons in marketing materials.",
          },
          {
            shortTitle: "Client Data Use Guidelines",
            id: "Client-Data-Use-Guidelines",
            title:
              "Draft guidelines for ethical use of client data in marketing activities.",
          },
          {
            shortTitle: "Targeting Best Practices",
            id: "Targeting-Best-Practices",
            title:
              "Compose a set of best practices for targeting marketing efforts appropriately.",
          },
          {
            shortTitle: "Ethical Decision-Making Training Program",
            id: "Ethical-Decision-Making-Training-Program",
            title:
              "Develop a training program on ethical decision-making in marketing for all staff.",
          },
          {
            shortTitle: "Annual Self-Assessment Template",
            id: "Annual-Self-Assessment-Template",
            title:
              "Create a template for an annual ethical marketing practices self-assessment.",
          },
        ],
      },
    ],
  },
];

export const welcomeSuggestions = [
  {
    text: "Employ the concept of Brand Ecosystem Development to evaluate my client engagement strategy.",
  },
  {
    text: "Evaluate my client acquisition strategy using the Innovation Ambition Matrix.",
  },
  {
    text: "Implement Lean Startup Principles to evaluate my client service offering.",
  },
  {
    text: "Compose an insightful article on a relevant financial topic, such as 'tax-efficient investing.'",
  },
];
