import React, { useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { FaPen } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { TbLayoutSidebarLeftCollapseFilled, TbLayoutSidebarLeftExpandFilled } from "react-icons/tb";

import {
  CustomAddButton,
  CustomButton,
  CustomCancelButton,
} from "../components/CustomButton";
import CustomDropdown from "../components/CustomDropdown";
import CustomModal from "../components/CustomModal";
import CustomTextArea from "../components/CustomTextArea";
import DeleteConfirmationModal from "../components/DeleteConfirmationModal";
import { InfoIconWithText } from "../components/InfoIcon";
import QueriesAndResultsModal from "../components/QueriesAndResultsModal";
import Sidebar from "../components/Sidebar/Sidebar";
import { useGenerateHandler, createSectionContentText } from "../logic/generate";
import { Tooltip } from "@mui/material";
import {
  IoArrowRedoSharp,
  IoCheckmarkCircleOutline,
  IoCopyOutline,
} from "react-icons/io5";
import { useHistory } from "react-router-dom";
import BouncingDots from "../components/Loaders/BouncingDots/BouncingDots";
import { useToast } from "../context/ToastContext";
import { setMessageForReview } from "../features/compliance/complianceSlice.ts";
import { useAppDispatch, useAppSelector } from "../hooks.ts";
//import { KnowledgeBaseType } from "../utils/knowledge_bases.ts";

import { WorkflowCustomConfigModal } from "../components/WorkflowConfigModal";
import { useWorkflowHandler } from "../logic/workflow.js";

import { longFormPresets } from "../utils/longFormPresets";
import { useAuthExpiry } from "../logic/auth";
import "./LongForm.css";

const LongForm = () => {

  useAuthExpiry();

  const token = sessionStorage.getItem('token');

  const theme = { theme: "light" };
  const { toggleToast } = useToast();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const savedSelectedWorkflow = localStorage.getItem("longFormSelectedWorkflow");

  //const data = useAppSelector((state) => state.auth.user);

  const [knowledgeBaseIds, setKnowledgeBaseIds] = useState([]);

  const { listWorkflows, workflows } = useWorkflowHandler(token);

  const [selectedWorkflow, setSelectedWorkflow] = useState(savedSelectedWorkflow === null ? "none" : savedSelectedWorkflow);
  const [workflowIds, setWorkflowIds] = useState([]);
  const [workflowNames, setWorkflowNames] = useState([]);
  //console.log("selectedWorkflow", selectedWorkflow)

  const messagesEndRef = useRef(null);

  const [model, setModel] = useState("claude-3-sonnet");
  const [temperature, setTemperature] = useState(0.4);
  const [autoQueryGuidance, setAutoQueryGuidance] = useState("");
  const [responseLength, setResponseLength] = useState("medium");
  const [useWebSearch, setUseWebSearch] = useState(false);
  const [includeDomains, setIncludeDomains] = useState("");
  const [timeframe, setTimeframe] = useState("any");

  const [queries, setQueries] = useState([]);
  const [results, setResults] = useState([]);
  const [citedResults, setCitedResults] = useState([]);
  const [selectedSource, setSelectedSource] = useState(null);
  const [showQueriesAndResultsModal, setShowQueriesAndResultsModal] = useState(false);

  const [promptText, setPromptText] = useState("");

  const [showConfigModal, setShowConfigModal] = useState(false);
  const [showPromptAndConfig, setShowPromptAndConfig] = useState(true);

  const [preset, setPreset] = useState("none");
  const [presetSystemMessage, setPresetSystemMessage] = useState("");
  const [systemMessage, setSystemMessage] = useState("");

  let presets = longFormPresets.map((item) => item.value);
  presets.unshift("none");
  let presetTitles = longFormPresets.map((item) => item.title);
  presetTitles.unshift("None");

  const [generationToDelete, setGenerationToDelete] = useState(null);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);

  const [sectionEditing, setSectionEditing] = useState(null);
  const [updatedSectionTitle, setUpdatedSectionTitle] = useState(null);
  const [updatedSectionContent, setUpdatedSectionContent] = useState(null);

  const [showHumanizeModal, setHumanizeModalOpen] = useState(false);
  const [humanizeSection, setHumanizeSection] = useState(null);
  const [humanizeText, setHumanizeText] = useState("");
  const [styleSample, setStyleSample] = useState("");

  const [showRightSidebar, setShowRightSidebar] = useState(true);

  const state = useAppSelector((state) => state);
  const {
    sendGeneratePrompt,
    resultData,
    requestData,
    isLoading,
    buttonDisabled,
    createNewGeneration,
    generations,
    currentGeneration,
    getGeneration,
    updateGeneration,
    deleteGeneration,
    reviseGeneration
  } = useGenerateHandler(
    setShowPromptAndConfig,
    state.auth.idToken,
    state.auth.user._id,
  );

  console.log("resultData", resultData)

  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  function openHumanizeModal() {
    // Open the humanize modal
    setHumanizeModalOpen(true);
  }

  async function reviseContent(styleSampleInput) {
    console.log("styleSample", styleSampleInput);
    console.log("humanizeText", humanizeText);
    console.log("humanizeSection", humanizeSection);
    setHumanizeModalOpen(false);
    reviseGeneration(humanizeText, styleSampleInput, humanizeSection);
  }

  function updatePreset(val) {
    setPreset(val);
    // Set the system message based on the use case
    if (val === "none") {
      //setSystemMessage(data.system_message ?? "")
    } else {
      const updatedSytemMessage = longFormPresets.filter((item) => item.value === val)[0].systemMessage;
      setPresetSystemMessage(updatedSytemMessage);
    }

  }

  function sendToCompliance(data) {
    // console.log("updatedSectionContent", updatedSectionContent);
    dispatch(setMessageForReview(data));
    history.push("/compliance");
  }

  function copyMessageText(content) {
    // Copy the text to clipboard
    navigator.clipboard.writeText(content);

    // Show the copied text for 3 seconds
    toggleToast("Copied to clipboard", false);
    setShowCopiedMessage(true);
    setTimeout(() => {
      setShowCopiedMessage(false);
    }, 3000);
  }

  function copyFullText() {
    // Loop through the sections and copy the title, content, and sources

    // Add in the title first
    let fullText = "";

    fullText += resultData["title"] + "\n\n";
    resultData["sections"].forEach((section, index) => {
      fullText += section["title"] + "\n\n";
      fullText += section["content"] + "\n\n";

      /*fullText += "Sources:\n";
      resultData["sources"][index].forEach((source) => {
        fullText += source["title"] + "\n";
      });*/
      if (resultData["webSources"][index].length > 0) {
        fullText += "Web sources:\n";
      }
      // Add in the web sources. We'll use the url for these
      resultData["webSources"][index].forEach((source) => {
        fullText += source["url"] + "\n";
      });
      fullText += "\n";
    });
    console.log("fullText", fullText)

    navigator.clipboard.writeText(fullText);

    // Show the copied text for 3 seconds
    //toggleToast("Copied to clipboard", false);
    setShowCopiedMessage(true);
    setTimeout(() => {
      setShowCopiedMessage(false);
    }, 3000);
  }

  function updateSelectedWorkflow(newWorkflow) {
    setSelectedWorkflow(newWorkflow);
    console.log("newWorkflow", newWorkflow)
    localStorage.setItem("longFormSelectedWorkflow", newWorkflow)

    if (newWorkflow === "none") {
      setKnowledgeBaseIds([]);
    }

    const workflowData = workflows.filter((item) => item._id === newWorkflow)[0];
    console.log("workflowData", workflowData)
    // If the workflow doesn't exist, return
    if (workflowData === undefined) {
      return;
    }

    setKnowledgeBaseIds([workflowData.knowledge_base_id]);

  }

  function updateConfigOptions(updatedResponseConfig, updatedWebSearchConfig, updatedSelectedWorkflow) {
    //setShowConfigModal(false);
    setSelectedWorkflow(updatedSelectedWorkflow);
    //console.log("updatedSelectedWorkflow", updatedSelectedWorkflow)
    localStorage.setItem("longFormSelectedWorkflow", updatedSelectedWorkflow)
    console.log("updatedSelectedWorkflow", updatedSelectedWorkflow)
    if (updatedSelectedWorkflow !== "none") {
      // Update all of the params from the selected workflow
      const workflowData = workflows.filter((item) => item._id === updatedSelectedWorkflow)[0];
      // If the workflow doesn't exist, return
      if (workflowData === undefined) {
        return;
      }
      console.log("workflowData", workflowData);
      setModel(workflowData.model);
      setSystemMessage(workflowData.system_message);
      setTemperature(workflowData.temperature);
      setStyleSample(workflowData.writing_style);
      setResponseLength(workflowData.responseLength);

      // Set the web search config
      setUseWebSearch(workflowData.use_web_search);
      let includeDomainData = workflowData.web_search_preset === undefined ? null : workflowData.web_search_preset.include_domains;
      setIncludeDomains(includeDomainData);

      // Set the timeframe
      setTimeframe(workflowData.web_search_preset === undefined ? 10000 : workflowData.web_search_preset.timeframe_days);

      // KB ids
      setKnowledgeBaseIds([workflowData.knowledge_base_id]);
    } else {
      console.log("updatedWebSearchConfig", updatedWebSearchConfig)
      // Set all of the params based on the updated config
      setModel(updatedResponseConfig.model);
      setSystemMessage(updatedResponseConfig.systemMessage);
      setStyleSample(updatedResponseConfig.writingStyle);
      setResponseLength(updatedResponseConfig.responseLength);

      // Web search config (these have already been formatted properly)
      setUseWebSearch(updatedWebSearchConfig.useWebSearch);
      setIncludeDomains(updatedWebSearchConfig.includeDomains);
      setTimeframe(updatedWebSearchConfig.timeframe);
    }
  }

  function updateSectionTitle(text, sectionIndex) {
    console.log("sectionIndex", sectionIndex);
    setUpdatedSectionTitle(text);
  }

  function updateSectionContent(text, sectionIndex) {
    console.log("sectionIndex", sectionIndex);
    setUpdatedSectionContent(text);
  }

  function showQueriesAndResults(queries, results, sources, source) {
    setQueries(queries);
    setResults(results);
    setCitedResults(sources);
    setSelectedSource(source);
    setShowQueriesAndResultsModal(true);
  }

  useEffect(() => {
    if (workflows.length === 0) {
      listWorkflows();
    }
  }, []);

  useEffect(() => {
    const workflowIdData = workflows.map((workflow) => workflow._id);
    // Append "none" to the list of workflow ids
    workflowIdData.unshift("none");
    setWorkflowIds(workflowIdData);
    const workflowNameData = workflows.map((workflow) => workflow.name);
    // Append "None" to the list of workflow ids
    workflowNameData.unshift("None");
    setWorkflowNames(workflowNameData);
  }, [workflows])


  useEffect(() => {
    console.log("savedSelectedWorkflow", savedSelectedWorkflow)
    if ((savedSelectedWorkflow !== null && savedSelectedWorkflow !== "none") && workflows.length > 0) {
      console.log("updating config options")
      updateConfigOptions(null, null, savedSelectedWorkflow);
    } else if (savedSelectedWorkflow === null && workflows.length > 0) {
      console.log("test")
      setSelectedWorkflow(workflows[0]._id);
      setKnowledgeBaseIds([workflows[0].knowledge_base_id]);
      updateConfigOptions(null, null, workflows[0]._id);
    } else if (workflows.length === 0) {
      setSelectedWorkflow("none");
    }
  }, [savedSelectedWorkflow, workflows])


  useEffect(() => {
    // When the current generation changes, check the KB id used
    // We want to use the writing style from the workflow that was used for this generation
    if (currentGeneration !== null && currentGeneration !== undefined) {
      const kbIDs = currentGeneration.request.knowledge_base_ids;
      if (kbIDs.length === 1) {
        // Find this KB id in the workflows
        if (workflows.length > 0) {
          const workflowData = workflows.filter((item) => item.knowledge_base_id === kbIDs[0])[0];
          if (workflowData !== undefined) {
            // Get the writing style from it
            const writingStyle = workflowData.writing_style;
            console.log("writingStyle", writingStyle);
            setStyleSample(writingStyle);
          }
        }
      }
    }
  }, [currentGeneration])



  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Sidebar currentPage={"long form"} />
      <div className={`generate-page-section`}>
        <div className={`long-form-page-main-container`}>
          {isLoading && <BouncingDots />}

          {showDeleteConfirmationModal && (
            <DeleteConfirmationModal
              isOpen={showDeleteConfirmationModal}
              closeModal={() => setShowDeleteConfirmationModal(false)}
              delete={() => {
                deleteGeneration(generationToDelete);
                setShowDeleteConfirmationModal(false);
              }}
              title={"Delete Long Form generation"}
              confirmationText={`Are you sure you want to delete this long form generation?`}
            />
          )}

          {showHumanizeModal &&
            <HumanizeModal
              closeModal={() => setHumanizeModalOpen(false)}
              isOpen={showHumanizeModal}
              presetStyleSample={styleSample}
              onClick={(styleSampleInput) => reviseContent(styleSampleInput)}
            />
          }

          {showConfigModal &&
            <WorkflowCustomConfigModal
              isOpen={showConfigModal}
              closeModal={() => setShowConfigModal(false)}
              workflows={workflows}
              preselectedWorkflow={selectedWorkflow}
              showWorkflows={false}
              onClick={(updatedResponseConfig, updatedWebSearchConfig) =>
                updateConfigOptions(updatedResponseConfig, updatedWebSearchConfig, null)}
            />
          }

          {showQueriesAndResultsModal && (
            <QueriesAndResultsModal
              onClose={() => setShowQueriesAndResultsModal(false)}
              queries={queries}
              citedResults={citedResults}
              allResults={results}
              theme={theme.theme}
              selectedSource={selectedSource}
            />
          )}

          <div id="generate-main-container">
            {resultData == null && (
              <div className="generate-input-container">
                {/* <div style={{ width: "100%" }}>
                  <p
                    onClick={() => setShowConfigModal(true)}
                    className={`generate-page-clickable-text-${theme.theme}`}
                  >
                    Edit model configuration
                  </p>
                </div> */}

                {resultData == null && requestData == null && (
                  <div
                    className="generate-input-row"
                    style={{ marginTop: "20px" }}
                  >

                    <div className="compliance-review-use-case-container">
                      <p className="use-case-header-text">Preset:</p>
                      <CustomDropdown
                        valueList={presets}
                        textList={presetTitles}
                        value={preset}
                        sendDataToParent={(val) => updatePreset(val)}
                        customStyle={{ width: "200px", maxHeight: "400px" }}
                      />

                      <p className="use-case-header-text">Persona:</p>
                      <CustomDropdown
                        valueList={workflowIds}
                        textList={workflowNames}
                        value={selectedWorkflow}
                        sendDataToParent={(val) => updateSelectedWorkflow(val)}
                        customStyle={{ width: "200px", maxHeight: "400px" }}
                      />
                    </div>

                    <div className="query-text-input-container">
                      <InfoIconWithText
                        text={"Prompt"}
                        helpBoxTitle={"Prompt"}
                        helpBoxText={
                          "In this field, be specific about what you want to write. Try things like, 'Write me a blog post about <topic>.' or 'Write an article about <topic>.' You can also use this field to guide how the AI writes."
                        }
                      />
                      <CustomTextArea
                        placeholderText={"Prompt"}
                        text={promptText}
                        sendDataToParent={(text) => setPromptText(text)}
                        rows={8}
                        minHeight={240}
                      />
                    </div>

                    {selectedWorkflow !== "none" && <div className="query-text-input-container" style={{ marginBottom: "30px" }}>
                      <InfoIconWithText
                        text={"Persona Data Sources Search (optional)"}
                        helpBoxTitle={"Persona Data Sources Search"}
                        helpBoxText={
                          "This lets you add a more targeted search over data sources you uploaded to your selected persona so the AI has the precise context of what keywords or specific data sources to look for."
                        }
                      />
                      <CustomTextArea
                        placeholderText={"Search over specific data sources from your selected persona."}
                        text={autoQueryGuidance}
                        sendDataToParent={(text) => setAutoQueryGuidance(text)}
                        rows={4}
                        minHeight={120}
                      />
                    </div>}

                    <CustomButton
                      buttonText="Generate"
                      disabled={
                        promptText.length === 0 ||
                        buttonDisabled
                      }
                      onClick={() =>
                        sendGeneratePrompt(
                          knowledgeBaseIds,
                          model,
                          autoQueryGuidance,
                          promptText,
                          styleSample,
                          responseLength,
                          systemMessage,
                          presetSystemMessage,
                          useWebSearch,
                          timeframe,
                        )
                      }
                    />
                  </div>
                )}
              </div>
            )}

            <div className="generate-results-container">
              {resultData !== null && (
                <div className="generate-result-container">


                  <div
                    className="generate-intro-row"
                    style={{ display: "flex", marginTop: "40px" }}
                  >
                    <div>
                      <p
                        className={`bold-font-${theme.theme}`}
                        id="generation-title-text"
                      >
                        {resultData["title"]}
                      </p>
                    </div>

                    <IconContext.Provider
                      value={{ className: `copy-text-icon-light` }}
                    >
                      <div>
                        {showCopiedMessage ? (
                          <IoCheckmarkCircleOutline />
                        ) : (
                          <Tooltip
                            arrow
                            title="Copy Text"
                            placement="top"
                          >
                            <div onClick={() => copyFullText()}>
                              <IoCopyOutline id="copy-icon" />
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    </IconContext.Provider>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <p className={`semi-bold-font-${theme.theme}`}>
                      Generated on:
                    </p>
                    <p className={`regular-font-${theme.theme}`}>
                      {resultData["createdTimestamp"]}
                    </p>
                  </div>

                  {resultData["sections"].map((section, sectionIndex) => (
                    <div className="generate-section-row" key={sectionIndex}>
                      <div className="generate-section-title-row">
                        {sectionEditing !== sectionIndex && (
                          <p
                            className={`generate-section-title-${theme.theme}`}
                          >
                            {section["title"]}
                          </p>
                        )}

                        {sectionEditing === sectionIndex && (
                          <CustomTextArea
                            text={
                              updatedSectionTitle == null
                                ? section["title"]
                                : updatedSectionTitle
                            }
                            sendDataToParent={(text) => {
                              updateSectionTitle(text, sectionIndex);
                            }}
                          />
                        )}

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                          }}
                        >
                          {" "}

                          <div
                            className="copy-wrapper"
                            onClick={() => { openHumanizeModal(); setHumanizeText(section["content"]); setHumanizeSection(sectionIndex); }}
                          >
                            {sectionEditing !== sectionIndex && (
                              <IconContext.Provider
                                value={{ className: `copy-text-icon-light` }}
                              >
                                <div>
                                  <div className="humanize-wrapper">
                                    <p className="humanize-text">Humanize</p>
                                  </div>
                                </div>
                              </IconContext.Provider>
                            )}
                          </div>

                          <div
                            className="copy-wrapper"
                            onClick={() => copyMessageText(section["content"])}
                          >
                            {sectionEditing !== sectionIndex && (
                              <IconContext.Provider
                                value={{ className: `copy-text-icon-light` }}
                              >
                                <div>
                                  {showCopiedMessage ? (
                                    <IoCheckmarkCircleOutline />
                                  ) : (
                                    <Tooltip
                                      arrow
                                      title="Copy Text"
                                      placement="top"
                                    >
                                      <div>
                                        <IoCopyOutline id="copy-icon" />
                                      </div>
                                    </Tooltip>
                                  )}
                                </div>
                              </IconContext.Provider>
                            )}
                          </div>
                          <div
                            onClick={() => sendToCompliance(section["content"])}
                          >
                            <p className={`send-to-review-text`}>
                              send to review
                            </p>
                            {sectionEditing !== sectionIndex && (
                              <IconContext.Provider
                                value={{ className: `copy-text-icon-light` }}
                              >
                                <Tooltip
                                  arrow
                                  title="Compliance Review"
                                  placement="top"
                                >
                                  <div>
                                    <IoArrowRedoSharp />
                                  </div>
                                </Tooltip>
                              </IconContext.Provider>
                            )}
                          </div>
                          {sectionEditing !== sectionIndex && (
                            <IconContext.Provider
                              value={{
                                className: "generate-edit-section-icon",
                              }}
                            >
                              <Tooltip arrow title="Edit" placement="top">
                                <div
                                  onClick={() => {
                                    setSectionEditing(sectionIndex);
                                    setUpdatedSectionTitle(section["title"]);
                                    setUpdatedSectionContent(
                                      section["content"]
                                    );
                                  }}
                                >
                                  <FaPen />
                                </div>
                              </Tooltip>
                            </IconContext.Provider>
                          )}
                        </div>
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        {sectionEditing !== sectionIndex && (
                          <p
                            className={`regular-font-${theme.theme}`}
                            style={{ whiteSpace: "pre-wrap" }}
                          >
                            {createSectionContentText(section["content"])}
                          </p>
                        )}

                        {sectionEditing === sectionIndex && (
                          <CustomTextArea
                            text={updatedSectionContent}
                            sendDataToParent={(text) => {
                              updateSectionContent(text, sectionIndex);
                            }}
                          />
                        )}

                        {sectionEditing === sectionIndex && (
                          <div className="generate-edit-section-button-row">
                            <div className="generate-edit-section-cancel-button-col">
                              <CustomCancelButton
                                buttonText={"Cancel"}
                                onClick={() => {
                                  setSectionEditing(null);
                                  setUpdatedSectionTitle(null);
                                  setUpdatedSectionContent(null);
                                }}
                              />
                            </div>
                            <div className="generate-edit-section-update-button-col">
                              <CustomButton
                                buttonText={"Update"}
                                onClick={() => {
                                  updateGeneration(
                                    updatedSectionTitle,
                                    updatedSectionContent,
                                    sectionIndex
                                  );
                                  setSectionEditing(null);
                                  setUpdatedSectionTitle(null);
                                  setUpdatedSectionContent(null);
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      {resultData["sources"] &&
                        resultData["sources"][sectionIndex] &&
                        resultData["sources"][sectionIndex].length > 0 && (
                          <div className="generate-sources-container">
                            <div className="sources-col">
                              <p
                                className={`generate-sources-text-${theme.theme}`}
                              >
                                Sources:
                              </p>
                            </div>
                            <div className="sources-col">
                              {resultData["sources"][sectionIndex].map(
                                (source, index) => (
                                  <div
                                    key={index}
                                    className="source-row"
                                    onClick={() =>
                                      showQueriesAndResults(
                                        resultData["searchQueries"][
                                        sectionIndex
                                        ],
                                        resultData["rankedResults"][
                                        sectionIndex
                                        ],
                                        resultData["sources"][sectionIndex],
                                        source
                                      )
                                    }
                                  >
                                    <p className={`source-text-${theme.theme}`}>
                                      {source["title"]}
                                    </p>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        )}

                      {resultData["webSources"] &&
                        resultData["webSources"][sectionIndex] &&
                        resultData["webSources"][sectionIndex].length > 0 && (
                          <div className="generate-sources-container">
                            <div className="sources-col">
                              <p
                                className={`generate-sources-text-${theme.theme}`}
                              >
                                Web Sources:
                              </p>
                            </div>
                            <div className="sources-col">
                              {resultData["webSources"][sectionIndex].map(
                                (source, index) => (
                                  <div
                                    key={index}
                                    className="source-row"
                                    onClick={() =>
                                      showQueriesAndResults(
                                        resultData["webSearchQueries"][
                                        sectionIndex
                                        ],
                                        resultData["webRankedResults"][
                                        sectionIndex
                                        ],
                                        resultData["webSources"][sectionIndex],
                                        source
                                      )
                                    }
                                  >
                                    <p className={`source-text-${theme.theme}`}>
                                      {source["title"]}
                                    </p>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                  ))}
                  <div ref={messagesEndRef}></div>
                </div>
              )}
            </div>
          </div>

          {showRightSidebar &&
            <div className={`generate-threads-container-${theme.theme}`}>

              <div className="sidebar-show-hide-container">
                <div>
                  <IconContext.Provider
                    value={{ className: `hide-sidebar-icon` }}
                  >
                    <div onClick={() => setShowRightSidebar(false)}>
                      <Tooltip arrow title="Hide" placement="top">
                        <div>
                          <TbLayoutSidebarLeftExpandFilled />
                        </div>
                      </Tooltip>
                    </div>

                  </IconContext.Provider>
                </div>
              </div>

              <CustomAddButton
                buttonText={"New generation"}
                onClick={() => {
                  setPromptText("");
                  createNewGeneration()
                }}
                disabled={isLoading || resultData == null}
              />

              <div id="generations-list-container">
                {generations !== undefined &&
                  generations.map((generation, index) => (
                    <div key={index}>
                      <Generation
                        title={generation["title"]}
                        onClick={() => {
                          getGeneration(generation);
                          setSectionEditing(null);
                        }}
                        onDeleteClick={() => {
                          setShowDeleteConfirmationModal(true);
                          setGenerationToDelete(generation);
                        }}
                        isCurrentGeneration={
                          currentGeneration !== null &&
                          currentGeneration["id"] == generation["job_id"]
                        }
                      />
                    </div>
                  ))}
              </div>
            </div>
          }

          {!showRightSidebar &&
            <div className={`chat-sidebar-container-light-collapsed`}>

              <div className="sidebar-show-hide-container">
                <div>
                  <IconContext.Provider
                    value={{ className: `hide-sidebar-icon` }}
                  >
                    <div onClick={() => setShowRightSidebar(true)}>
                      <Tooltip arrow title="Show" placement="top">
                        <div>
                          <TbLayoutSidebarLeftCollapseFilled />
                        </div>
                      </Tooltip>
                    </div>

                  </IconContext.Provider>
                </div>
              </div>
            </div>
          }

        </div>
      </div>
    </div>
  );
};

export const SelectedKnowledgeBaseCard = ({ title, theme }) => {
  return (
    <div className={`selected-knowledge-base-card-container-${theme.theme}`}>
      <p
        className={`bold-font-${theme.theme} selected-knowledge-base-card-title`}
      >
        {title}
      </p>
    </div>
  );
};

const RequestData = ({ requestData, knowledgeBases }) => {
  const theme = { theme: "light" };

  // Get the knowledge bases from the ids
  const kbIds =
    requestData["knowledge_base_ids"] == undefined
      ? []
      : requestData["knowledge_base_ids"];
  const selectedKnowledgeBases = knowledgeBases.filter((item) =>
    kbIds.includes(item.id)
  );

  return (
    <div style={{ marginBottom: "20px" }}>
      <p className={`semi-bold-font-${theme.theme}`}>Knowledge bases used</p>
      <div className="query-knowledge-bases-selected">
        {selectedKnowledgeBases.map((kb, index) => (
          <div key={index}>
            <SelectedKnowledgeBaseCard title={kb["title"]} theme={theme} />
          </div>
        ))}
      </div>

      <div className="request-data-row">
        <div className="request-data-col">
          <p className={`semi-bold-font-${theme.theme}`}>RSE segment length</p>
          <p className={`regular-font-${theme.theme}`}>
            {requestData["segment_length"] == ""
              ? '""'
              : requestData["segment_length"]}
          </p>
        </div>
      </div>

      <div className="request-data-row">
        <div className="request-data-col">
          <p className={`semi-bold-font-${theme.theme}`}>AutoQuery guidance</p>
          <p className={`regular-font-${theme.theme}`}>
            {requestData["auto_query_guidance"] == ""
              ? '""'
              : requestData["auto_query_guidance"]}
          </p>
        </div>
      </div>

      <div className="request-data-row">
        <div className="request-data-col">
          <p className={`semi-bold-font-${theme.theme}`}>Model</p>
          <p className={`regular-font-${theme.theme}`}>
            {requestData["model"]}
          </p>
        </div>
        <div className="request-data-col">
          <p className={`semi-bold-font-${theme.theme}`}>Temperature</p>
          <p className={`regular-font-${theme.theme}`}>
            {requestData["temperature"]}
          </p>
        </div>

        <div className="request-data-col">
          <p className={`semi-bold-font-${theme.theme}`}>Response length</p>
          <p className={`regular-font-${theme.theme}`}>
            {requestData["response_length"] == ""
              ? '""'
              : requestData["response_length"]}
          </p>
        </div>
      </div>
    </div>
  );
};

const Generation = ({
  title,
  onClick,
  onDeleteClick,
  disableClick,
  isCurrentGeneration = false,
}) => {
  const theme = { theme: "light" };

  return (
    <div
      className={`chat-thread-container-${theme.theme}${isCurrentGeneration
        ? ` chat-thread-container-selected-${theme.theme}`
        : ""
        }`}
    >
      <div
        className={
          "chat-thread-title-col" +
          (disableClick ? " chat-thread-title-col-disabled" : "")
        }
        onClick={disableClick ? null : onClick}
      >
        <p className={`semi-bold-font-${theme.theme} chat-thread-title-text`}>
          {title}
        </p>
      </div>
      <div
        className={
          "delete-chat-thread-col" +
          (disableClick ? " delete-chat-thread-col-disabled" : "")
        }
        onClick={disableClick ? null : onDeleteClick}
      >
        <IconContext.Provider
          value={{ className: `delete-chat-thread-icon-${theme.theme}` }}
        >
          <div>
            <MdDelete />
          </div>
        </IconContext.Provider>
      </div>
    </div>
  );
};


// This is temporary until the workflows are complete
export const HumanizeModal = ({ onClick, closeModal, isOpen, presetStyleSample }) => {

  const [wiritingStyle, setWritingStyle] = useState(presetStyleSample);

  return (
    <CustomModal closeModal={closeModal} isOpen={isOpen} customMainColStyle={{ paddingTop: "0px" }}>
      <div className="humanize-modal-container">
        <p className="humanize-header-text">Humanize</p>

        <InfoIconWithText
          text={"Style sample"}
          helpBoxTitle={"Style sample"}
          helpBoxText={
            "Insert 1-2 pages of sample text in the style you want the content to be converted into."
          }
        />
        <CustomTextArea
          text={wiritingStyle}
          sendDataToParent={(text) => setWritingStyle(text)}
          rows={8}
          maxHeight={400}
          placeholderText={"Insert 1-2 pages of sample text in the style you want the content to be converted into."}
        />
        <div className="humanize-button-container">
          <CustomButton buttonText={"Humanize"} onClick={() => onClick(wiritingStyle)} />
        </div>
      </div>
    </CustomModal>
  )

}


export default LongForm;
