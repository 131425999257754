import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CustomButton } from '../components/CustomButton';
import CustomTextInput from '../components/CustomTextInput';


import BouncingDots from '../components/Loaders/BouncingDots/BouncingDots';
import { sendLoginInfo } from '../services/auth.js';
import './Login.css';

// server_c60e76737e967bd4a5996a23b47a72fa
// 4YrpwTeX2YFFMuK1WTNJxbRd3aL92eSCKPXxunm-LuQ

// client_1a3f2a252e4564610e6ecbc8cf7caea6
// szd9EOJ0fwVW-tIn3dkCZZ-yf4QrkVz2_PvA02Q3RgE

const Login = ({ }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    async function submitLoginInfo() {
        setButtonDisabled(true);
        setIsLoading(true);
        const [resData, status] = await sendLoginInfo(username, password);
        // Check if there is a message in the response data
        if (resData !== null && resData["message"] !== undefined) {
            // See if 'Invalid credentials' is in the message
            if (resData["message"].includes("Invalid credentials")) {
                alert('Invalid username or password.');
                setIsLoading(false);
                setButtonDisabled(false);
                return;
            }
        }
        console.log("status", status)
        if (status === 500) {
            alert('Something went wrong. Please try again later or contact us for assistance.');
            setIsLoading(false);
            return;
        } else if (status == 401) {
            alert('Invalid username or password.');
            setIsLoading(false);
            return;
        }

        const expiryTime = new Date() + 1000 * 60 * 60 * 16; // 16 hours
        console.log("expiryTime", expiryTime)

        // dispatch(authActions.setAutoLogoutTimer(1000 * 60 * 60 * 16, history));
        sessionStorage.setItem('data', JSON.stringify(resData));
        sessionStorage.setItem('username', username);
        setIsLoading(false);
        history.push('/login-redirect')

    }

    useEffect(() => {
        if (username.length > 0 && password.length > 0) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [username, password])

    // client_f8d79d1a23c7955d1760bc2d011c21d1
    // HdcQFSYWWN5NUSmUSqyzLWuBw4GhO-dDblNpqZdPVmc

    return (
        <div className="login-screen-container">

            <div className={`header-container-dark`}>
                <img className={`advisor-x-logo-dark`} loading="lazy" src="https://assets-global.website-files.com/629e3357919ae1682f65b07c/6532d8b6ece624b92226971a_main-logo-big-advisorx.svg" />
            </div>

            {isLoading && <BouncingDots />}
            <div className="login-credentials-container">

                <div className="custom-solutions-contact-us-form-container" id="custom-solutions-contact-us-section">

                    <div className="contact-us-form-row">
                        <div className="contact-us-form-col">
                            <p className="custom-solutions-contact-header">Username</p>
                            <CustomTextInput
                                placeholderText={'johndoe123'}
                                text={username}
                                sendDataToParent={(data) => setUsername(data)}
                                rows={1}
                                theme={"dark"}
                                customTextAreaStyle={{ backgroundColor: "transparent" }}
                            />
                        </div>
                    </div>
                    <div className="contact-us-form-row">
                        <div className="contact-us-form-col">
                            <p className="custom-solutions-contact-header" style={{ marginTop: "20px" }}>Password</p>
                            <CustomTextInput
                                placeholderText={'password'}
                                text={password}
                                sendDataToParent={(data) => setPassword(data)}
                                rows={1}
                                theme={"dark"}
                                type={"password"}
                                customTextAreaStyle={{ backgroundColor: "transparent" }}
                            />
                        </div>
                    </div>
                    <div className="contact-us-form-button-row" style={{ paddingTop: "20px" }}>
                        <CustomButton
                            onClick={() => submitLoginInfo()}
                            buttonText="Login"
                            theme={"dark"}
                            disabled={buttonDisabled} />
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Login