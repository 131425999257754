import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../hooks.ts";


import * as knowledgeBaseServices from '../services/knowledge';
import {
    setKnowledgeBaseFiles as setKnowledgeBaseFilesDispatch
    } from '../features/knowledge/knowledgeSlice.ts';
import { delay, removeNonAsciiCharacters } from '../utils/generalFunctions';


export function checkForDuplicateFiles(newFilesChosen, fileData) {
    // A duplicate file is one with the same filename and content
    let uniqueFiles = {};
    // Loop through the file data keys
    const keys = Object.keys(newFilesChosen);
    for (let i = 0; i < keys.length; i++) {
        const fileName = keys[i];
        const fileContent = newFilesChosen[fileName];
        if (newFilesChosen[fileName] === fileData[fileName]) {
            continue;
        } else {
            uniqueFiles[fileName] = fileContent;
        }
    }
    // Get the unique filenames
    const uniqueFileNames = Object.keys(uniqueFiles);
    return [uniqueFiles, uniqueFileNames];

}

export function getSelectedAvailableKnowledgeBases(knowledgeBases, model_knowledge_bases) {
    // model_knowledge_bases is the list of knowledge base ids that are selected for the model, in the model spec
    let modelKnowledgeBases = [];
    model_knowledge_bases.forEach((knowledgeBaseID) => {
        const knowledgeBase = knowledgeBases.find((item) => item.id === knowledgeBaseID);
        if (knowledgeBase) {
            modelKnowledgeBases.push(knowledgeBase);
        }
    });
    let userSelectedKnowledgeBases = [];
    modelKnowledgeBases.forEach((knowledgeBaseID) => {
        const knowledgeBase = modelKnowledgeBases.find((item) => item.id === knowledgeBaseID);
        if (knowledgeBase) {
            userSelectedKnowledgeBases.push(knowledgeBase);
        }
    });
    return [userSelectedKnowledgeBases, modelKnowledgeBases];
}


export function sortKnowledgeBaseByTimestamp(knowledgeBases) {
    // knowledgeBases is a list of knowledge bases
    // Sort the knowledge bases by created_on date, which is an epoch timestamp
    knowledgeBases.sort((a, b) => {
        return b.created_on - a.created_on;
    });
    return knowledgeBases;
}


export function formatSummary(summaryText) {
    // We want to make the numbers in square bracket superscript and clickable
    // Split the text on numbers in square brackets
    let regex = /\[(\d+)\]/g;
    let splitText = summaryText.split(regex);

    let summaryTextData = []
    for (let i = 0; i < splitText.length; i++) {
        if (`[${splitText[i]}]`.match(regex)) {
            summaryTextData.push({
                text: `[${splitText[i]}]`,
                isNumber: true
            })
        } else {
            summaryTextData.push({
                text: removeNonAsciiCharacters(splitText[i]),
                isNumber: false
            })
        }
    }
    return summaryTextData
}


export function formatKBsForSearching(knowledgeBases) {
    // Create a string of all the knowledge base titles, ids, and descriptions
    let knowledgeBasesStrings = {};
    knowledgeBases.forEach((knowledgeBase) => {
        const title = (knowledgeBase.title === undefined || knowledgeBase.title === null) ? "" : knowledgeBase.title;
        const description = (knowledgeBase.description === undefined || knowledgeBase.description === null) ? "" : knowledgeBase.description;
        const knowledgeBaseString = `${title.toLowerCase()} ${knowledgeBase.id} ${description.toLowerCase()}`;
        knowledgeBasesStrings[knowledgeBase.id] = knowledgeBaseString;
    });
    return knowledgeBasesStrings;
}

export function formatDocumentsForSearching(documents) {
    let documentsStrings = {};
    documents.forEach((document) => {
        const title = (document.title === undefined || document.title === null) ? "" : document.title;
        const documentString = `${title.toLowerCase()} ${document.id}`;
        documentsStrings[document.id] = documentString;
    });
    return documentsStrings;
}


export function useKnowledgeBaseHandler(knowledgeBaseId) {

    const dispatch = useAppDispatch();
    // Get the knowledge base files from the redux store
    const savedKnowledgeBaseFiles = useAppSelector(state => state.knowledge.knowledgeBaseFiles);

    // Get the api key and secret from session storage
    let data = sessionStorage.getItem('data');
    let chatConfig = {};
    if (data === undefined || data === null) {
        data = null;
    } else {
        data = JSON.parse(data);
        chatConfig = JSON.parse(data["chat_config"])
    }
    const idToken = useAppSelector(state => state.auth.idToken);


    // Get the saved knowledge bases from the redux store
    const [isLoading, setIsLoading] = useState(false);
    const [knowledgeBaseFiles, setKnowledgeBaseFiles] = useState([]);
    const [numDocuments, setNumDocuments] = useState(0);
    const [deletedFileIDs, setDeletedFileIDs] = useState([]);


    async function uploadDocument(fileName, fileContent, fileHash) {
        const [response, urlStatus] = await knowledgeBaseServices.requestSignedURL(
            idToken, fileName, knowledgeBaseId, fileHash
        );
        const signedURL = response.temporary_url;
        console.log("signed url response", response)
        const documentData = response.document
        if (urlStatus === 400) {
            if (response.error) {
                alert(response.error)
            } else {
                alert(`We couldn't upload ${fileName} at this time. Please contact support or try again later`)
            }
            return [{}, 400];
        }
        const status = await knowledgeBaseServices.uploadDocument(signedURL, fileHash, fileContent);
        return [documentData, status];
    }


    async function updateKnowledgeBaseFiles(updatedKnowledgeBaseFilesList) {
        dispatch(setKnowledgeBaseFilesDispatch(updatedKnowledgeBaseFilesList));
        await delay(2000);
        setIsLoading(false)
    }


    async function deleteDocuments(filesToDelete) {
        setIsLoading(true);
        let updatedKnowledgeBaseFilesList =
        {
            ...savedKnowledgeBaseFiles
        }
        for (let i = 0; i < filesToDelete.length; i++) {
            const fileID = filesToDelete[i];
            const status = await knowledgeBaseServices.deleteDocument(idToken, knowledgeBaseId, fileID);
            console.log("status", status)
            if (status === 200 || status === 204) {
                // let fileList = savedKnowledgeBaseFiles[knowledgeBaseId];
                // // Remove the deleted file from the list
                // fileList = fileList.filter(file => file.id !== fileID);
                // console.log("fileList", fileList);
                // updatedKnowledgeBaseFilesList[knowledgeBaseId] = fileList;
                // setDeletedFileIDs(prevState => [...prevState, fileID]);
                await delay(2000);
                getKnowledgeBaseFiles();
            }
        }
        setIsLoading(false);
        // setKnowledgeBaseFiles(updatedKnowledgeBaseFilesList[knowledgeBaseId])
        // updateKnowledgeBaseFiles(updatedKnowledgeBaseFilesList)
    }
    

    async function requestPendingFilesStatus(pendingFiles) {
       
        let areAllFilesComplete = false;
        let updatedPendingFiles = pendingFiles;
        let updatedKnowledgeBaseFiles = knowledgeBaseFiles;
        let num_tries = 0;
        while (!areAllFilesComplete && num_tries < 10) {
            await delay(5000);
            for (let i = 0; i < updatedPendingFiles.length; i++) {
                const knowledgeBaseId = updatedPendingFiles[i].knowledge_base_id;
                const documentId = updatedPendingFiles[i].id;
                const [response, status] = await knowledgeBaseServices.getDocumentById(idToken, knowledgeBaseId, documentId, false);
                if (status !== 200) {
                    continue
                }
                const vectorizationStatus = response.vectorization_status;
                // Update the vectorization status of this file in the knowledge base files list
                updatedKnowledgeBaseFiles = updatedKnowledgeBaseFiles.map((item) => {
                    if (item.id === response.id) {
                        return response;
                    } else {
                        return item;
                    }
                });
                setKnowledgeBaseFiles(updatedKnowledgeBaseFiles)
                if (vectorizationStatus === "COMPLETE" || vectorizationStatus === "FAILED") {
                    // Remove this file from the list of pending files so we don't request it again
                    updatedPendingFiles = updatedPendingFiles.filter((item) => item.id !== response.id);
                }
            }
            num_tries += 1;
            if (updatedPendingFiles.length === 0) {
                // If there are no more pending files, then we can stop the loop
                areAllFilesComplete = true;
                return updatedKnowledgeBaseFiles;
            }
        }
        return updatedKnowledgeBaseFiles;
    }

    async function getKnowledgeBaseFiles() {
        setIsLoading(true)
        const [response, status] = await knowledgeBaseServices.getAllDocumentsInKnowledgeBase(idToken, knowledgeBaseId);
        let files = [];
        if (status === 200) {
            files = response.documents;
            let nextPageToken = response['next_page_token'];
        }

       
        setKnowledgeBaseFiles(files)
        setIsLoading(false)
        setNumDocuments(files.length)

        let updatedKnowledgeBaseFiles = (savedKnowledgeBaseFiles == null) ? {} : { ...savedKnowledgeBaseFiles}
        updatedKnowledgeBaseFiles[knowledgeBaseId] = files;


            dispatch(setKnowledgeBaseFilesDispatch(updatedKnowledgeBaseFiles))

    }

    async function checkForPendingFiles() {
        let allPendingFiles = [];
        if (knowledgeBaseFiles.length > 0) {
            knowledgeBaseFiles.forEach(file => {
                const createdDate = file.created_on; // This is an epoch timestamp in UTC time
                // Check the time difference between now and when the file was created
                const fileID = file.id;
                // If this file is in the deleted files list, don't add it to the pending files list
                const timeDifference = (Date.now() / 1000 - createdDate);
                if (!deletedFileIDs.includes(fileID) && timeDifference < 3600 && (file.vectorization_status !== "COMPLETE" && file.vectorization_status !== "FAILED")) {
                    allPendingFiles.push(file);
                }
            });
        }

        if (allPendingFiles.length > 0) {
            let updatedKnowledgeBaseFiles = await requestPendingFilesStatus(allPendingFiles)
            // Check again for any deleted files
            updatedKnowledgeBaseFiles = updatedKnowledgeBaseFiles.filter(file => !deletedFileIDs.includes(file.id))
            let updatedKnowledgeBaseFilesList = (savedKnowledgeBaseFiles === null ? {} : savedKnowledgeBaseFiles);
            updatedKnowledgeBaseFilesList[knowledgeBaseId] = updatedKnowledgeBaseFiles;
            dispatch(setKnowledgeBaseFilesDispatch(updatedKnowledgeBaseFilesList));
            // Set the state variable
            setKnowledgeBaseFiles(updatedKnowledgeBaseFiles);
        }
    }

    // useEffect(() => {
    
    //     checkForPendingFiles(knowledgeBaseFiles)
    // }, [knowledgeBaseFiles])

    // useEffect(() => {
    //     // If the saved knowledge base files for this knowledge base are not in the redux store, request them
    //     if (savedKnowledgeBaseFiles !== null && savedKnowledgeBaseFiles[knowledgeBaseId] !== undefined) {
    //         const sortedFiles = sortFilesByTimestamp(savedKnowledgeBaseFiles[knowledgeBaseId], "descending")
    //         setKnowledgeBaseFiles(sortedFiles)
    //         setNumDocuments(sortedFiles.length)
    //     } else {
    //         getKnowledgeBaseFiles()
    //     }
    // }, [savedKnowledgeBaseFiles])

    return {
        uploadDocument, getKnowledgeBaseFiles, deleteDocuments, knowledgeBaseFiles, isLoading, numDocuments
    }

}

export function sortFilesByTitle(allFiles, ascendingOrDescending) {
    // The title is accessed at allFiles[i].title (This also works for knowledge bases)
    // Convert the title to lowercase so that the sort is case insensitive
    //let sortedFiles = allFiles.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))

    let sortedFiles = allFiles.sort((a, b) => {
        // If both titles are null, they are equal in terms of sorting
        if (!a.title && !b.title) return 0;

        // If a's title is null, b comes first
        if (!a.title) return 1;

        // If b's title is null, a comes first
        if (!b.title) return -1;

        // If both titles are not null, compare them
        return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
    });

    // If descending, reverse the array
    if (ascendingOrDescending === "descending") {
        sortedFiles = sortedFiles.reverse()
    }
    return sortedFiles;
}


export function sortFilesByTimestamp(allFiles, ascendingOrDescending) {
    // The created_on is accessed at allFiles[i].created_on
    let sortedFiles = allFiles.sort((a, b) => a.created_on - b.created_on)
    // If descending, reverse the array
    if (ascendingOrDescending === "descending") {
        sortedFiles = sortedFiles.reverse()
    }
    return sortedFiles;
}


export async function requestKnowledgeBaseFiles(idToken, knowledgeBaseId, nextPageToken = null) {

    const [response, status] = await knowledgeBaseServices.getAllDocumentsInKnowledgeBase(idToken, knowledgeBaseId, nextPageToken);
    const files = response.documents;
    const newNextPageToken = response['next_page_token'];
    return [files, status, newNextPageToken];

}


