import axios from "axios";
const API_URL = process.env.REACT_APP_BACKEND_MAIN_BASE_URL;

export const getCurrentUserAPI = async () => {
  const token = sessionStorage.getItem("token");
  const response = await axios.get(API_URL + "users/me", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// upload image
export const getPresignedUrl = async (fileName, fileType) => {
  try {
    const token = sessionStorage.getItem("token");
    const response = await axios.post(API_URL + "file", {
      filename: fileName,
      contentType: fileType,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err) {
    console.error("Error fetching pre-signed URL:", err);
    throw err;
  }
};


// upload image to s3
export const uploadImageAPI = async (url, file) => {
  const response = await axios.put(url, file, {
    headers: {
      'Content-Type': file.type,
    },
  });
  return response;
};


export const updateUserAPI = async (data) => {
  const token = sessionStorage.getItem("token");
  const response = await axios.patch(API_URL + "users/me", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const updateSystemMessageAPI = async (data) => {
  const token = sessionStorage.getItem("token");
  const response = await axios.patch(API_URL + "users/me", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
