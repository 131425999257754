import React from 'react';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';


interface IosSwitchProps extends SwitchProps {
  darkMode?: boolean;
}

const IosSwitch = styled((props: IosSwitchProps) => {
  // Destructure the darkMode prop and pass the rest to the Switch component
  const { ...switchProps } = props;
  
  return <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...switchProps} />;
})(({ theme, darkMode }) => ({
  width: 44,
  height: 25,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 1,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(19px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#5D24D8',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 23,
    height: 23,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: darkMode ? '#242329' : '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 200,
    }),
  },
}));

export default IosSwitch;
