import React, { useState, useEffect, useRef } from "react";

import LoadingSpinner from "../components/LoadingSpinner.tsx";
import { CustomButton, CustomAddButton } from "../components/CustomButton";
import CustomModal from "../components/CustomModal";
import CustomTextInput from "../components/CustomTextInput";
import CustomTextArea from "../components/CustomTextArea";
import CustomDropdown from "../components/CustomDropdown";
import { ClickableRow } from "../components/ClickableRow";
import { InfoIconWithText } from "../components/InfoIcon.js";
import IosSwitch from "../components/IosSwitch.tsx";

import WorkflowConfigModal from "../components/WorkflowConfigModal";

import { useWorkflowHandler } from "../logic/workflow";
import { formatTimestampForDisplay } from "../utils/dateFormatting";

import "./Knowledge.css";
import Sidebar from "../components/Sidebar/Sidebar.js";
import { useHistory } from "react-router-dom";
import { useAuthExpiry } from "../logic/auth";

const Knowledge = () => {
  const history = useHistory();

  useAuthExpiry();

  const token = sessionStorage.getItem('token');

  const { createNewWorkflow, listWorkflows, workflows } = useWorkflowHandler(token);
  console.log("workflows", workflows)

  const [showCreateWorkflowModal, setShowCreateWorkflowModal] = useState(false);

  // Get the api key and secret from session storage
  let data = sessionStorage.getItem("data");
  if (data === undefined || data === null) {
    data = {};
  } else {
    data = JSON.parse(data);
  }

  useEffect(() => {
    if (workflows.length === 0) {
      listWorkflows();
    }
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Sidebar currentPage={"personas"} />

      {showCreateWorkflowModal &&
        <WorkflowConfigModal
          isOpen={showCreateWorkflowModal}
          closeModal={() => setShowCreateWorkflowModal(false)}
          onClick={(name, description, updatedResponseConfig, updatedWebSearchConfig) => {
            createNewWorkflow(name, description, updatedResponseConfig, updatedWebSearchConfig);
            setShowCreateWorkflowModal(false)
          }}
        />
      }

      <div className="knowledge-base-section">
        <div className="knowledge-bases-container">
          <div>
            <div className="workflow-management-header-row">
              <p className="regular-font-light">Persona Management</p>

              <div className="edit-knowledge-base-add-files-container">
                <CustomAddButton
                  buttonText={"Create new persona"}
                  onClick={() => setShowCreateWorkflowModal(true)}
                />
              </div>
            </div>

            <div className="workflows-header-row">
              <div className="workflows-title-col">
                <p className="workflows-header-text">Title</p>
              </div>
              <div className="workflows-date-col">
                <p className="workflows-header-text">Date created</p>
              </div>
              <div className="workflows-arrow-col">
                
              </div>
            </div>

            {workflows.map((workflow, index) =>
              <div className="knowledge-base-list-container" key={index}>
                <ClickableRow
                  numCols={3}
                  allText={[
                    workflow.name,
                    formatTimestampForDisplay(workflow.createdAt),
                  ]}
                  flexSizing={[1, 1]}
                  onClick={() => history.push(`/personas/${workflow._id}`)}
                />
              </div>
            )}

          </div>
        </div>
      </div>
    </div>
  );
};


export const WorkflowModal = ({
  isOpen, closeModal, onClick, presetName = null, presetUseWebSearch = true, presetWebSearchConfig = {},
  presetSystemMessage = "", presetWritingStyle = "", createOrEdit = "create"
}) => {

  // This modal will be used for both creating and editing a workflow

  const [name, setName] = useState(presetName);
  const [systemMessage, setSystemMessage] = useState(presetSystemMessage);
  const [writingStyle, setWritingStyle] = useState(presetWritingStyle);

  const [useWebSearch, setUseWebSearch] = useState(presetUseWebSearch);
  const [timeframe, setTimeframe] = useState("any");

  let presetIncludeDomains = presetWebSearchConfig.web_search_preset === undefined ? "" : presetWebSearchConfig.web_search_preset.include_domains;
  let presetExcludeDomains = presetWebSearchConfig.web_search_preset === undefined ? "" : presetWebSearchConfig.web_search_preset.exclude_domains;

  if (presetIncludeDomains !== null && presetIncludeDomains !== undefined && presetIncludeDomains !== "") {
    presetIncludeDomains = presetIncludeDomains.join(", ");
  } else if (presetExcludeDomains !== null && presetExcludeDomains !== undefined && presetExcludeDomains !== "") {
    presetExcludeDomains = presetExcludeDomains.join(", ");
  }
  const [includeDomains, setIncludeDomains] = useState(presetIncludeDomains);
  const [excludeDomains, setExcludeDomains] = useState(presetExcludeDomains);

  const [showIncludeOrExclude, setShowIncludeOrExclude] = useState("include");


  function handleOnClick() {

    console.log("includeDomains", includeDomains);
    console.log("excludeDomains", excludeDomains);

    // Convert the timeframe to days
    let timeframeDays = 10000;
    if (timeframe === "past_day") {
      timeframeDays = 1;
    } else if (timeframe === "past_week") {
      timeframeDays = 7;
    } else if (timeframe === "past_month") {
      timeframeDays = 30;
    } else if (timeframe === "past_quarter") {
      timeframeDays = 90;
    } else if (timeframe === "past_year") {
      timeframeDays = 365;
    }

    let webSearchConfig = {
      timeframe_days: timeframeDays,
    }

    if (showIncludeOrExclude === "include" && includeDomains.length > 0) {
      let includeDomainsList = includeDomains.split(",").map((domain) => domain.trim());
      webSearchConfig.web_search_preset.include_domains = includeDomainsList;
    } else if (showIncludeOrExclude === "exclude" && excludeDomains.length > 0) {
      let excludeDomainsList = excludeDomains.split(",").map((domain) => domain.trim());
      webSearchConfig.web_search_preset.exclude_domains = excludeDomainsList;
    }

    console.log("webSearchConfig", webSearchConfig)

    onClick(name, useWebSearch, webSearchConfig, systemMessage, writingStyle)

  }


  return (
    <CustomModal isOpen={isOpen} closeModal={closeModal}
      customModalContainerStyle={{ maxWidth: "1000px", width: "100%", height: "92vh", maxHeight: "800px", overflow: "hidden", paddingTop: "15px", paddingBottom: "10px" }}
      customMainColStyle={{ paddingTop: "0px", marginTop: "0px", paddingLeft: "0px", paddingRight: "0px", paddingBottom: "20px", height: "100%", overflow: "hidden" }}>

      <div className="workflow-modal-container">

        <div className="workflow-config-container">
          <div className="workflow-name-row">
            <p className="workflow-modal-field-header-text">Title</p>
            <CustomTextInput
              placeholderText={"Workflow title"}
              text={name}
              sendDataToParent={(val) => setName(val)}
            />
          </div>
          <div className="workflow-system-message-row">
            <InfoIconWithText
              text={"System Message"}
              helpBoxTitle={"System Message"}
              helpBoxText={
                "Write your firm's investment thesis or investment philosophy, or any other message you want the AI to have access to when generating content."
              }
              customInfoBoxStyle={{ top: "30px", left: "-20px" }}
            />
            <CustomTextArea
              placeholderText="System message"
              text={systemMessage}
              maxHeight={250}
              sendDataToParent={(val) => setSystemMessage(val)}
            />
          </div>
          <div className="workflow-writing-style-row">
            <InfoIconWithText
              text={"Writing Style"}
              helpBoxTitle={"Writing Style"}
              helpBoxText={
                "Insert 2-4 paragraphs of sample text in the style you want the content to be converted into."
              }
              customInfoBoxStyle={{ top: "30px", left: "-20px" }}
            />
            <CustomTextArea
              placeholderText="Insert 2-4 paragraphs of sample text in the style you want the content to be converted into."
              text={writingStyle}
              maxHeight={250}
              sendDataToParent={(val) => setWritingStyle(val)}
            />
          </div>
          <div className="workflow-web-search-config-container">

            <div >
              <InfoIconWithText
                text={"Web Enrichment"}
                helpBoxTitle={"Web Enrichment"}
                helpBoxText={"Whether to use web enrichment to retrieve information"}
                customInfoBoxStyle={{ left: "20px" }}
              />
              <div style={{ marginTop: "8px" }}>
                <IosSwitch
                  checked={useWebSearch}
                  onChange={() => setUseWebSearch(!useWebSearch)}
                />
              </div>

              {useWebSearch && (

                <div>
                  <div className="web-search-timeframe-row">
                    <InfoIconWithText
                      text={"Web search timeframe"}
                      helpBoxTitle={"Web search timeframe"}
                      helpBoxText={
                        "This is the timeframe before today that the web search will return results from."
                      }
                      customInfoBoxStyle={{ left: "20px" }}
                    />
                    <CustomDropdown
                      valueList={[
                        "past_day",
                        "past_week",
                        "past_month",
                        "past_quarter",
                        "past_year",
                        "any",
                      ]}
                      textList={[
                        "Past day",
                        "Past week",
                        "Past month",
                        "Past quarter",
                        "Past year",
                        "Any",
                      ]}
                      value={timeframe}
                      sendDataToParent={(val) => setTimeframe(val)}
                      customStyle={{ width: 160 }}
                      isDisabled={!useWebSearch}
                    />
                  </div>


                  <div className="workflow-modal-input-container" style={{ marginTop: "25px" }}>
                    <p className={`regular-font-dark`}>Site filter</p>
                    <div className="include-exclude-toggle-container">
                      <div className={`include-exclude-toggle-dark${showIncludeOrExclude === "include" ? "-selected" : ""}`}
                        onClick={() => setShowIncludeOrExclude("include")}>
                        <p className={`include-exclude-font-dark`}>Include</p>
                      </div>
                      <p className={`web-search-or-font-dark`}>OR</p>
                      <div className={`include-exclude-toggle-dark${showIncludeOrExclude === "exclude" ? "-selected" : ""}`}
                        onClick={() => setShowIncludeOrExclude("exclude")}>
                        <p className={`include-exclude-font-dark`}>Exclude</p>
                      </div>
                    </div>
                  </div>

                  <div className="workflow-modal-input-container">

                    {showIncludeOrExclude === "include" && <div className="workflow-web-search-modal-col">
                      <InfoIconWithText
                        text={"Include domains (optional)"}
                        helpBoxTitle={"Include domains"}
                        helpBoxText={"A list of domains to constrain the search to, in a comma separated list. If left blank, the search will include all domains."}
                        customInfoBoxStyle={{ width: "350px" }}
                        customTextStyle={{ fontSize: "12px", color: "var(--medium-gray)", marginTop: "5px", marginBottom: "2px" }}
                      />
                      <CustomTextInput
                        placeholderText={"Acme.inc, example.com"}
                        text={includeDomains}
                        sendDataToParent={(text) => setIncludeDomains(text)}
                        rows={1} />
                    </div>}

                    {showIncludeOrExclude === "exclude" && <div className="workflow-web-search-modal-col">
                      <InfoIconWithText
                        text={"Exclude domains (optional)"}
                        helpBoxTitle={"Exclude domains"}
                        helpBoxText={"Domains to exclude from the web search, in a comma separated list. If left blank, the search will include all domains."}
                        customInfoBoxStyle={{ width: "350px" }}
                        customTextStyle={{ fontSize: "12px", color: "var(--medium-gray)", marginTop: "5px", marginBottom: "2px" }}
                      />
                      <CustomTextInput
                        placeholderText={"blacklist.com, blog.blacklist.com"}
                        text={excludeDomains}
                        sendDataToParent={(text) => setExcludeDomains(text)}
                        rows={1} />
                    </div>}
                  </div>

                </div>

              )}

            </div>

          </div>
        </div>


        <div className="workflow-button-container">
          <CustomButton
            onClick={() => handleOnClick()}
            buttonText={createOrEdit === "create" ? "Create persona" : "Update persona"} />
        </div>
      </div>

    </CustomModal>
  )

}

export default Knowledge;
