import React, { useEffect, useState } from 'react';

import './CustomTextInput.css';

const CustomTextInput = ({
    text, rows, placeholderText, customTextAreaStyle, sendDataToParent, disabled=false, theme="light", type="text"
}) => {

    const [message, setMessage] = useState(text === undefined ? "" : text);

    const handleChange = (evt) => {
        const val = evt.target?.value;
        setMessage(val);
        sendDataToParent(val);
    };

    useEffect(() => {
        setMessage(text === undefined ? "" : text);
    }, [text])

    return (
        <div className={"custom-text-input-section"}>
            <div className="custom-text-input-container">
                <input
                    className={`custom-text-input-${theme}` + (disabled ? ` custom-text-input-${theme}-disabled` : "")}
                    style={customTextAreaStyle}
                    onChange={handleChange}
                    placeholder={placeholderText === undefined ? "Enter text" : placeholderText}
                    rows={rows === undefined ? 1 : rows}
                    value={message}
                    disabled={disabled}
                    type={type}
                />
            </div>
        </div>
    )

}

export default CustomTextInput;