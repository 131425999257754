import React, { useEffect, useState } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";

import Login from "../screens/Login";
import Chat from "../screens/Chat";
import Compliance from "../screens/Compliance";
import Archive from "../screens/Archive";
import LongForm from "../screens/LongForm";
import LoginRedirect from "../screens/LoginRedirect";
import DocumentAnalysis from "../screens/DocumentAnalysis.js";
import AdvisorAssist from "../screens/AdvisorAssist.js";
import Knowledge from "../screens/Knowledge.js";
import EditKnowledgeBase from "../screens/EditKnowledgeBase.js";
import DocumentUpload from "../screens/DocumentUpload.js";
import Onboarding from "../screens/Onboarding.js";
import { useAppDispatch, useAppSelector } from "../hooks.ts";
import { useAuth0 } from "@auth0/auth0-react";
import { getCurrentUserAPI, updateUserAPI } from "../logic/user.js";
import { setKnowledgeBases, setUser } from "../features/auth/authSlice.ts";
import ProtectedOnboardRoute from "../guard.tsx";
import ProtectedAuthRoute from "../guard_auth.tsx";
import { createKnowledgeBases } from "../services/knowledgebases.tsx";
import LoadingScreen from "../components/Loaders/LoadingScreen/LoadingScreen.js";
import Chart from "../screens/Chart.js";

const MainNavigator = () => {
  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout
  } = useAuth0();


  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);

  useEffect(() => {
    console.log(isAuthenticated, user, isLoading, "useEffect");
    if (isAuthenticated && (!user || !user._id)) {
      setUserLogic();
    }
  }, [isAuthenticated, isLoading, user]);

  const setUserLogic = async () => {
    console.log(isAuthenticated);
    if (isAuthenticated) {
      setLoading(true);
      const token = await getAccessTokenSilently();
      sessionStorage.setItem("token", token);

      const expiryTime = localStorage.getItem("expiry");
      console.log("expiryTime", expiryTime)
      if (expiryTime == null) {
        // Set a 20 hour expiry for the token
        const expiry = new Date();
        expiry.setHours(expiry.getHours() + 20);
        console.log("expiry", expiry);
        localStorage.setItem("expiry", expiry);
      }

      const user = await getCurrentUserAPI();
    
      dispatch(setUser(user));
      setLoading(false);
    } else {
      console.log("removing token")
      sessionStorage.removeItem("token");
      loginWithRedirect();
      setLoading(false);
    }
  };

  if (loading || isLoading) {
    // Todo: Jay add a loading spinner here
    return <LoadingScreen />
  }
  if (!isAuthenticated) {
    loginWithRedirect();
    return <LoadingScreen />  
  }


  // if(user && user._id && user.knowledge_bases && user.knowledge_bases.length > 0) {    
  if (user && user._id) {

    return (
      <BrowserRouter>
        <Switch>
          <Route path="/login" exact>
            <Login />
          </Route>
          <ProtectedOnboardRoute path="/personas" exact component={Knowledge} />
          <ProtectedOnboardRoute
            path="/personas/:workflowId"
            exact
            component={EditKnowledgeBase}
          />
          <ProtectedOnboardRoute
            path="/document-upload/:knowledgeBaseId"
            exact
            component={DocumentUpload}
          />
          {/*<Route path="/login-redirect" exact>
          <LoginRedirect />
  </Route>*/}
          <ProtectedOnboardRoute
            path="/advisor-assist"
            exact
            component={AdvisorAssist}
          />
          <ProtectedOnboardRoute path="/" exact component={Chat} />
          <ProtectedOnboardRoute
            path="/compliance"
            exact
            component={Compliance}
          />
          <ProtectedOnboardRoute
            path="/charts"
            exact
            component={Chart}
          />
          <ProtectedOnboardRoute path="/archive" exact component={Archive} />
          <ProtectedOnboardRoute path="/long-form" exact component={LongForm} />
          <ProtectedOnboardRoute
            path="/document-analysis"
            exact
            component={DocumentAnalysis}
          />
          <ProtectedAuthRoute path="/onboarding" exact component={Onboarding} />
        </Switch>
      </BrowserRouter>
    );
  }
  return <LoadingScreen />;
};

export default MainNavigator;
