import axios from 'axios';
const baseURL = process.env.REACT_APP_BACKEND_MAIN_BASE_URL;


//const token = localStorage.getItem('token');
export const getWorkflow = async (workflowId, token) => {
  const response = await axios.get(`${baseURL}workflows/${workflowId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export const getWorkflows = async (token) => {
  const response = await axios.get(`${baseURL}workflows`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}
// {
//   "name": "string",
//   "description": "string",
//   "knowledge_base_id": "string",
//   "system_message": "string",
//   "web_search_preset": {
//     "end_date": "2024-04-08T18:54:36.964Z",
//     "exclude_domains": [
//       "string"
//     ],
//     "include_domains": [
//       "string"
//     ],
//     "timeframe_days": 0,
//     "start_date": "2024-04-08T18:54:36.964Z"
//   },
//   "use_web_search": true,
//   "model": "string",
//   "temperature": 0,
//   "responseLength": "string",
//   "writing_style": "string"
// }

export const createWorkflow = async (workflow, token) => {
  const response = await axios.post(`${baseURL}workflows`, workflow, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return [response.data, response.status];
}

export const updateWorkflow = async (id, workflow, token) => {
  const response = await axios.patch(`${baseURL}workflows/${id}`, workflow, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

export const deleteWorkflow = async (workflowId, token) => {
  const response = await axios.delete(`${baseURL}workflows/${workflowId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  
  return response.data;
}


