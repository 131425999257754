
export const secComplianceWords = [
    "Guaranteed returns",
    "No loss",
    "Certain profit",
    "Fixed return",
    "Guaranteed income",
    "Assured yield",
    "Guaranteed profit",
    "Secure investment",
    "Definite gain",

    "Sure profit",
    "Risk-free investment",
    "Safe bet",
    "Can't lose",
    "Guaranteed success",
    "Surefire investment",
    "No-risk opportunity",
    "100% safe",
    "Fail-proof",

    "Always profitable",
    "Never fails",
    "Permanent gains",
    "Forever successful",
    "Eternally beneficial",
    "Consistently yielding",
    "Unfailingly profitable",
    "Infallible investment",
    "Uninterrupted success",

    "Ironclad guarantee",
    "Absolute certainty",
    "Sure bet",
    "Money-back guarantee",
    "Profit guaranteed",
    "Guaranteed growth",
    "Locked-in returns",
    "Secured gains",
    "Guaranteed to outperform",

    "Sure thing",
    "Riskless",
    "Certain success",
    "Guaranteed wealth",
    "Profit assured",
    "Zero risk",
    "Surefire returns",
    "No way to lose",
    "Guaranteed market beating",

    "Always a winner",
    "Never declining",
    "Continuously profitable",
    "Eternal growth",
    "Perpetual success",
    "Constant returns",
    "Unceasing profitability",
    "Inexorable financial gains",
    "Unstoppable investment",

    "Hot stock",
    "Get rich quick",
    "Next big thing",
    "Explosive growth potential",
    "Once in a lifetime opportunity",
    "Skyrocketing assets",
    "Breakout stock pick",
    "Secret investment",
    "Insider tip",

    "High yield investment",
    "Fast returns",
    "Quick profit",
    "Instant financial growth",
    "Rapid income increase",
    "Double your money",
    "Short-term gains",
    "Immediate cash flow",
    "Large profits",

    "Miracle stock",
    "Hidden gem",
    "Rapid growth stock",
    "Undiscovered opportunity",
    "Game-changing investment",
    "Golden ticket",
    "Surefire winner",
    "Money-spinning opportunity",
    "Under-the-radar pick",

    "Effortless profit",
    "Guaranteed doubling of investment",
    "Risk-free high returns",
    "Money-making machine",
    "Wealth creation certainty",
    "Immediate wealth accumulation",
    "Rapid financial turnaround",
    "Guaranteed high ROI",
    "Overnight success",

    "Pump and dump",
    "Price spiking",
    "Artificial inflation",
    "Market cornering",
    "Painting the tape",

    "Record-breaking returns",
    "Highest yielding",
    "Exceptional performance history",
    "Unmatched track record",
    "Superior historical growth",
    "Consistently outperformed",
    "Top quartile ranking",


]


export const finraComplianceWords = [
    "Guaranteed top performance",
    "Sure maximum returns",
    "Never underperforms",
    "Always beats the market",
    "Consistently outperforms",
    "Permanent market leader",
    "Forever outperforming",
    "Infallible strategy",
    "Market-proof investment",
    "Recession-proof earnings",
    "Bulletproof strategy",
    "Teflon-coated investment",
    "Ironclad performance",
    "Unfailing success",
    "Inexorable returns",
    "Invincible strategy",
    "Immune to market downturns",
    "Defies market corrections",

    "Superior to",
    "Better than",
    "Outperforms",
    "More profitable than",
    "Exceeds",
    "Leading the",
    "Top-ranked compared to",
    "Outshines",
    "Surpasses",
    "Best in class against",
    "Beating the",
    "Advantage over",
    "More effective than",
    "Higher yielding than",
    "Greater returns than",
    "More secure than",
    "Smarter choice than",

    "Act now",
    "Limited time offer",
    "Immediate action required",
    "Don't miss out",
    "Urgent opportunity",
    "Time-sensitive investment",
    "Last chance",
    "Hurry",
    "Offer expires soon",
    "Immediate returns",
    "Quick profits",
    "Fast action required",
    "Before it's too late",
    "Closing soon",
    "Final opportunity",
    "Rush",
    "Immediate access",
    "Act fast",
    "Act before",
    "Flash sale",

    "Don't be left behind",
    "Once in a lifetime opportunity",
    "Exclusive offer",
    "Only a few spots left",
    "Join the elite",
    "Be the first",
    "Get in on the ground floor",
    "Insider opportunity",
    "Limited availability",
    "Exclusive access",
    "Special invitation",
    "One-time deal",
    "Now or never",
    "Seize the opportunity",
    "Limited seats",
    "Elite group",
    "Insider's choice",
    "Early bird special",
    "Jump on the bandwagon",
    "Secure your spot",

    "No risk",
    "Risk-free",
    "Sure thing",
    "Guaranteed success",
    "Can't lose",
    "Safe as houses",
    "Money machine",
    "Foolproof",
    "Easy money",
    "Guaranteed growth",
    "No fail",
    "Can't miss",
    "Painless profit",
    "Quick cash",
    "Fast money",
    "Guaranteed income",
    "Certain gain",
    "No-brainer",
    "Win-win situation",
    "Guaranteed wealth",
    "Iron-clad investment",
    "Fail-safe",
    "Sure bet",
    "Easy win",
    "No downside",
    "Without risk",

    "High yield",
    "Significant returns",
    "Massive profits",
    "Huge gains",
    "Major earnings",
    "Big opportunity",
    "Substantial income",
    "Large profits",
    "Quick growth",
    "High performance",
    "Strong returns",
    "Secure investment",
    "Solid returns",
    "Robust profits",
    "Remarkable gains",
    "Exceptional opportunity",
    "Premium investment",
    "Elite returns",
    "Superior profits",

    "Will increase",
    "Guaranteed growth",
    "Definite rally",
    "Certain spike",
    "Sure to rise",
    "Predicted surge",
    "Will double",
    "Bound to jump",
    "Surefire appreciation",
    "Unstoppable growth",
    "Assured profit",
    "Inevitable gains",
    "Projected to multiply",
    "Destined to soar",
    "Set to explode",
    "Guaranteed to appreciate",
    "Certain boom",
    "Unfailingly profitable",
    "Will skyrocket",

    "Projected earnings of",
    "Expected returns of",
    "Forecasted growth rate",
    "Anticipated profits",
    "Projected to yield",
    "Estimated appreciation",
    "Calculated gains of",
    "Predicted performance",
    "Forecasted returns",
    "Expected income of",
    "Estimated dividend yield",
    "Anticipated rate of return",
    "Projected market share",
    "Expected capital gains",
    "Estimated ROI",
    "Forecasted valuation",
    "Easy profits",
    "Simple gains",
    "No effort required",
    "Easy money",
    "Quick cash",
    "Simplest way to wealth",
    "Effortless income",
    "No knowledge necessary",
    "Straightforward profits",
    "Guaranteed easy",
    "Foolproof plan",
    "Risk-free profits",
    "Hassle-free returns",
    "Instant wealth",
    "Just like saving",
    "Can't go wrong",
    "Minimal effort",
    "Easy as pie",
    "Sure thing",
    "Nothing to it",
    "Plain sailing",

    "High yield",
    "Significant returns",
    "Substantial profits",
    "Big earnings",
    "Major gains",
    "High performance",
    "Strong results",
    "Massive income",
    "Large growth",
    "Solid returns",
    "Robust profits",
    "Top results",
    "Premium investment",
    "Superior performance",
    "Exceptional opportunity",
    "Great potential",
    "Remarkable benefits",
    "Optimal results",
    "Incredible features",
    "Ultimate choice",
    "Unparalleled advantage",

]


export const additionalKeywords = [
    "Guaranteed crypto returns",
    "Risk-free cryptocurrency",
    "Safe digital asset investment",
    "Secure blockchain profits",
    "High returns on tokens",
    "Stablecoin guaranteed income",
    "Security and Safety Claims",
    "Hack-proof blockchain",
    "Unhackable cryptocurrency",
    "Secure crypto transactions",
    "Foolproof digital wallet",
    "Theft-proof blockchain",
    "Loss-free crypto investment",
    "Fast Profit and Instant Wealth",
    "Instant cryptocurrency profits",
    "Quick crypto gains",
    "Fast digital asset growth",
    "Immediate blockchain returns",
    "Speedy crypto earnings", 
    "Overnight digital wealth",
    "Technological Superiority",
    "Cutting-edge blockchain technology", 
    "Advanced crypto algorithms",
    "Superior digital token design",
    "Next-gen blockchain platform",
    "Revolutionary cryptocurrency system",
    "State-of-the-art crypto mining",
    "Market Dominance and Exclusivity",
    "Leading cryptocurrency investment",

    "Top digital currency",
    "Premier crypto assets",
    "Exclusive blockchain access",
    "Elite digital tokens",
    "Market-leading crypto returns",
    "Undefined or Vague Technological Claims",
    "Innovative crypto solution",
    "Unique blockchain opportunity",
    "Rare digital asset offer",
    "Exclusive token launch",
    "Limited supply cryptocurrency",
    "One-time crypto investment chance",
    "Speculative and Predictive Language",
    "Skyrocketing crypto prices",
    "Exploding blockchain market",
    "Booming digital currencies",
    "Surging crypto values",
    "Cryptocurrency gold rush",
    "Future of finance tokens",
    "Misleading Comparisons or Guarantees",
    "Safer than traditional banking",
    "More profitable than stocks",
    "Better returns than real estate",
    "Outperforms gold",
    "Faster growth than tech stocks",
    "More stable than forex",
    "Guaranteed token appreciation",
    "Risk-free blockchain investments",
    "No-loss crypto trading",
    "Safe digital assets",
    "Secure coin growth",

    "AI-driven returns", "Machine learning guaranteed profits", "AI-enhanced investment opportunities", "Smart algorithm profits", "Foolproof AI strategies", "Risk-free AI investments", "AI-powered financial gains", "Algorithmic Trading", "Algorithmic trading with no risk", "Guaranteed algorithmic profits", "Safe algo-trading systems", "Error-free trading algorithms", "High-performance trading bots", "Perfect algorithm execution", "No-loss algorithm strategies", "Technological Superiority and Performance", "Superior tech investment tools", "Cutting-edge financial technologies", "High returns from tech innovations", "Unmatched tech-driven growth", "Revolutionary trading technology", "Next-gen investment algorithms", "State-of-the-art trading platforms", "Guaranteed and High Returns", "Tech-enhanced guaranteed returns", "High-tech high returns", "Secure tech investments", "Profitable technology upgrades", "High-yield tech solutions", "Surefire tech investment success", "Undefined or Vague Tech Claims",

    "Innovative investment technology", "Advanced trading mechanisms", "Unique fintech solutions", "Exclusive algorithmic models", "Proprietary tech investing methods", "Breakthrough financial technology", "Overly Optimistic and Speculative Language", "Exploding fintech market", "Booming tech stocks", "Skyrocketing tech funds", "High-growth tech ventures", "Surging digital asset returns", "Future-proof tech investments", "Misleading Comparisons or Guarantees", "Safer than traditional trading", "More profitable than manual trading", "Better than conventional investments", "Outperforms human traders", "Faster gains than standard methods", "More stable than traditional markets",

    "SEC-compliant returns", "FINRA-approved strategy", "Government-backed securities", "Compliance guaranteed investments", "SEC-endorsed investments", "FINRA-certified products", "Government-approved returns", "Regulatory-approved strategies", "Misleading Claims of Security and Safety", "Regulation-protected profits", "SEC-safe investments", "FINRA-secure strategies", "Government-secured returns", "Risk-free by regulation", "Compliance-protected capital", "Regulatory safeguarded assets", "Compliance Assurance", "Fully compliant investment opportunities", "Compliance ensured strategies", "Regulation-ready products", "Ahead of compliance curve",

    "Meets all regulatory standards", "Exceeds compliance requirements", "Regulatory compliance guaranteed", "Authority and Legitimacy Implications", "Officially recognized investments", "Legally compliant returns", "Authorized by regulators", "Certified compliant strategies", "Legitimized by government approval", "Sanctioned investment products", "Endorsed by regulatory bodies", "Overstatements of Regulatory Role", "SEC-proof investments", "FINRA-proof strategies", "Government-proof returns", "Beyond regulatory scrutiny", "Immune to regulation changes", "Exempt from compliance concerns",

    "Pandemic-proof investments", "Recession-proof strategy", "COVID-safe returns", "Economic downturn resistant profits", "Crisis-proof financial plans", "Recession-resistant portfolios", "Inflation-proof investments", "Depression-safe strategies", "Overconfidence in Stability", "Market crash-proof investments", "Lockdown-proof earnings", "Quarantine-safe returns", "Financial contagion immune profits", "Disaster-resistant investments", "Turmoil-proof financial products", "Catastrophe-safe returns", "Misleading Safety and Security", "Risk-free during pandemics", "Secure despite recession", "Unaffected by economic crisis", "Stable in volatile markets", "Protected from economic shocks", "Safe from global instability",

    "Guarantees Against External Risks", "Guaranteed returns during recession", "Guaranteed safety amid pandemic", "Guaranteed profits in economic downturns", "Secure earnings during market fluctuations", "Fixed returns in financial crises", "Overstatements and Exaggerations", "Bulletproof against economic decline", "Ironclad during downturns", "Unshakeable in financial crises", "Rock-solid in recessions", "Fortified against pandemics"
]


export const more_non_compliant_keywords = [
    "Guaranteed returns", "Guaranteed income", "Guaranteed profit", "Guaranteed growth", "Guaranteed success",
    "Guaranteed wealth", "Profit guaranteed", "Income guaranteed", "Returns guaranteed", "No loss", "Risk-free",
    "No-risk", "Sure bet", "Can't lose", "Fail-proof", "Never fails", "Fixed return", "Assured yield", "Secure investment",
    "Certain profit", "Sure profit", "Locked-in returns", "Secured gains", "Money-back guarantee", "No way to lose",
    "Absolute certainty", "Always profitable", "Permanent gains", "Forever successful", "Eternally beneficial",
    "Consistently yielding", "Unfailingly profitable", "Infallible investment", "Uninterrupted success", "Always a winner",
    "Never declining", "Continuously profitable", "Eternal growth", "Perpetual success", "Constant returns", "Unceasing profitability",
    "Inexorable financial gains", "Unstoppable investment", "High yield", "High returns", "Quick profit", "Instant wealth",
    "Double your money", "Overnight success", "Effortless profit", "Guaranteed top performance", "Sure maximum returns",
    "Never underperforms", "Always beats the market", "Consistently outperforms", "Permanent market leader", "Forever outperforming",
    "Market-proof investment", "Recession-proof earnings", "Bulletproof strategy", "Ironclad performance", "Unfailing success",
    "Inexorable returns", "Invincible strategy", "Immune to market downturns", "Defies market corrections", "Certain profit",
    "Surefire investment", "No-risk opportunity", "Fail-proof", "Guaranteed market beating", "Profit assured", "Zero risk",
    "Never fails", "Forever successful", "Certain success", "Guaranteed wealth", "Definite gain", "Ironclad guarantee",
    "Total security", "Complete protection", "Foolproof investment", "Entirely risk-free", "Fully secured", "100% safe",
    "100% secure", "Complete assurance", "Uninterrupted success", "Unceasing profitability", "Eternal growth", "Perpetual success",
    "Constant returns", "Endless opportunities", "Unlimited potential", "Infinite possibilities", "Unmatched performance",
    "Unsurpassed growth", "Unparalleled returns", "Peerless results", "Supreme quality", "Optimal performance", "Ultimate choice",
    "Market-beating returns", "Outperforms the market", "Leading the market", "Top performer", "Best in class", "Superior performance",
    "Record-breaking gains", "Best-performing", "Industry-leading", "Better than", "Exceeds", "Surpasses", "More profitable than",
    "Outshines", "Leading edge", "Ahead of the pack", "Top-ranked", "Market leader", "Dominates the market", "Cornering the market",
    "Defining the industry", "Pioneering the market", "Sets the standard", "Gold standard", "Premium quality", "State-of-the-art",
    "Cutting edge", "Elite performance", "Exceptional quality", "Superior quality", "Unrivalled", "Unbeatable", "Peerless", 
    "Without equal", "Second to none", "The best available", "Safe investment", "Secure investment", "Guaranteed safety",
    "Protected investment", "Safeguarded assets", "Secure profits", "Riskless", "Safe as houses", "Stable returns", 
    "Consistent returns", "Steady growth", "Reliable profits", "Financial security", "Safe harbor", "Hedged risk", "Insured investment",
    "Guaranteed not to lose", "Loss-proof", "Recession-proof", "Market-proof", "Economic downturn resistant", "Immune to market volatility",
    "Solid investment", "Strong financials", "Robust returns", "Durable growth", "Resilient performance", "Stable financial product",
    "Financial cushion", "Secure your future", "Financial safeguard", "100% secure", "Completely safe", "Fully protected", 
    "Absolutely risk-free", "Entirely stable", "Totally risk-averse", "Unconditional safety", "Ultimate security", "Explosive growth",
    "Skyrocketing profits", "Huge potential", "Massive returns", "High growth potential", "Rapid growth", "Fast gains", "Quick profits",
    "Next big thing", "Could double", "Might skyrocket", "Potential to explode", "Promising future", "Up-and-coming", "Watch this space",
    "On the verge of", "Poised for success", "Booming market", "Soaring values", "Climbing rates", "Surging prices", "Hot stock", 
    "Bullish trend", "Going through the roof", "Breaking out", "Bet on", "Roll the dice", "Take a chance", "Gamble", "Hit the jackpot",
    "Strike it rich", "Big win", "High stakes", "Speculative opportunity", "High-risk, high-reward", "Venture play", "Risky investment",
    "Aggressive growth strategy", "Leveraged buy", "Volatile asset", "Play the market", "Miracle stock", "Secret tip", "Insider scoop",
    "Undiscovered gem", "Hidden opportunity", "Exclusive insight", "Revolutionary product", "Groundbreaking technology", "Limited time offer",
    "Act now", "Hurry", "Offer ends soon", "While supplies last", "Time is running out", "Urgent", "Immediate action required",
    "Don’t miss out", "Last chance", "Expires soon", "Closing soon", "Exclusive offer", "Members only", "VIP access", "Insider opportunity",
    "One-time offer", "Limited availability", "Few spots left", "Early access", "Limited edition", "Special promotion", "Only a few available",
    "First come, first served", "Act fast", "Quick profits", "Instant access", "Immediate returns", "Fast track", "Speedy gains", "Rapid returns",
    "Flash sale", "Before it’s too late", "Immediate enrollment", "Quick decision required", "Secure your spot", "Join now", "Don't be left behind",
    "Can't afford to miss", "Jump on the bandwagon", "Now or never", "Seize the opportunity", "Strike while the iron is hot", "Fear of missing out",
    "Don't get left out", "Protect yourself from loss", "Avoid regret", "Act before it's too late", "Save what you have", "Shield your investments",
    "Unbelievable deal", "Best deal ever", "Greatest opportunity", "Massive returns guaranteed", "Incredible results", "Unmatched benefits", 
    "Phenomenal success", "High returns", "Significant profits", "Large income", "Massive growth", "Strong performance", "Substantial gains", 
    "Major earnings", "Exceptional yields", "Superior results", "Market-leading", "Best-in-class", "Top-rated", "High-quality", "Robust", "Premium",
    "Optimized", "Strategic", "Solid investment", "Great potential", "Good value", "Favorable returns", "Excellent opportunity", "Smart choice",
    "Wise investment", "Profitable", "Lucrative", "Rewarding", "Better", "Stronger", "Safer", "More effective", "More reliable", "Less risky",
    "Higher performing", "Leading provider", "Leading edge", "Innovative", "Groundbreaking", "Revolutionary", "Pioneering", "State-of-the-art",
    "Trailblazing", "Guaranteed satisfaction", "Best available", "Unparalleled", "Unmatched", "Unprecedented", "Ultimate", "Guaranteed crypto returns",
    "Risk-free cryptocurrency", "Safe digital asset investment", "Secure blockchain profits", "High returns on tokens", "Stablecoin guaranteed income",
    "Hack-proof blockchain", "Unhackable cryptocurrency", "Secure crypto transactions", "Foolproof digital wallet", "Theft-proof blockchain",
    "Loss-free crypto investment", "Instant cryptocurrency profits", "Quick crypto gains", "Fast digital asset growth", "Immediate blockchain returns",
    "Speedy crypto earnings", "Overnight digital wealth", "Cutting-edge blockchain technology", "Advanced crypto algorithms", "Superior digital token design",
    "Next-gen blockchain platform", "Revolutionary cryptocurrency system", "State-of-the-art crypto mining", "Leading cryptocurrency investment",
    "Top digital currency", "Premier crypto assets", "Exclusive blockchain access", "Elite digital tokens", "Market-leading crypto returns", 
    "Innovative crypto solution", "Unique blockchain opportunity", "Rare digital asset offer", "Exclusive token launch", "Limited supply cryptocurrency",
    "One-time crypto investment chance", "Skyrocketing crypto prices", "Exploding blockchain market", "Booming digital currencies", "Surging crypto values",
    "Cryptocurrency gold rush", "Future of finance tokens", "Safer than traditional banking", "More profitable than stocks", "Better returns than real estate",
    "Outperforms gold", "Faster growth than tech stocks", "More stable than forex", "Guaranteed token appreciation", "Risk-free blockchain investments",
    "No-loss crypto trading", "Secure coin growth", "Green guaranteed returns", "Ethical investment with guaranteed profits", "Risk-free sustainable investing",
    "Guaranteed ESG growth", "Safe green investments", "Secure ESG profits", "Foolproof ESG strategy", "Can't lose with green investing", "No-risk ESG funds"
]