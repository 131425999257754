import React from 'react';

import { CustomCancelButton, CustomDeleteButton } from './CustomButton';
import CustomModal from './CustomModal';

import './DeleteConfirmationModal.css';
import BouncingDots from './Loaders/BouncingDots/BouncingDots';

const DeleteConfirmationModal = (props) => {

    return (
        <CustomModal
            closeModal={() => props.closeModal()}
            customModalContainerStyle={{ width: "500px", borderRadius: "10px", "paddingTop": "10px" }}>

            {props.isLoading && <BouncingDots />}

            <div className="delete-confirmation-modal-main-col">

                <p className={`header-font-light`}>{props.title}</p>

                <div className="delete-confirmation-container">
                    {props.confirmationText !== undefined &&
                        <p className={`regular-font-light`}>{props.confirmationText}</p>
                    }
                    <CustomDeleteButton
                        buttonText={"Delete"}
                        customStyle={{marginBottom: "10px", marginTop: "20px"}}
                        onClick={() => props.isLoading ? console.log("") : props.delete()} />
                    <CustomCancelButton
                        buttonText={"Cancel"}
                        onClick={() => props.isLoading ? console.log("") : (props.onCancel === undefined ? props.closeModal() : props.onCancel())}
                    />
                    
                </div>

            </div>

        </CustomModal>
    )

}

export default DeleteConfirmationModal;
