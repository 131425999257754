export enum KnowledgeBase {
  FINRA = "49fb1557-dc03-49a7-9f31-cdaf059fb43b",
  SEC = "64be5a17-d2b0-4ca8-951a-eeced0a0b9d0",
  Hybrid = "7df1f6b3-7f22-419e-b1bf-faaad7acbe1f",
  DEMO_KB = "239aa451-cc85-447a-aa1d-4464d2f813a5",
}


export enum KnowledgeBaseType {
  BrandPersonalization = "Brand Personalization",
  Compliance = "Compliance",
  AdvisorAssist = "Advisor Assist",
  DocumentAnalyst = "Document Analyst",
}


export const chat_and_long_form_kbs = [
  KnowledgeBaseType.BrandPersonalization,
  KnowledgeBaseType.Compliance,
];