import { useState } from "react";
import { secComplianceWords, finraComplianceWords, additionalKeywords, more_non_compliant_keywords } from "../utils/complianceWords";
import * as generateServices from "../services/longForm";

export async function reviseChatContent(styleSample: string, content: string) {

    const idToken = sessionStorage.getItem("idToken");

    const payload = {
        input: content,
        style_sample: styleSample,
    }

    console.log("payload", payload)

    const [resData, status] = await generateServices.revise(idToken, payload);

    if (status !== 202) {
        alert("Error in revising the content");
        return [{}, 500]
    }

    const id = resData.id;
    let pollCount = 0;
    let pollStatus = resData.status;

    while (pollCount < 200 && pollStatus !== "COMPLETE") {
        await new Promise(r => setTimeout(r, 2000));
        const [pollData, newPollStatus] = await generateServices.pollReviseResponse(idToken, id);
        pollStatus = pollData.status;
        pollCount += 1;

        if (pollStatus === "COMPLETE") {
            return [pollData.response.output, 200];
        }
    }


}

export function highlightNonCompliantWords(text) {

    if (text === undefined || text === null) {
        return "";
    }

    //console.log("text", text)

    let flaggedWords = secComplianceWords.concat(finraComplianceWords);
    flaggedWords = flaggedWords.concat(additionalKeywords);
    flaggedWords = flaggedWords.concat(more_non_compliant_keywords);
    // Turn all of the words into lowercase
    flaggedWords = flaggedWords.map((word) => word.toLowerCase());

    // Split the content by \n\n first
    const parts = text.split("\n\n");

    // Split each part by spaces
    parts.forEach((part, i) => {
        parts[i] = part.split(" ");
    });

    // Create the new formatted string. If a word is in the flagged words list, then we want highlight it
    // We also need to add in a double new line after each part list
    let newContent = ``;
    for (let i = 0; i < parts.length; i++) {
        for (let j = 0; j < parts[i].length; j++) {
            const word = parts[i][j];
            newContent += (flaggedWords.includes(word.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "").toLowerCase()) ? `<mark>` + word + `</mark>` : word) + " ";
        }
        newContent += "\n\n";
    }

    return newContent;

}

export function breakResponseIntoChunks(
    currentText: string,
    newResponseText: string
): string[] {
    // For simulating streaming chat, we need to determine which words we need to display next

    // currentText will be the current text in the chat window
    // newResponseText will be the new response from the LLM, which will include the current text
    // We just care about the new text that needs to be added to the chat window
    const newTextAdded = newResponseText.slice(currentText.length);

    // Split the new text into words
    const newWords = newTextAdded.split(" ");

    const numWordsToAdd = newWords.length;

    // Randomly choose between 1 and 4 words to add to each chunk
    let chunks = [];
    let numWordsAdded = 0;
    while (numWordsAdded < numWordsToAdd) {
        let numWords = Math.floor(Math.random() * 4) + 1;
        let newString = newWords.slice(0, numWords).join(" ");
        newString += (numWordsAdded + numWords < numWordsToAdd ? " " : "")
        chunks.push(newString);
        newWords.splice(0, numWords);
        numWordsAdded += numWords;
    }

    return chunks;

}

interface Source {
    document: {
        title: string;
    };
    title: string;
}

interface ChatHistory {
    id: string;
    prefix: string;
    content: string;
    sources: Source[];
    searchQueries: string[];
    searchResults: string[];
}


export function formatWebSources(webSearchReferences = [], webSearchResults = []) {

    let sources = [];
    let numReferencesPerURL = {};

    if (webSearchReferences === undefined || webSearchReferences === null || webSearchResults === undefined || webSearchResults === null) {
        return [];
    }

    for (let i = 0; i < webSearchReferences.length; i++) {
        const index = webSearchReferences[i];
        let source = webSearchResults[index];
        source["fullTitle"] = webSearchResults[index]["title"]

        let displayedTitle = webSearchResults[index]["title"];
        let count = 1
        if (numReferencesPerURL[displayedTitle] !== undefined) {
            count = numReferencesPerURL[displayedTitle] + 1;
        }
        numReferencesPerURL[displayedTitle] = count;
        displayedTitle = (count > 1 ? displayedTitle + " (" + count + ")" : displayedTitle);
        // Cap the number of characters in the document title
        //let displayedTitle = documentTitle;
        if (displayedTitle.length > 35) {
            displayedTitle = displayedTitle.slice(0, 15) + "..." + displayedTitle.slice(-17);
        }

        source["title"] = displayedTitle;
        sources.push(source);
    }

    return sources;

}

export function formatSources(references = [], rankedResults = []) {

    // references is just an array of indices that correlates to the rankedResults array

    let sources: Source[] = [];
    let numReferencesPerDocument = {};
    let displayedTitle = "";
    let source = {};

    if (references === undefined || references === null || rankedResults === undefined || rankedResults === null) {
        return [];
    }

    for (let i = 0; i < references.length; i++) {
        const index = references[i];
        const rankedResult = rankedResults[index]["metadata"];
        displayedTitle = rankedResult["document"]["title"];
        if (displayedTitle === null || displayedTitle === undefined) {
            continue;
        }
        let count = 1
        if (numReferencesPerDocument[displayedTitle] !== undefined) {
            count = numReferencesPerDocument[displayedTitle] + 1;
        }
        numReferencesPerDocument[displayedTitle] = count;
        displayedTitle = (count > 1 ? displayedTitle + " (" + count + ")" : displayedTitle);
        // Cap the number of characters in the document title
        //let displayedTitle = documentTitle;
        if (displayedTitle.length > 35) {
            displayedTitle = displayedTitle.slice(0, 15) + "..." + displayedTitle.slice(-17);
        }
        source = rankedResult;
        source["document"]["title"] = rankedResult["document"]["title"];
        source["title"] = displayedTitle;
        sources.push(source);
    }

    return sources;
}


export function formatChatThreadTitle(chatThread) {
    // First check if the title exists
    if (chatThread.title == undefined || chatThread.title === "") {
        return "New Chat"
    }
    const title = chatThread.title;
    // Strip extra "" from the title
    if (title[0] === '"' && title[title.length - 1] === '"') {
        return title.slice(1, -1);
    }
    return title;

}


export function sortChatThreadsByRecentlyUsed(chatThreads) {

    let sortedChatThreads = chatThreads.sort((a, b) => {
        return new Date((b.created_on - a.created_on));
    })
    return sortedChatThreads;
}


export function formatChatThread(chatThread) {
    console.log("chatThread", chatThread)
    // Format the data into a more usable format for the frontend
    if (chatThread == undefined) {
        return [];
    }
    let chatHistory = chatThread.interactions;
    console.log("chatHistory", chatHistory)
    // Sort the chat history by id (increasing order)
    chatHistory.sort((a, b) => {
        return a.id - b.id;
    });
    let formattedChatHistory: ChatHistory[] = [];
    for (let i = 0; i < chatHistory.length; i++) {
        // Get the user message and AI response
        let userMessage = chatHistory[i]["user_input"]["content"];
        let aiResponse = chatHistory[i]["model_response"]["content"];
        const sources = formatSources(chatHistory[i]["references"], chatHistory[i]["ranked_results"]);

        let webSources = [];
        if (chatHistory[i]["web_search_references"] !== undefined && chatHistory[i]["web_search_references"] !== null && chatHistory[i]["web_search_results"] !== undefined) {
            webSources = formatWebSources(chatHistory[i]["web_search_references"], chatHistory[i]["web_search_results"])
        }
        const webSearchQueries = chatHistory[i]["web_search_queries"];
        const webSearchResults = chatHistory[i]["web_search_results"];

        const searchQueries = chatHistory[i]["search_queries"];

        // Extract the metadata from the ranked_results
        let searchResults = [];
        for (let j = 0; j < chatHistory[i]["ranked_results"].length; j++) {
            searchResults.push(chatHistory[i]["ranked_results"][j]["metadata"]);
        }

        const id = chatHistory[i]["id"];
        formattedChatHistory.push({ id: `user_${id}`, prefix: "user", content: userMessage, sources: [], searchQueries: [], searchResults: [], webSources: [], webSearchQueries: [], webSearchResults: [] });
        formattedChatHistory.push({ id: `ai_${id}`, prefix: "ai", content: aiResponse, sources: sources, searchQueries: searchQueries, searchResults: searchResults, webSources: webSources, webSearchQueries: webSearchQueries, webSearchResults: webSearchResults });
    }
    return formattedChatHistory;
}

