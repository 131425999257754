import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const CircularProgressWithLabel = (
    props,
) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                variant="determinate" {...props}
                sx={{
                    color: '#5D24D8', // Use the theme's primary color
                    // You can also use custom colors like '#ff0000'
                    // For a larger size, adjust the size property
                    size: '150px', // Set the size of the CircularProgress
                    ...props.sx, // Allow additional styles to be passed via props
                }}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

export const LinearProgressWithLabel = (props) => {

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: "100%" }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#5D24D8', // Custom color for the progress bar
                        },
                    }}
                />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary"
                sx={{
                    color: "#AEAEAE"
                }}>
                    {`${Math.round(props.value,)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

export default CircularProgressWithLabel;