import React, { useState } from "react";

import { usePreviousReviewsHandler } from "../logic/documentReview";

import BouncingDots from "../components/Loaders/BouncingDots/BouncingDots.js";
import Sidebar from "../components/Sidebar/Sidebar";
import ViewArchive from "../components/ViewArchive/ViewArchive";
import { useAppSelector } from "../hooks.ts";
import { useAuthExpiry } from "../logic/auth";
import "./Archive.css";

const Archive = () => {
  
  useAuthExpiry();

  const auth = useAppSelector((state) => state.auth);

  const { previousReviews, isLoading, getDocumentContent } =
    usePreviousReviewsHandler(auth.idToken, auth.user._id);
  const [resultIndexShown, setResultIndexShown] = useState(null);
  const [documentContent, setDocumentContent] = useState(null);
  // const [contentOrReview, setContentOrReview] = useState("content");
  const [isModalOpen, setIsModalOpen] = useState(false);

  async function toggleResultIndexShown(index) {
    const documentId = previousReviews[index]["request"]["document_id"];
    if (resultIndexShown == index) {
      setResultIndexShown(null);
      setIsModalOpen(false);
    } else {
      const [resData, status] = await getDocumentContent(documentId);
      if (status === 200) {
        setDocumentContent(resData["content"]);
      }
      setIsModalOpen(true);
      setResultIndexShown(index);
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Sidebar currentPage={"archive"} />
      <div className="archive-section">
        {/* <Header currentPage={"archive"} /> */}

        {isLoading && <BouncingDots />}

        <div className="previous-reviews-container">
          <div className="document-review-row">
            <div className="document-review-date-col">
              <p className="semi-bold-font-light">Date</p>
            </div>
            <div className="document-review-title-col">
              <p className="semi-bold-font-light">Title</p>
            </div>

            <div className="view-review-col"></div>
          </div>

          {!isLoading && previousReviews.length === 0 && (
            <div className="no-previous-reviews">
              <p className="regular-font-light">No previous reviews</p>
            </div>
          )}

          {previousReviews.map((review, index) => (
            <div className="previous-document-review-container" key={index}>
              <div className="document-review-row">
                <div className="document-review-date-col">
                  <p className="regular-font-light">{review["date"]}</p>
                </div>

                <div className="document-review-title-col">
                  <p className="regular-font-light">
                    {review["response"]["title"]}
                  </p>
                </div>

                <div className="view-review-col">
                  <p
                    className="view-review-text"
                    onClick={() => toggleResultIndexShown(index)}
                  >
                    {resultIndexShown == index ? "hide" : "view"}
                  </p>
                </div>
              </div>

              {resultIndexShown === index && (
                <ViewArchive
                  isOpen={isModalOpen}
                  onRequestClose={() => toggleResultIndexShown(index)}
                  title={review["response"]["title"]}
                  date={review["date"]}
                  content={documentContent}
                  review={review["response"]["final_review"]}
                />
              )}

              {/* {resultIndexShown === index && (
                <div className="previous-review-result-data">
                  <div className="content-or-review-text-container">
                    <p
                      className={`content-or-review-text${
                        contentOrReview === "content" ? "-selected" : ""
                      }`}
                      onClick={() => setContentOrReview("content")}
                    >
                      Content
                    </p>
                    <p
                      className={`content-or-review-text${
                        contentOrReview === "review" ? "-selected" : ""
                      }`}
                      onClick={() => setContentOrReview("review")}
                    >
                      Review
                    </p>
                  </div>
                  {documentContent !== null &&
                    contentOrReview === "content" && (
                      <div className="document-review-content">
                        <Markdown
                          markdownContent={documentContent}
                          className={`regular-font-light document-review-result-text`}
                        />
                      </div>
                    )}
                  {contentOrReview === "review" && (
                    <div className="document-review-content">
                      <Markdown
                        markdownContent={review["response"]["final_review"]}
                        className={`regular-font-light document-review-result-text`}
                      />
                    </div>
                  )}
                </div>
              )} */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Archive;
