import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  Typography,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { updateUserAPI } from "../logic/user";
import { useAppDispatch } from "../hooks.ts";
import { setUser } from "../features/auth/authSlice.ts";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

const Onboarding = () => {
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [website, setWebsite] = useState("");
  const [license, setLicense] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [usage, setUsage] = useState([]);
  const [brandColors, setBrandColors] = useState({
    primary: "",
    secondary: "",
  });

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    getAccessToken();
  }, []);

  const getAccessToken = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        cacheMode:  "off",
      });
      localStorage.setItem("token", accessToken);
    } catch (error) {
      console.error(error);
    }
  };

  const history = useHistory();
  const handleSubmit = async (e) => {
    e.preventDefault();
   
    if (
      first_name && first_name.length > 0 &&
      last_name && last_name.length > 0 &&
      website && website.length > 0 &&
      license && license.length > 0 &&
      usage && usage.length > 0 &&
      brandColors.primary && brandColors.primary.length > 0 &&
      brandColors.secondary && brandColors.secondary.length > 0
    ) {
      const token =await getAccessTokenSilently();
      localStorage.setItem("token", token);
      const user = await updateUserAPI({
        first_name,
        last_name,
        company: {
          name: company_name,
          website,
        },
        license,
        brandColors,
        usage,
      });
      dispatch(setUser(user));
      history.push("/");
    } else {
      alert("Please fill all the fields");
    }
  };

  const handleUsageChange = (event) => {
    const { value, checked } = event.target;
    setUsage((prev) =>
      checked ? [...prev, value] : prev.filter((item) => item !== value)
    );
  };

  // const handleFileChange = (e) => {
  //   setLogo(e.target.files[0]);
  // };

  return (
    <div style={{ display: "flex", justifyContent: "center", margin: "50px" }}>
      <form
        onSubmit={handleSubmit}
        style={{ color: "white", padding: "20px", backgroundColor: "black" }}
      >
        <Typography variant="h6" gutterBottom style={{ color: "white" }}>
          Onboarding Form
        </Typography>
        <TextField
          label="First Name"
          variant="outlined"
          value={first_name}
          onChange={(e) => setFirstName(e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{
            style: { color: "white" },
          }}
          InputProps={{
            style: { color: "white" },
          }}
        />
        <TextField
          label="Last Name"
          variant="outlined"
          value={last_name}
          onChange={(e) => setLastName(e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{
            style: { color: "white" },
          }}
          InputProps={{
            style: { color: "white" },
          }}
        />
        <TextField
          label="Firm Name"
          variant="outlined"
          value={company_name}
          onChange={(e) => setCompanyName(e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{
            style: { color: "white" },
          }}
          InputProps={{
            style: { color: "white" },
          }}
        />
        <TextField
          label="Firm Website URL"
          variant="outlined"
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{
            style: { color: "white" },
          }}
          InputProps={{
            style: { color: "white" },
          }}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="license-select-label" style={{ color: "white" }}>
            Select License
          </InputLabel>
          <Select
            labelId="license-select-label"
            value={license}
            label="Select License"
            onChange={(e) => setLicense(e.target.value)}
            style={{ color: "white", borderBottom: "1px solid white" }}
          >
            <MenuItem value="RIA">RIA</MenuItem>
            <MenuItem value="Hybrid RIA">Hybrid RIA</MenuItem>
            <MenuItem value="FINRA Registered Representative">
              FINRA Registered Representative
            </MenuItem>
            <MenuItem value="IAR">IAR</MenuItem>
          </Select>
        </FormControl>
        <FormGroup>
          <FormLabel
            component="legend"
            style={{ color: "white", marginTop: "20px" }}
          >
            What do you want to use AdvisorX AI for? (Select your top 3)
          </FormLabel>
          {[
            "Blogs",
            "Articles",
            "Newsletters",
            "E-mail Campaigns",
            "Market Commentaries",
            "Whitepapers",
            "Social Media Posts",
            "Marketing Compliance Reviews",
          ].map((option, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={usage.includes(option)}
                  onChange={handleUsageChange}
                  value={option}
                  style={{ color: "white" }}
                />
              }
              label={option}
            />
          ))}
        </FormGroup>

        {/* <input
          accept="image/*"
          id="logo"
          type="file"
          onChange={handleFileChange}
          style={{ display: "none" }}
        /> */}
        {/* <label htmlFor="logo">
          <Button
            variant="contained"
            component="span"
            style={{ marginTop: "20px" }}
          >
            Upload Firm Logo
          </Button>
        </label>
        {logo && (
          <Typography variant="body1" style={{ color: "white" }}>
            {logo.name}
          </Typography>
        )} */}
        <TextField
          label="Primary Brand Color"
          variant="outlined"
          value={brandColors.primary}
          onChange={(e) =>
            setBrandColors({ ...brandColors, primary: e.target.value })
          }
          fullWidth
          margin="normal"
          InputLabelProps={{
            style: { color: "white" },
          }}
          InputProps={{
            style: { color: "white" },
          }}
        />
        <TextField
          label="Secondary Brand Color"
          variant="outlined"
          value={brandColors.secondary}
          onChange={(e) =>
            setBrandColors({ ...brandColors, secondary: e.target.value })
          }
          fullWidth
          margin="normal"
          InputLabelProps={{
            style: { color: "white" },
          }}
          InputProps={{
            style: { color: "white" },
          }}
        />
        <Button type="submit" variant="contained" style={{ marginTop: "20px" }}>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default Onboarding;
