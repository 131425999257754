import React, { useEffect } from 'react';

import './CustomModal.css';

const CustomModal = (props) => {

    const theme = "light"

    useEffect(() => {
        // When the Modal is opened, set the overflow of the body to hidden
        // We need to do this since the dropdown menu hides the scroll bar when
        // it is open, causing a very strange UI bug
        if (props.isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        // Clean up function
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [props.isOpen]);

    return (
        <div className="modal-section">
            <div className={`modal-container-${theme}`} style={props.customModalContainerStyle}>

                <div className="modal-header-container">

                    <div className="modal-dummy-col"></div>
                    <div className="modal-header-col">
                        <p className={`modal-header-text`} style={props.customTitleStyle}>{props.title}</p>
                    </div>
                    <div className="modal-dummy-col">
                        <div className="modal-close-button" onClick={() => props.closeModal()}>
                            <p className={`modal-close-x-${theme}`}>&#215;</p>
                        </div>
                    </div>

                </div>

                <div className="modal-main-content-container" style={props.customMainColStyle}>
                    <div className="modal-content-container">
                        {props.children}
                    </div>
                </div>

            </div>
        </div>
    )

}

export default CustomModal;