import { KnowledgeBaseType } from "../utils/knowledge_bases.ts";

const { REACT_APP_AI_URL } = process.env;

export async function createKnowledgeBase(
  authToken: string,
  title: string,
  language_code: string = "en",
  type: string = "unstructured",
  supp_id: string
) {
  const response = await fetch(`${REACT_APP_AI_URL}/knowledge_bases`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken,
    },
    body: JSON.stringify({
      title,
      language_code,
      type,
      supp_id,
      // description,
    }),
  });

  const resData = await response.json();
  console.log("createKnowledgeBase", resData);
  return [resData, response.status];
}

export async function createKnowledgeBases(authToken: string, userId: string) {
  const knowledge_bases_types = KnowledgeBaseType;
  const new_knowledge_bases: any = [];
  for (const type in knowledge_bases_types) {
    const title = knowledge_bases_types[type];
    const supp_id = userId;
    const [resData, status] = await createKnowledgeBase(
      authToken,
      title,
      "en",
      "unstructured",
      supp_id
    );
    if (status === 201 || status === 200) {
      new_knowledge_bases.push({
        name: title,
        id: resData.id,
      })
    }
  }
  return new_knowledge_bases;
}
