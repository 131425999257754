const { REACT_APP_API_URL, REACT_APP_BACKEND_BASE_URL } = process.env;
export async function sendLoginInfo(username, password) {
  try {
    const response = await fetch(`${REACT_APP_API_URL}/auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    });

    const resData = await response.json();
    console.log("resData", resData);
    const status = response.status;

    return [resData, status];
  } catch (error) {
    console.log("error", error);
    return [null, 500];
  }
}
export async function sendToken(token) {
  console.log(REACT_APP_BACKEND_BASE_URL);
  try {
    const response = await fetch(`${REACT_APP_BACKEND_BASE_URL}/getUserDetails/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        refToken: token,
      },
    });
    const resData = await response.json();
    const status = response.status;

    return [resData, status];
  } catch (error) {
    console.log("error", error);
    return [null, 500];
  }
}
