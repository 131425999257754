import React, { useEffect, useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { IconContext } from "react-icons";
import { AiOutlineLink } from "react-icons/ai";
import { BiLeftArrowAlt } from "react-icons/bi";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { MdOutlineFileUpload } from "react-icons/md";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { CustomButton } from "../components/CustomButton";
import CustomModal from "../components/CustomModal";
import CustomTextInput from "../components/CustomTextInput";
import { NewFileList } from "../components/FileList";

import { useAppDispatch, useAppSelector } from "../hooks.ts";
import { useFileUploadHandler } from "../logic/fileUpload";
import { useKnowledgeBaseHandler } from "../logic/knowledge";

import * as knowledgeBaseServices from "../services/knowledge";
// import * as knowledgeBaseActions from '../store/actions/knowledge';

import BouncingDots from "../components/Loaders/BouncingDots/BouncingDots.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import { KnowledgeBaseType } from "../utils/knowledge_bases.ts";
import "./DocumentUpload.css";

const DocumentUpload = () => {
  const { knowledgeBaseId } = useParams();
  const history = useHistory();
  // const dispatch = useDispatch();
  const dispatch = useAppDispatch();
  const inputFile = useRef(null);
  const idToken = sessionStorage.getItem("idToken");
  const savedKnowledgeBaseFiles = useSelector(
    (state) => state.knowledge.knowledgeBaseFiles
  );

  console.log("knowledgeBaseId", knowledgeBaseId)

  const data = useAppSelector((state) => state.auth.user);

  /*const knowledgeBaseIds = {
    compliance: data.knowledge_bases.filter(
      (kb) => kb.name === KnowledgeBaseType.Compliance
    )[0].id,
    "brand-personalization": data.knowledge_bases.filter(
      (kb) => kb.name === KnowledgeBaseType.BrandPersonalization
    )[0].id,
    "document-analysis": data.knowledge_bases.filter(
      (kb) => kb.name === KnowledgeBaseType.DocumentAnalyst
    )[0].id,
    "advisor-assist": data.knowledge_bases.filter(
      (kb) => kb.name === KnowledgeBaseType.AdvisorAssist
    )[0].id,
  };*/
  const kbNames = {
    compliance: "Compliance",
    "brand-personalization": "Brand Personalization",
    "document-analysis": "Document Analysis",
    "advisor-assist": "Advisor Assist",
  };
  //const knowledgeBaseId = knowledgeBaseIds[title];

  const {
    handleFileUpload,
    handleURLUpload,
    setAllUploadData,
    resetFileData,
    removeFile,
    fileData,
    fileHashes,
    allUploadData,
  } = useFileUploadHandler();
  const { uploadDocument } = useKnowledgeBaseHandler(knowledgeBaseId);

  const [showFileTypeModal, setShowFileTypeModal] = useState(false);
  const [fileUploadStatuses, setFileUploadStatuses] = useState({});
  const [showURLInput, setShowURLInput] = useState(false);
  const [url, setURL] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [showDoneButton, setShowDoneButton] = useState(false);

  const fileTypes = ["txt", "pdf", "docx", "md", "mp3", "wav", "m4a", "epub"];

  function handleChange(files) {
    if (!showDoneButton) {
      let fileList = [];
      for (let i = 0; i < files.length; i++) {
        fileList.push(files[i]);
      }
      const object = {
        target: {
          files: fileList,
        },
      };
      handleFileUpload(object);
    }
  }

  function onFileUploadButtonClick() {
    // `current` points to the mounted file input element
    inputFile.current.click();
  }

  async function addDocumentsToKnowledgeBase() {
    setIsLoading(true);
    let successfulFileUploads = [];
    let successfulFileUploadNames = [];

    for (let i = 0; i < allUploadData.length; i++) {
      const name = allUploadData[i].name; // Either the filename or the URL
      const type = allUploadData[i].type;

      setFileUploadStatuses((prevState) => ({
        ...prevState,
        [name]: "loading",
      }));
      let status;
      let documentData;
      if (type === "file") {
        [documentData, status] = await uploadDocument(
          name,
          fileData[name],
          fileHashes[name]
        );
      } else {
        [documentData, status] =
          await knowledgeBaseServices.createDocumentFromURL(
            name,
            idToken,
            knowledgeBaseId
          );
      }
      if (status === 200) {
        successfulFileUploads.push(documentData);
        successfulFileUploadNames.push(name);
      } else {
        setFileUploadStatuses((prevState) => ({
          ...prevState,
          [name]: "error",
        }));
      }
    }
    for (let i = 0; i < successfulFileUploadNames.length; i++) {
      let name = successfulFileUploadNames[i];
      setFileUploadStatuses((prevState) => ({
        ...prevState,
        [name]: "success",
      }));
    }

    setIsLoading(false);
    setShowDoneButton(true);
  }

  function resetDocumentUpload() {
    console.log("resetting data inside document upload");
    // Reset this page
    setAllUploadData([]);
    setFileUploadStatuses({});
    setShowDoneButton(false);
    resetFileData();
  }

  useEffect(() => {
    console.log("allUploadData useEffect running inside document upload");
    // For each file, set the file upload status to uploaded
    let newFileUploadStatuses = {};
    allUploadData.forEach((file) => {
      newFileUploadStatuses[file.name] = "uploaded";
    });
    setFileUploadStatuses(newFileUploadStatuses);
  }, [allUploadData]);

  /*useEffect(() => {
        // If the file upload status is 'success', then remove the file from the allUploadData array
        let newUploadData = allUploadData;
        for (let i = 0; i < allUploadData.length; i++) {
            if (fileUploadStatuses[allUploadData[i].name] === "success") {
                newUploadData = newUploadData.filter(file => file.name !== allUploadData[i].name);
            }
        }
        setAllUploadData(newUploadData);
    }, [fileUploadStatuses])*/

  useEffect(() => {
    console.log("fileData useEffect running inside document upload");
    // Set the allFileUpload variable based on the fileData
    // fileData is an object with the following structure:
    // {
    //     name: "file content",
    // }

    for (const filename in fileData) {
      // Make sure this filename isn't already in the allUploadData array
      if (allUploadData.filter((file) => file.name === filename).length > 0) {
        continue;
      }
      setAllUploadData((prevState) => {
        return [
          {
            name: filename,
            type: "file",
            chunk_header: "",
            auto_context: true,
            document_title: "",
            link_to_source: "",
            supp_id: "",
            description: "",
          },
          ...prevState,
        ];
      });
    }
  }, [fileData]);

  /*useEffect(() => {
        console.log("knowledgeBases", knowledgeBases)
        if (knowledgeBaseId !== null && knowledgeBases !== null && knowledgeBases.length > 0) {
            // Get the current knowledge base
            const currentKnowledgeBase = knowledgeBases.filter(knowledgeBase => knowledgeBase.id === knowledgeBaseId)[0];
            setKnowledgeBaseTitle(currentKnowledgeBase.title)
            console.log("currentKnowledgeBase", currentKnowledgeBase)
        }
    }, [knowledgeBaseId, knowledgeBases])*/

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Sidebar currentPage={"personas"} />
      <div className="knowledge-base-section">
        <div className="knowledge-bases-container">
          {/* <Header currentPage={"knowledge"} /> */}

          <div className="file-upload-section">
            <div
              className="file-upload-back-container"
              onClick={() => history.goBack()}
            >
              <IconContext.Provider
                value={{ className: `file-upload-back-arrow-light` }}
              >
                <BiLeftArrowAlt />
              </IconContext.Provider>
              <p className={`file-upload-back-text-light`}>Back</p>
            </div>

            <p
              className={`regular-font-light`}
              style={{
                marginBottom: "20px",
                marginTop: "25px",
                fontSize: "20px",
              }}
            >
              <b>Add data sources</b>
            </p>

            {isLoading && <BouncingDots />}

            {showFileTypeModal && (
              <CustomModal
                title={"Supported file types"}
                customModalContainerStyle={{ width: "300px" }}
                closeModal={() => setShowFileTypeModal(false)}
              >
                <div className="supported-file-type-modal-container">
                  {fileTypes.map((fileType, index) => (
                    <div className="supported-file-type-row" key={index}>
                      <p className="supported-file-type-text">
                        &#x2022; {fileType}
                      </p>
                    </div>
                  ))}
                </div>
              </CustomModal>
            )}

            <FileUploader
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              multiple
              disabled
            >
              <div className={`file-upload-container-light`}>
                <IconContext.Provider
                  value={{
                    size: "32px",
                    color: "#383838",
                  }}
                >
                  <MdOutlineFileUpload />
                </IconContext.Provider>
                <div className="drag-and-drop-row">
                  <p className={`regular-font-light drag-files-text`}>
                    Drag files here
                  </p>
                </div>
              </div>
            </FileUploader>

            <div className="alternate-upload-method-container">
              <div
                className={`alternate-upload-method-choice-light`}
                onClick={onFileUploadButtonClick}
                style={{ marginRight: "15px" }}
              >
                <IconContext.Provider
                  value={{
                    size: "18px",
                    color: "#242424",
                  }}
                >
                  <MdOutlineFileUpload />
                </IconContext.Provider>
                <input
                  onChange={(event) => handleChange(event.target.files)}
                  type="file"
                  accept={fileTypes.map((fileType) => "." + fileType).join(",")}
                  id="file"
                  ref={inputFile}
                  multiple
                  style={{ display: "none" }}
                />
                <p className={`alternate-upload-method-text-light`}>
                  Browse Files
                </p>
              </div>
              <div
                className={`alternate-upload-method-choice-light`}
                onClick={() => setShowURLInput(!showURLInput)}
              >
                <IconContext.Provider
                  value={{
                    size: "18px",
                    color: "#383838",
                  }}
                >
                  <AiOutlineLink />
                </IconContext.Provider>
                <p className={`alternate-upload-method-text-light`}>
                  Enter URL
                </p>

                <IconContext.Provider
                  value={{
                    size: "20px",
                    color: "#8F8F8F",
                  }}
                >
                  {showURLInput ? <FiChevronUp /> : <FiChevronDown />}
                </IconContext.Provider>
              </div>
            </div>

            {showURLInput && (
              <div className="url-input-container">
                <div className="url-input-col">
                  <CustomTextInput
                    customTextAreaStyle={{
                      height: "41px",
                      paddingTop: "11px",
                      paddingBottom: "11px",
                      marginTop: "3px",
                    }}
                    placeholderText={"Enter URL"}
                    text={url}
                    sendDataToParent={(data) => setURL(data)}
                  />
                </div>
                <div className="url-input-button-col">
                  <CustomButton
                    buttonText={"Add"}
                    customStyle={{ paddingTop: "9px", paddingBottom: "9px" }}
                    onClick={() => {
                      handleURLUpload(url, true);
                      setURL("");
                    }}
                  />
                </div>
              </div>
            )}

            <div
              className={
                showURLInput
                  ? "chosen-files-container chosen-files-container-shortened"
                  : "chosen-files-container"
              }
            >
              <NewFileList
                files={allUploadData}
                fileUploadStatuses={fileUploadStatuses}
                removeFile={(file) => removeFile(file.name)}
              />
            </div>

            <div className="add-more-documents-container">
              <CustomButton
                buttonText={
                  showDoneButton
                    ? "Done"
                    : `Upload ${allUploadData.length} data sources`
                }
                disabled={allUploadData.length === 0 || isLoading}
                onClick={() => {
                  showDoneButton
                    ? history.goBack()
                    : addDocumentsToKnowledgeBase();
                }}
              />
              <p
                className={`add-more-documents-text${
                  showDoneButton ? "" : "-disabled"
                }`}
                onClick={() => resetDocumentUpload()}
              >
                Add more data sources
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentUpload;
