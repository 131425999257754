export function removeSecondsFromDate(date) {

    // Formatted date can come in as a few different formats. Here are some examples:
    // 2/9/2021, 10:00:19 PM
    // 20/12/2012, 03:00:00
    // 2012. 12. 20. 오전 3:00:00
    // 24/12/20 12:00:00
    // 20/12/2012 11.00.00

    // We just want to remove the seconds from the date. We can't rely on splitting on the ":" because
    // the date can be in different formats

    // First, check if the last 2 characters are PM or AM. If so, we can remove the last 3 characters
    let formattedDate = date;
    if (formattedDate.slice(-2) === "PM" || formattedDate.slice(-2) === "AM") {
        formattedDate = formattedDate.slice(0, -6) + " " + formattedDate.slice(-2);
    } else {
        // Now just remove the last 3 characters
        formattedDate = formattedDate.slice(0, -3);
    }
    
    return formattedDate;

}

export function formatEpochToDate(epoch, omitSeconds=false) {
    // Format an epoch to a date string
    // Epoch is in seconds from 1970
    const date = new Date(epoch * 1000);

    // Format it for Netherlands
    let formattedDate = date.toLocaleString();
    if (omitSeconds) {
        formattedDate = removeSecondsFromDate(formattedDate)
    }
    return formattedDate;
}

export function formatTimestampForDisplay(timestamp) {
    // timestamp is in the format 2023-02-09T22:00:19.449660. We want to format it to Feb 9, 2023
    let date = new Date(timestamp);
    let month = date.toLocaleString('default', { month: 'short' });
    let day = date.getDate();
    let year = date.getFullYear();
    return month + " " + day + ", " + year;
}

export function getStartEndTimestamps(month, year) {
    // month will come in as the long date name (e.g. "January")
    // We want the timestamp of the first day of the month (at 12:00:00 AM) and the last day of the month (at 11:59:59 PM)
    
    if (typeof(year) !== 'string') {
        year = year.toString();
    }
    const monthDate = new Date(month + " 1, " + year);
    const startTimestamp = monthDate.getTime() / 1000;

    const nextMonthTimestamp = startTimestamp + (86400 * 45);
    const nextMonthDate = new Date(nextMonthTimestamp * 1000);
    const nextMonth = nextMonthDate.toLocaleString('en-US', { month: 'long' });
    const nextMonthYear = nextMonthDate.getFullYear();
    const nextMonthFullDate = new Date(nextMonth + " 1, " + nextMonthYear.toString());
    // Convert nextMonthFullDate to an epoch timestamp
    const endTimestamp = (nextMonthFullDate.getTime() / 1000) - 1; // Subtract 1 second to get the last second of the previous month
    return [startTimestamp, endTimestamp]
}