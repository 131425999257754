import React, { useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { IoIosCloseCircle } from "react-icons/io";
import { useSelector } from "react-redux";

import { CustomButton } from "../components/CustomButton";
import CustomTextArea from "../components/CustomTextArea";
import { InfoIconWithText } from "../components/InfoIcon";
import Markdown from "../components/Markdown";
import { LinearProgressWithLabel } from '../components/Loaders/CustomProgress';

import { useDocumentReviewHandler } from "../logic/documentReview";
import { useFileUploadHandler } from "../logic/fileUpload";
import { useWorkflowHandler } from "../logic/workflow.js";

import BouncingDots from "../components/Loaders/BouncingDots/BouncingDots";
import Sidebar from "../components/Sidebar/Sidebar.js";
import { useAppSelector } from "../hooks.ts";
import { getDocumentStatus } from "../services/fileUpload.js";
import "./DocumentAnalysis.css";
import * as knowledgeBaseServices from "../services/knowledge";
import CustomDropdown from "../components/CustomDropdown.js";

const DocumentAnalysis = () => {

  let didRequestWorkflows = false;

  const token = sessionStorage.getItem('token');

  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [showNoDocumentMessage, setShowNoDocumentMessage] = useState(false);
  const [knowledgeBaseId, setKnowledgeBaseId] = useState(null);

  const idToken = useAppSelector((state) => state.auth.idToken);

  const { listWorkflows, workflows } = useWorkflowHandler(token);


  // Check the redux store for saved review info
  const readingInstructionsFromRedux = useSelector(
    (state) => state.documentAnalysis.readingInstructions
  );
  const reviewInstructionsFromRedux = useSelector(
    (state) => state.documentAnalysis.reviewInstructions
  );
  const finalReviewFromRedux = useSelector(
    (state) => state.documentAnalysis.finalReview
  );

  const [isLoading, setIsLoading] = useState(false);

  const [readingInstructions, setReadingInstructions] = useState("");
  const [reviewInstructions, setReviewInstructions] = useState("");

  const {
    runDocumentAnalysis,
    progress,
    showProgress,
    resultData,
    setResultData,
  } = useDocumentReviewHandler(setIsLoading);

  async function handleWorkflowChange(workflowID) {
    console.log("workflowID", workflowID)
    setIsLoading(true);
    setSelectedWorkflow(workflowID);
    // Get the documents for this kb id
    const workflowData = workflows.filter((workflow) => workflow._id === workflowID)[0];
    const kbID = workflowData.knowledge_base_id;
    setKnowledgeBaseId(kbID);

    const [response, status] = await knowledgeBaseServices.getAllDocumentsInKnowledgeBase(idToken, kbID);
    console.log("response", response)
    const documentData = response.documents;

    let allDocumentData = [];
    // Append "all" and "All" to the front of the list
    if (documentData.length > 0) {
      allDocumentData = [{ id: "all", file_name: "All" }];
      allDocumentData = allDocumentData.concat(documentData);
      setShowNoDocumentMessage(false);
    } else {
      setShowNoDocumentMessage(true);
    }

    setDocuments(allDocumentData);
    if (documentData.length > 0) {
      setSelectedDocument(documentData[0].id);
    }

    setIsLoading(false);
  }


  async function startDocumentReview() {
    setIsLoading(true);
    runDocumentAnalysis(
      idToken,
      knowledgeBaseId,
      selectedDocument,
      readingInstructions,
      reviewInstructions
    );
  }


  useEffect(() => {
    // If the readingInstructionsFromRedux is not null, set the readingInstructions state variable
    if (readingInstructionsFromRedux !== null) {
      setReadingInstructions(readingInstructionsFromRedux);
    }
  }, [readingInstructionsFromRedux]);

  useEffect(() => {
    // If the reviewInstructionsFromRedux is not null, set the reviewInstructions state variable
    if (reviewInstructionsFromRedux !== null) {
      setReviewInstructions(reviewInstructionsFromRedux);
    }
  }, [reviewInstructionsFromRedux]);

  useEffect(() => {
    // If the finalReviewFromRedux is not null, set the finalReview state variable
    if (finalReviewFromRedux !== null) {
      setResultData(finalReviewFromRedux);
    }
  }, [finalReviewFromRedux]);


  useEffect(() => {
    //if (workflows.length === 0 && token !== null && !didRequestWorkflows) {
    console.log("didRequestWorkflows", didRequestWorkflows)
    didRequestWorkflows = true;
    listWorkflows();
    //}
  }, []);

  useEffect(() => {
    if (workflows.length > 0) {
      setSelectedWorkflow(workflows[0]._id);
    }
  }, [workflows])

  useEffect(() => {
    const workflowID = selectedWorkflow;
    if (workflowID !== null) {
      handleWorkflowChange(workflowID);
    }
  }, [selectedWorkflow])

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Sidebar currentPage={"document analysis"} />

      <div className="compliance-review-section">

        <div className="compliance-review-container">
          {isLoading && <BouncingDots />}

          <div className="compliance-review-content-container">
            <p className="compliance-review-header-text">Document Analysis</p>

            <div style={{ display: "flex" }}>
              <div className="document-upload-container">
                <InfoIconWithText
                  text={"Persona"}
                  helpBoxTitle={"Persona"}
                  helpBoxText={
                    "Select the persona you want to select a document(s) from."
                  }
                />

                <CustomDropdown
                  valueList={workflows.map((workflow) => workflow._id)}
                  textList={workflows.map((workflow) => workflow.name)}
                  value={selectedWorkflow}
                  sendDataToParent={(val) => setSelectedWorkflow(val)}
                  customStyle={{ width: "200px", maxHeight: "400px" }}
                />
              </div>

              <div className="document-upload-container">
                <InfoIconWithText
                  text={"Document"}
                  helpBoxTitle={"Document"}
                  helpBoxText={
                    "Select the document(s) you want to select for review."
                  }
                />

                <CustomDropdown
                  valueList={documents.map((workflow) => workflow.id)}
                  textList={documents.map((workflow) => workflow.file_name)}
                  value={selectedDocument}
                  sendDataToParent={(val) => setSelectedDocument(val)}
                  customStyle={{ width: "200px", maxHeight: "400px" }}
                />
              </div>
            </div>

            {showNoDocumentMessage &&
              <p className="valid-file-types-text">There are no documents for this persona</p>
            }


            <div>
              <div className="document-review-input-container">
                <InfoIconWithText
                  text={"Active reading instructions"}
                  helpBoxTitle={"Active reading instructions"}
                  helpBoxText={
                    "Detailed instructions about what the model should be paying attention to when reading through the documents."
                  }
                />
                <CustomTextArea
                  placeholderText={`Tell the AI what to pay attention to while reading through the document(s)`}
                  text={readingInstructions}
                  sendDataToParent={(text) => setReadingInstructions(text)}
                  rows={7}
                  useAutoResize={false}
                />
              </div>
            </div>

            <div style={{ marginTop: "20px" }}>
              <div className="document-review-input-container">
                <InfoIconWithText
                  text={"Final review instructions (optional)"}
                  helpBoxTitle={"Final review instructions"}
                  helpBoxText={
                    "Detailed instructions about what the final output should look like."
                  }
                />
                <CustomTextArea
                  placeholderText={`Tell the AI what to write in the final review`}
                  text={reviewInstructions}
                  sendDataToParent={(text) => setReviewInstructions(text)}
                  rows={7}
                  useAutoResize={false}
                />
              </div>
            </div>

            <div className="submit-review-button-container">
              <CustomButton
                onClick={() => startDocumentReview()}
                buttonText={"Submit Review"}
                disabled={
                  readingInstructions === "" ||
                  isLoading ||
                  showNoDocumentMessage
                }
              />
            </div>
          </div>

          {showProgress &&
            <div className="document-review-progress-container">
              <p className={`regular-font-light`}>Reading documents</p>
              <LinearProgressWithLabel value={progress} />
            </div>
          }

          <div className="compliance-review-result-container">
            {resultData !== null && !showProgress && (
              <div className="document-review-result-container">
                <div className="document-review-result-text">
                  <p className={`semi-bold-font-light`}>Final review</p>
                </div>
                <Markdown
                  markdownContent={resultData}
                  className={`regular-font-light document-review-result-text`}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentAnalysis;
