import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { CustomButton } from "../components/CustomButton";
import CustomDropdown from "../components/CustomDropdown";
import CustomTextArea from "../components/CustomTextArea";
import Markdown from "../components/Markdown";

import { LinearProgressWithLabel } from '../components/Loaders/CustomProgress';
import { useDocumentReviewHandler } from "../logic/documentReview";
import { getDocumentStatus, uploadText } from "../services/fileUpload";

import BouncingDots from "../components/Loaders/BouncingDots/BouncingDots";
import Sidebar from "../components/Sidebar/Sidebar.js";
import { useAppSelector } from "../hooks.ts";
import { useAuthExpiry } from "../logic/auth";
import "./Compliance.css";

const Compliance = () => {

  useAuthExpiry();
  
  const [isLoading, setIsLoading] = useState(false);
  const [messageForReview, setMessageForReview] = useState("");
  const [useCase, setUseCase] = useState("ria");
  const [showUseCaseDropdown, setShowUseCaseDropdown] = useState(false);

  const { reviewDocument, progress, showProgress, resultData } = useDocumentReviewHandler(setIsLoading);

  // Get the message for review from redux
  const messageForReviewFromRedux = useSelector(
    (state) => state.compliance.messageForReview
  );

  // Get the api key and secret from session storage
  let data = sessionStorage.getItem("data");
  if (data === undefined || data === null) {
    data = null;
  } else {
    data = JSON.parse(data);
  }
  const apiKey = data !== null ? data["api_key"] : null;
  const apiSecret = data !== null ? data["api_secret"] : null;

  // This is the KB that we upload new documents to
  const kbID = "239aa451-cc85-447a-aa1d-4464d2f813a5";
  // const idToken = data["idToken"];
  const idToken = useAppSelector((state) => state.auth.idToken);

  // useEffect(() => {
  //   dispatch(logout());
  // }, [apiKey]);

  // const chatConfig = data !== null ? JSON.parse(data["chat_config"]) : {};
  const defaultKBs = [
    "49fb1557-dc03-49a7-9f31-cdaf059fb43b",
    "64be5a17-d2b0-4ca8-951a-eeced0a0b9d0",
    "7df1f6b3-7f22-419e-b1bf-faaad7acbe1f",
  ];
  const chatConfig = {
    knowledge_base_ids: defaultKBs,
  };

  async function pollForDocumentStatus(resData) {
    const authToken = btoa(
      unescape(encodeURIComponent(`${apiKey}:${apiSecret}`))
    );

    // Send the document for review
    const documentId = resData["id"];
    console.log("documentId", documentId);
    // Sleep for 1 second
    await new Promise((r) => setTimeout(r, 1000));
    // Get the document status
    let [newResData, newStatus] = await getDocumentStatus(
      idToken,
      kbID,
      documentId
    );
    console.log("newResData", newResData);

    let documentStatus = newResData["vectorization_status"];

    let pollCount = 0;
    while (documentStatus !== "COMPLETE" && pollCount < 120) {
      // Sleep for 1 second
      await new Promise((r) => setTimeout(r, 1000));
      [newResData, newStatus] = await getDocumentStatus(
        idToken,
        kbID,
        documentId
      );
      console.log("newResData", newResData);

      let newDocumentStatus = newResData["vectorization_status"];
      documentStatus = newDocumentStatus;
      pollCount += 1;
    }

    if (documentStatus === "COMPLETE") {
      // Start the document review now
      console.log("Starting document review");
      reviewDocument(kbID, documentId, useCase, idToken, authToken);
    } else {
      alert("Error uploading text");
      setIsLoading(false);
    }
  }

  // async function handleDuplicateDocument(resData) {
  //   const documentId = resData.error["existing_document_id"];
  //   console.log("documentId", documentId);
  //   const authToken = btoa(
  //     unescape(encodeURIComponent(`${apiKey}:${apiSecret}`))
  //   );
  //   reviewDocument(kbID, documentId, useCase, idToken, authToken);
  // }

  async function startDocumentReview() {
    setIsLoading(true);
    //const kbID = chatConfig["knowledge_base_ids"][0]
    const [resData, status] = await uploadText(messageForReview, kbID, idToken);

    if (status === 200 && !resData.error) {
      pollForDocumentStatus(resData);
    } else if (resData.error) {

      if (
        resData.existing_document_id !== undefined &&
        resData.existing_document_id !== null
      ) {
        console.log("Document already exists");
        const documentId = resData.existing_document_id;
        console.log("documentId", documentId);
        const authToken = btoa(
          unescape(encodeURIComponent(`${apiKey}:${apiSecret}`))
        );
        reviewDocument(kbID, documentId, useCase, idToken, authToken);
      }
    } else {
      alert("Error uploading text");
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (
      messageForReviewFromRedux !== null &&
      messageForReviewFromRedux !== undefined
    ) {
      setMessageForReview(messageForReviewFromRedux);
    }
  }, [messageForReviewFromRedux]);

  useEffect(() => {
    // Check the kb ids in the chat config
    if (chatConfig !== null && chatConfig !== undefined) {
      const kbIds = chatConfig["knowledge_base_ids"];
      if (kbIds.length > 1) {
        setShowUseCaseDropdown(true);
      } else {
        // Define the use case based on the kb id
        switch (kbIds[0]) {
          case "64be5a17-d2b0-4ca8-951a-eeced0a0b9d0":
            setUseCase("ria");
            break;
          case "49fb1557-dc03-49a7-9f31-cdaf059fb43b":
            setUseCase("finra");
            break;
          case "7df1f6b3-7f22-419e-b1bf-faaad7acbe1f":
            setUseCase("hybrid");
            break;
        }
      }
    }
  }, [chatConfig]);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Sidebar currentPage={"compliance"} />
      <div className="compliance-review-section">
        {/* <Header darkMode={false} currentPage={"compliance"} /> */}
        <div className="compliance-review-container">
          {isLoading && <BouncingDots />}

          <div className="compliance-review-content-container">
            <p className="compliance-review-header-text">Compliance Review</p>

            {showUseCaseDropdown && (
              <div className="compliance-review-use-case-container">
                <p className="use-case-header-text">Use case:</p>
                <CustomDropdown
                  valueList={["ria", "finra", "hybrid"]}
                  textList={["RIA", "FINRA", "Hybrid"]}
                  value={useCase}
                  sendDataToParent={(val) => setUseCase(val)}
                  customStyle={{ width: "200px", maxHeight: "400px" }}
                />
              </div>
            )}

            <p className="message-for-review-header-text">Message to review:</p>

            <CustomTextArea
              text={messageForReview}
              sendDataToParent={(data) => setMessageForReview(data)}
              rows={3}
            />
            <div className="submit-review-button-container">
              <CustomButton
                onClick={() => startDocumentReview()}
                buttonText={"Review for Compliance"}
                disabled={messageForReview === "" || isLoading}
              />
            </div>
          </div>

          {showProgress &&
            <div className="document-review-progress-container">
              <p className={`regular-font-light`}>Reviewing for compliance</p>
              <LinearProgressWithLabel value={progress} />
            </div>
          }

          <div className="compliance-review-result-container">
            {resultData != null && !showProgress && (
              <div className="document-review-result-container">
                <div className="document-review-result-text">
                  <p className={`semi-bold-font-light`}>Final review</p>
                </div>
                <Markdown
                  markdownContent={resultData}
                  className={`regular-font-light document-review-result-text`}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Compliance;
