import React from 'react';
import { IconContext } from 'react-icons';
import { BsArrowRight } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md';
import Checkbox from '@mui/material/Checkbox';

import './ClickableRow.css';


export const ClickableRow = ({ onClick, numCols, allText, flexSizing, showArrow = true }) => {

    const theme = {theme: "dark"};

    return (
        <div className={`clickable-row-section-${theme.theme}`}
            onClick={onClick}
        >
            <div className="clickable-row-container">

                {allText.map((text, index) =>
                    <div key={index} className="clickable-row-col" style={{ flex: flexSizing[index] }}>
                        <p className={`medium-font-${theme.theme} clickable-row-text`}>
                            {text}
                        </p>
                    </div>
                )}

                {showArrow &&
                    <div className="clickable-row-arrow-col" style={{ flex: flexSizing[numCols - 1] }}>
                        <IconContext.Provider value={{ className: `clickable-row-arrow-icon-${theme.theme}` }}>
                            <div>
                                <BsArrowRight />
                            </div>
                        </IconContext.Provider>
                    </div>
                }

            </div>
        </div>
    )

}


// Same styling, but the only part that will be clickable is the icon
export const ClickableRowWithDeleteIcon = ({ onClick, numCols, allText, flexSizing }) => {

    const theme = {theme: "light"};

    return (
        <div className={`clickable-row-section-delete-${theme.theme}`}>
            <div className="clickable-row-container" style={{cursor: "default"}}>

                {allText.map((text, index) =>
                    <div key={index} className="clickable-row-col" style={{ flex: flexSizing[index] }}>
                        <p className={`medium-font-${theme.theme} clickable-row-text`}>{text}</p>
                    </div>
                )}

                <div className="clickable-row-arrow-col" style={{ flex: flexSizing[numCols - 1] }}>
                    <IconContext.Provider value={{ className: `clickable-row-delete-icon-${theme.theme}` }}>
                        <div onClick={onClick}>
                            <MdDelete />
                        </div>
                    </IconContext.Provider>
                </div>

            </div>
        </div>
    )

}


export const ClickableContainer = ({ onClick, children, customContainerStyle }) => {

    const theme = {theme: "light"};

    return (
        <div className={`clickable-container-section-${theme.theme}`} onClick={onClick} style={customContainerStyle}>
            <div className="clickable-container-container">
                {children}
            </div>
        </div>
    )

}


export const ClickableRowWithCheckbox = ({ onClick, onCheckboxClick, isChecked, numCols, allText, flexSizing, showArrow = true }) => {

    const theme = {theme: "light"};

    return (

        <div className={`clickable-row-section-${theme.theme}`}>

            <div className="clickable-row-checkbox-col">
                <Checkbox
                    checked={isChecked}
                    onChange={() => onCheckboxClick()} />
            </div>

            <div className="clickable-row-container" onClick={onClick}>

                {allText.map((text, index) =>
                    <div key={index} className="clickable-row-col" style={{ flex: flexSizing[index] }}>
                        <p className={`medium-font-${theme.theme} clickable-row-text clickable-row-text-checkbox`}>
                            {text}
                        </p>
                    </div>
                )}

                {showArrow &&
                    <div className="clickable-row-arrow-col" style={{ flex: flexSizing[numCols - 1] }}>
                        <IconContext.Provider value={{ className: `clickable-row-arrow-icon-${theme.theme}` }}>
                            <div>
                                <BsArrowRight />
                            </div>
                        </IconContext.Provider>
                    </div>
                }

            </div>
        </div>


    )

}
