import { convertToValidFilenameWithExtension } from "./fileUpload";

const { REACT_APP_AI_URL } = process.env;

export async function getKnowledgeBaseFiles(idToken, kbID) {

    const response = await fetch(`${REACT_APP_AI_URL}/list_documents`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${idToken}`
        },
        body: JSON.stringify({knowledge_base_id: kbID})
    });
    const data = await response.json();
    return [data, response.status];

}




// export async function getKnowledgeBaseFiles(idToken, kbID) {

//     const response = await fetch(`${REACT_APP_AI_URL}/list_documents`, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             "Authorization": `Bearer ${idToken}`
//         },
//         body: JSON.stringify({knowledge_base_id: kbID})
//     });
//     const data = await response.json();
//     return [data, response.status];

// }


export async function getSingleKnowledgeBase(idToken, knowledgeBaseId) {
    const response = await fetch(`${REACT_APP_AI_URL}/knowledge_bases/${knowledgeBaseId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${idToken}`
        }
    });
    const data = await response.json();
    return [data, response.status];
}



/*export async function requestSignedURL(idToken, filename, knowledgeBaseId, md5Hash, autoContext, chunkHeader, documentTitle, linkToSource, suppId, description, method = "POST") {

    let payload = {
        filename: filename,
        method: 'PUT',
        encoded_md5: md5Hash,
        auto_context: autoContext,
        title: documentTitle,
        link_to_source: linkToSource,
        supp_id: suppId,
        description: description,
    }

    if (chunkHeader !== "" && !autoContext) {
        payload['chunk_header'] = chunkHeader;
    }

    const response = await fetch(`${REACT_APP_AI_URL}/knowledge_bases/${knowledgeBaseId}/documents/request_signed_file_url`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            "Authorization": idToken
        },
        body: JSON.stringify(payload)
    });
    const data = await response.json();
    return [data, response.status];
}*/


export async function requestSignedURL(
    idToken,
    filename,
    knowledgeBaseId,
    md5Hash
  ) {
    // Filename CoWriter (1).pdf is not valid. Must be less than 256 characters and only contain alphanumeric characters, spaces, hyphens, and underscores.
  
    const newFilename = convertToValidFilenameWithExtension(filename);
  
    let payload = {
      filename: newFilename,
      encoded_md5: md5Hash,
      auto_context: true,
      method: "PUT",
    };
  
    const url = `${REACT_APP_AI_URL}/knowledge_bases/${knowledgeBaseId}/documents/request_signed_file_url`;
  
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    return [data, response.status];
  }


export async function uploadDocument(url, fileHash, fileData) {

    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-MD5': fileHash
        },
        body: fileData
    });
    console.log("upload data", response.status)
    return response.status;

}


export async function createDocumentFromURL(url, idToken, knowledgeBaseId) {
    const payload = {
        url: url,
        auto_context: true,
    }

    console.log("payload", payload)
    const response = await fetch(`${REACT_APP_AI_URL}/knowledge_bases/${knowledgeBaseId}/documents/url`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${idToken}`
        },
        body: JSON.stringify(payload)
    });
    const resData = await response.json();
    console.log("create document from url", resData)
    return [resData, response.status];
}


export async function deleteDocument(idToken, knowledgeBaseId, documentId) {
    // https://api.superpowered.ai/v1/knowledge_bases/{knowledge_base_id}/documents/{document_id}

    const response = await fetch(`${REACT_APP_AI_URL}/knowledge_bases/${knowledgeBaseId}/documents/${documentId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${idToken}`
        },
        // body: JSON.stringify({ knowledgeBaseId: knowledgeBaseId, documentId: documentId })
    });
    const status = response.status;
    return status;
    
}


export async function getDocumentById(idToken, knowledgeBaseId, documentId) {

    let url = `${REACT_APP_AI_URL}/knowledge_bases/${knowledgeBaseId}/documents/${documentId}`;
    const payload = {
        knowledgeBaseId: knowledgeBaseId,
        documentId: documentId,
        include_content: false
    }

    const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        // body: JSON.stringify(payload),
      });
    

    const resData = await response.json();
    console.log("resData", resData)
    const status = response.status;

    return [resData, status];
}


export async function getAllDocumentsInKnowledgeBase(idToken, knowledgeBaseId, nextPageToken = null) {
    let url = `${REACT_APP_AI_URL}/knowledge_bases/${knowledgeBaseId}/documents`;
    if (nextPageToken !== null) {
        url += `?next_page_token=${nextPageToken}`;
    }

    console.log("url", url)

    // https://api.superpowered.ai/v1/knowledge_bases/{knowledge_base_id}/documents
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${idToken}`
        },
        // body: JSON.stringify({ knowledgeBaseId: knowledgeBaseId })
    });
    const data = await response.json();
    console.log("data", data)
    return [data, response.status];
}

