import React from "react";
import { Provider } from "react-redux";
import MainNavigator from "./navigation/MainNavigator";

import "./App.css";
import "./styles/generalStyles.css";
import { store } from "./store.ts";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { ToastProvider } from "./context/ToastContext";
import { Auth0Provider } from "@auth0/auth0-react";





function App() {
  let persistor = persistStore(store);

  return (
    <Auth0Provider
      // domain={process.env.REACT_APP_AUTH0_DOMAIN}
      domain="auth0.advisorx.ai"
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE
      }}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ToastProvider>
            <MainNavigator />
          </ToastProvider>
        </PersistGate>
      </Provider>
    </Auth0Provider>
  );
}

export default App;
