import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { lucario } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { BiCopy } from 'react-icons/bi';
import { IconContext } from 'react-icons';
import rehypeRaw from "rehype-raw";

import './Markdown.css';

const Markdown = (props) => {

    const [showCopiedText, setShowCopiedText] = useState(false);
    const showCopyIcon = (props.hideCopyIcon === undefined || props.hideCopyIcon !== true);
    const linkClassName = props.linkClassName == undefined ? "" : props.linkClassName;

    const customImageStyle = props.customImageStyle == undefined ? {} : props.customImageStyle;

    function getMarkdownText() {
        const lines = props.markdownContent.split('\n');
        let code = '';
        let inCodeBlock = false;
        for (let line of lines) {
            if (line.startsWith('```')) {
                inCodeBlock = !inCodeBlock;
            } else if (inCodeBlock) {
                code += line + '\n';
            }
        }
        // Copy the code to clipboard
        navigator.clipboard.writeText(code);

        // Show the copied text for 3 seconds
        setShowCopiedText(true);
        setTimeout(() => {
            setShowCopiedText(false);
        }, 3000);

    }

    return (
        <ReactMarkdown
            children={props.markdownContent}
            className={props.className}
            rehypePlugins={[rehypeRaw]}
            components={{
                code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || '')
                    return !inline && match ? (
                        <div>
                            {showCopiedText && <p className="copied-text">copied</p>}
                            {showCopyIcon && <div className="copy-markdown-icon-container">
                                <IconContext.Provider
                                    value={{
                                        size: "20px",
                                        color: "#e8e8e8",
                                    }}>
                                    <div className="copy-markdown-icon" onClick={() => getMarkdownText()}>
                                        <BiCopy />
                                    </div>
                                </IconContext.Provider>
                            </div>}
                            <SyntaxHighlighter
                                children={String(children).replace(/\n$/, '')}
                                language={match[1]}
                                style={lucario}
                                customStyle={{ fontSize: "14px" }}
                                PreTag="div"
                                {...props}
                            />
                        </div>
                    ) : (
                        <code className={className} {...props}>
                            {children}
                        </code>
                    )
                },
                img({ node, ...props }) {
                    return (
                        <div className="blog-post-image-container">
                            <img loading="lazy" {...props} className="blog-post-main-section-image" style={customImageStyle} />
                        </div>
                    )
                },
                iframe: ({ node, ...props }) => (
                    <div className="blog-post-video-container">
                        <iframe {...props} className="blog-post-video" />
                    </div>
                ),
                a: ({ href, children }) => (
                    <a className={linkClassName} href={href} target="_blank" rel="noopener noreferrer">
                        {children}
                    </a>
                ),
                b: ({ children }) => (
                    <b>{children}</b>
                ),
                smallp: ({ children }) => (
                    <p style={{fontSize: "12px", lineHeight: "18px"}}>{children}</p>
                ),
                container: ({ children }) => (
                    <div style={{marginTop: "30px"}}>{children}</div>
                ),
            }}
        />
    )

}

export default Markdown;