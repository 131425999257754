import React, { useCallback, useEffect, useState } from "react";
import { CustomButton } from "../components/CustomButton.js";
import CustomColorInput from "../components/CustomColorInput.js";
import CustomDropdown from "../components/CustomDropdown.js";
import CustomTextArea from "../components/CustomTextArea.js";
import BouncingDots from "../components/Loaders/BouncingDots/BouncingDots.js";
import CircularProgressWithLabel from "../components/Loaders/CustomProgress.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import { setBrandColors, setBrandLogo } from "../features/auth/authSlice.ts";
import { useAppDispatch, useAppSelector } from "../hooks.ts";
import { useAuthExpiry } from "../logic/auth.js";
import { generateChart } from "../logic/chart.js";
import {
  getPresignedUrl,
  updateUserAPI,
  uploadImageAPI,
} from "../logic/user.js";
import "./Chart.css";
import RenderChart from "./RenderChart.js";
import { debounce } from "@mui/material";
import { useSelector } from "react-redux";
import ImageLogoInput from "../components/CustomImageUpload.js";

const Chart = () => {
  useAuthExpiry();
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [messageForReview, setMessageForReview] = useState("");
  const [chartType, setChartType] = useState("any");
  const [resultChartType, setResultChartType] = useState("any");

  const [chartData, setChartData] = useState({});
  const messageForReviewFromRedux = useSelector(
    (state) => state.chart.messageForChart
  );
  // onImageSelect
  const onImageSelect = async (image) => {
    if (
      image &&
      image !== null &&
      image !== undefined &&
      image.name &&
      image.type
    ) {
      const response = await getPresignedUrl(image.name, image.type);
      console.log(response);

      await uploadImageAPI(response, image);
      if (response === undefined) {
        alert("Error please try again");
        return;
      }
      const data = {
        brand_logo: response.split("?")[0],
      };
      setLogo(response.split("?")[0]);
      dispatch(setBrandLogo(data.brand_logo));
      await updateUserAPI(data);
    } else {
      const data = {
        brand_logo: "",
      };
      setLogo("");
      await updateUserAPI(data);
      dispatch(setBrandLogo(data.brand_logo));
    }
  };

  useEffect(() => {
    if (
      messageForReviewFromRedux !== null &&
      messageForReviewFromRedux !== undefined
    ) {
      setMessageForReview(messageForReviewFromRedux);
    }
  }, [messageForReviewFromRedux]);

  const [chartConfig, setChartConfig] = useState({
    // brand_color: userState?.brand_color?.brand_color || "##304bdb",
    brand_color:
      userState && userState.brand_color && userState.brand_color.brand_color
        ? userState.brand_color.brand_color
        : "#304bdb",
    font_color:
      userState && userState.brand_color && userState.brand_color.font_color
        ? userState.brand_color.font_color
        : "#000000",
    background_color:
      userState &&
        userState.brand_color &&
        userState.brand_color.background_color
        ? userState.brand_color.background_color
        : "#ffffff",
  });
  console.log(userState, "userState");
  console.log(userState.brand_logo, "userState.brand_logo");

  const [logo, setLogo] = useState(userState.brand_logo || "");

  // Debounced function to update user API
  const debouncedUpdateUserAPI = useCallback(
    debounce(async (data) => {
      await updateUserAPI(data);
      dispatch(setBrandColors(data.brand_color));
    }, 750),
    []
  );

  const updateColors = useCallback(
    (color, type) => {
      const newChartConfig = { ...chartConfig };
      newChartConfig[type] = color;
      setChartConfig(newChartConfig);

      const data = {
        brand_colors: newChartConfig,
      };

      debouncedUpdateUserAPI(data);
    },
    [chartConfig, debouncedUpdateUserAPI]
  );

  const onGenerateChart = async () => {
    setIsLoading(true);
    setChartData({});

    try {
      const body = {
        brand_color: chartConfig.brand_color,
        font_color: chartConfig.font_color,
        background_color: chartConfig.background_color,
        prompt: messageForReview,
        chartType: chartType,
      };
      const res = await generateChart(body);

      setResultChartType(res.type);
      setChartData(res.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      alert("Error generating chart");
    }
  };

  useEffect(() => {
    if (
      userState &&
      userState.brand_colors &&
      userState.brand_colors.brand_color &&
      userState.brand_colors.font_color &&
      userState.brand_colors.background_color
    ) {
      setChartConfig({
        brand_color: userState.brand_colors.brand_color,
        font_color: userState.brand_colors.font_color,
        background_color: userState.brand_colors.background_color,
      });
    }
  }, [userState]);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Sidebar currentPage={"charts"} />
      <div className="compliance-review-section">
        <div className="compliance-review-container">
          {isLoading && <BouncingDots />}

          <div className="compliance-review-content-container">
            <p className="compliance-review-header-text">Generate Chart</p>

            <div>
              <div className="compliance-review-use-case-container">
                <div className="input-group">
                  <p className="use-case-header-text">Brand Logo:</p>
                  <ImageLogoInput
                    onImageSelect={onImageSelect}
                    brand_logo={logo}
                  />
                </div>
                <div className="input-group">
                  <p className="use-case-header-text">Select Chart:</p>

                  <CustomDropdown
                    valueList={[
                      "any",
                      "area",
                      "bar",
                      "bubble",
                      "doughnut",
                      "Pie",
                      "line",
                      "mixed",
                      "polarArea",
                      "radar",
                      "scatter",
                    ]}
                    textList={[
                      "ANY",
                      "Area Chart",
                      "Bar Chart",
                      "Bubble Chart",
                      "Doughnut",
                      "Pie Chart",
                      "Line Chart",
                      "Mixed Chart",
                      "Polar Area Chart",
                      "Radar Chart",
                      "Scatter Chart",
                    ]}
                    value={chartType}
                    sendDataToParent={(val) => setChartType(val)}
                    customStyle={{ width: "150px" }}
                  />
                </div>

                <div className="input-group">
                  <p className="use-case-header-text">Brand Color:</p>
                  <CustomColorInput
                    value={chartConfig.brand_color}
                    sendDataToParent={(data) =>
                      updateColors(data, "brand_color")
                    }
                    customStyle={{ width: "150px" }}
                  />
                </div>

                <div className="input-group">
                  <p className="use-case-header-text">Font Color:</p>
                  <CustomColorInput
                    value={chartConfig.font_color}
                    sendDataToParent={(data) =>
                      updateColors(data, "font_color")
                    }
                    customStyle={{ width: "150px" }}
                  />
                </div>

                <div className="input-group">
                  <p className="use-case-header-text">Background Color:</p>
                  <CustomColorInput
                    value={chartConfig.background_color}
                    sendDataToParent={(data) =>
                      updateColors(data, "background_color")
                    }
                    customStyle={{ width: "150px" }}
                  />
                </div>
              </div>
            </div>

            <p className="message-for-review-header-text">Add Data Points:</p>

            <CustomTextArea
              text={messageForReview}
              sendDataToParent={(data) => setMessageForReview(data)}
              rows={3}
              placeholderText={
                "Example: Combining various assets, mutual funds have seen an average annual return of 7% from 2020-2023"
              }
            />
            <div className="submit-review-button-container">
              <CustomButton
                buttonText={"Generate Chart"}
                onClick={onGenerateChart}
                disabled={messageForReview === "" || isLoading}
              />
            </div>
          </div>

          {isLoading && (
            <div className="document-review-progress-container">
              <CircularProgressWithLabel />
            </div>
          )}

          <div className="compliance-review-result-container">
            {chartData &&
              chartData.datasets &&
              chartData.datasets.length > 0 && (
                <RenderChart
                  id="chart"
                  chartData={chartData}
                  chartType={resultChartType}
                  font_color={chartConfig.font_color}
                  background_color={chartConfig.background_color}
                  brand_logo={logo}
                />
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chart;
