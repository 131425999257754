
import { PayloadAction, createSlice } from "@reduxjs/toolkit";



export interface DocumentAnalysisState {
    readingInstructions: string | null;
    reviewInstructions: string | null;
    finalReview: string | null;
}

const initialState: DocumentAnalysisState = {
    readingInstructions: null,
    reviewInstructions: null,
    finalReview: null,
}

export const documentAnalysisSlice = createSlice({
    name: 'documentAnalysis',
    initialState,
    reducers: {
        setReadingInstructions: (state, action: PayloadAction<string>) => {
            state.readingInstructions = action.payload
        },
        setReviewInstructions: (state, action: PayloadAction<string>) => {
            state.reviewInstructions = action.payload
        },
        setFinalReview: (state, action: PayloadAction<string>) => {
            state.finalReview = action.payload
        },
    },
})

export const { setReadingInstructions, setReviewInstructions, setFinalReview } = documentAnalysisSlice.actions






export default documentAnalysisSlice.reducer

// import { SET_REVIEW_INSTRUCTIONS, SET_READING_INSTRUCTIONS, SET_FINAL_REVIEW } from '../actions/documentAnalysis';

// const initialState = {
//     readingInstructions: null,
//     reviewInstructions: null,
//     finalReview: null,
// }

// export default (state = initialState, action) => {

//     switch (action.type) {
//         case SET_REVIEW_INSTRUCTIONS:
//             return {
//                 ...state,
//                 readingInstructions: action.readingInstructions,
//             };
//         case SET_READING_INSTRUCTIONS:
//             return {
//                 ...state,
//                 reviewInstructions: action.reviewInstructions,
//             };
//         case SET_FINAL_REVIEW:
//             return {
//                 ...state,
//                 finalReview: action.finalReview,
//             };
//         default:
//             return state;
//     }

// }