import React, { useState, useEffect, useRef } from "react";

import "./CustomTextArea.css";

const CustomTextArea = ({
  text, maxChars, sendDataToParent, minHeight, maxHeight, rows, customContainerStyle, placeholderText, customTextAreaStyle
}) => {
  const [message, setMessage] = useState(text ? text : "");
  const textAreaRef = useRef(null);

  const handleChange = (evt) => {
    const val = evt.target?.value;
    if (maxChars !== undefined && val.length > maxChars) return;
    setMessage(val);
    sendDataToParent(val);
  };

  // Autosize the textarea
  const useAutosizeTextArea = (textAreaRef, message) => {
    useEffect(() => {
      const textAreaElement = textAreaRef.current;
      if (textAreaElement) {
        const savedScrollPos = window.scrollY;

        // We need to reset the height momentarily to get the correct scrollHeight for the textarea
        textAreaElement.style.height = "0px";
        const scrollHeight = textAreaElement.scrollHeight;

        // Handle the rows input to set a minimum height for the textarea
        //const rows = props.rows;
        //const minHeight = rows === undefined ? 1 : rows;
        const minHeightPx = minHeight === undefined ? 118 : minHeight;

        const maxHeightPx = maxHeight === undefined ? 1000 : maxHeight;

        // We then set the height directly, outside of the render loop
        textAreaElement.style.height =
          Math.min(maxHeightPx, Math.max(scrollHeight, minHeightPx)) + "px";

        // Restore the scroll position
        window.scrollTo(0, savedScrollPos);
      }
    }, [textAreaRef, message]); // Depend on textAreaRef itself, not its current value
  };

  useAutosizeTextArea(textAreaRef, message);

  useEffect(() => {
    setMessage(text);
  }, [text]);

  return (
    <div
      className="custom-text-area-section"
      style={customContainerStyle}
    >
      <div className="custom-text-area-container">
        <textarea
          className="custom-text-area"
          style={customTextAreaStyle}
          onChange={handleChange}
          placeholder={
            placeholderText === undefined
              ? "Enter text"
              : placeholderText
          }
          ref={textAreaRef}
          rows={rows === undefined ? 1 : rows}
          value={message === undefined ? "" : message}
        />
        {message && message.length > 0 && maxChars !== undefined && (
          <p className={`light-font-light`}>
            {message.length}/{maxChars} characters
          </p>
        )}
      </div>
    </div>
  );
};

export default CustomTextArea;
