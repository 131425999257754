import React, { useState, useEffect } from 'react';

import CustomModal from './CustomModal';

import "./QueriesAndResultsModal.css";


export const QuerySearchCard = ({ searchQueries, theme }) => {

    return (
        <div className="query-search-card-section">
            {searchQueries.map((queryData, index) =>
                <div className={`query-search-card-container-${theme}`} key={index}>
                    <div>
                        <p className={`regular-font-${theme}`}>{queryData["query"]} {queryData["knowledge_base"] === undefined ? "" : "->"} </p>
                    </div>
                    <div>
                        <p className={`semi-bold-font-${theme}`} style={{ marginLeft: "3px" }}>
                            {queryData["knowledge_base"] === undefined ? "" : queryData["knowledge_base"]["title"]}
                        </p>
                    </div>
                </div>
            )}
        </div>
    )

}


export const QueryResultsCard = ({ result, theme }) => {

    return (
        <div className={`query-result-card-container-${theme}`}>
            <div className="query-result-card-header-row">
                <p className={`medium-font-${theme}`} style={{ fontSize: "16px", color: "#fff" }}>From - </p>
                <div className={`query-result-title-container-${theme}`}>
                    <p className={`query-result-title-text-${theme} ${result.url !== undefined ? "query-result-link" : ""}`}
                        onClick={() => result.url === undefined ? console.log("") : window.open(result.url, "_blank")}>
                        {result.document === undefined ? (result.url === undefined ? result.title : result.url) : result.document.title}
                    </p>
                </div>
            </div>
            <div>
                <p className={`regular-font-${theme}`} style={{ whiteSpace: "pre-wrap" }}>
                    {result.content}
                </p>
            </div>
        </div>
    )

}


const QueriesAndResultsModal = ({ queries, allResults, citedResults, onClose, theme, selectedSource }) => {

    const [resultTypeShown, setResultTypeShown] = useState("cited");
    const [results, setResults] = useState(citedResults);

    console.log("citedResults", citedResults);
    console.log("allResults", allResults);

    function toggleResults(newType) {
        setResultTypeShown(newType);
        if (newType === "cited") {
            setResults(citedResults);
        } else {
            setResults(allResults);
        }
    }

    useEffect(() => {
        if (selectedSource !== null) {
            // Find the index of the selected source in the list of sources
            const sourceIndex = citedResults.findIndex((source) => source["title"] === selectedSource["title"]);
            // Scroll to that id
            const element = document.getElementById(`chat-result-${sourceIndex}`);
            element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [selectedSource])

    return (
        <CustomModal closeModal={onClose}
            customMainColStyle={{ paddingTop: "10px", overflowY: "scroll" }}
            customModalContainerStyle={{ maxHeight: "90vh", width: "80vw", maxWidth: "80vw", paddingBottom: "40px" }}>
            <div style={{ width: "100%" }}>

                <div>
                    <p className={`header-font-${theme}`} style={{ marginBottom: "10px" }}>
                        Search queries
                    </p>
                    <QuerySearchCard searchQueries={queries} theme={theme} />
                </div>
                <div style={{ marginTop: "35px", paddingBottom: "20px" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <p className={`chat-result-type-text-${theme}${resultTypeShown == "cited" ? "-selected" : ""}`}
                            style={{ marginBottom: "15px" }} onClick={() => toggleResults('cited')}>
                            {`Cited results (${(citedResults == undefined ? "0" : citedResults.length)})`}
                        </p>
                    </div>
                    {results != undefined && results.map((result, index) =>
                        <div key={index} id={`chat-result-${index.toString()}`}>
                            <QueryResultsCard
                                result={result}
                                theme={theme} />
                        </div>
                    )}
                </div>

            </div>

        </CustomModal>
    )

}


export default QueriesAndResultsModal;