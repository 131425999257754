import React, { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { IoCloseOutline } from "react-icons/io5";
import { BsFillGearFill } from "react-icons/bs";
import { MdError } from "react-icons/md";
import { AiFillCheckCircle } from "react-icons/ai";
import Checkbox from "@mui/material/Checkbox";

//import Pagination from '../general/Pagination';
import SortArrow from "./SortArrow";

import { sortFilesByTitle, sortFilesByTimestamp } from "../logic/knowledge";
import { formatEpochToDate } from "../utils/dateFormatting";

import "./FileList.css";

export const FileList = ({
  files,
  selectedFiles,
  handleCheckboxClick,
  onViewFileClick,
  onEditFileClick,
}) => {
  const theme = { theme: "light" };

  const numPerPage = 1000;
  const [currentPage, setCurrentPage] = useState(1);
  const [displayedFiles, setDisplayedFiles] = useState([]);

  const [sortMethod, setSortMethod] = useState("date");
  const [sortDirection, setSortDirection] = useState("descending");
  function showNextPage() {
    setCurrentPage(currentPage + 1);
    // Update the list of files to show
    setDisplayedFiles(
      files.slice(
        (currentPage + 1) * numPerPage - numPerPage,
        (currentPage + 1) * numPerPage
      )
    );
  }

  function showPreviousPage() {
    setCurrentPage(currentPage - 1);
    // Update the list of files to show
    setDisplayedFiles(
      files.slice(
        (currentPage - 1) * numPerPage - numPerPage,
        (currentPage - 1) * numPerPage
      )
    );
  }

  function showSpecificPage(pageNumber) {
    setCurrentPage(pageNumber);
    // Update the list of files to show
    setDisplayedFiles(
      files.slice(pageNumber * numPerPage - numPerPage, pageNumber * numPerPage)
    );
  }

  function formatStatus(vectorizationStatus) {
    if (vectorizationStatus === "FAILED") {
      return "Failed";
    } else if (vectorizationStatus === "COMPLETE") {
      return "Complete";
    } else {
      return "Pending";
    }
  }

  function sortFiles(direction, method) {
    // Sort the knowledge bases by title

    let sortedFiles = files;
    if (method == "name") {
      sortedFiles = sortFilesByTitle(files, direction);
    } else {
      sortedFiles = sortFilesByTimestamp(files, direction);
    }
    // Set the displayed knowledge bases, resetting to the first page
    setCurrentPage(1);
    setDisplayedFiles(sortedFiles.slice(0, numPerPage));
  }

  useEffect(() => {
    if (files && files.length > 0) {
      setDisplayedFiles(
        files.slice(
          currentPage * numPerPage - numPerPage,
          currentPage * numPerPage
        )
      );
    } else {
      setDisplayedFiles([]);
    }

    // setDisplayedFiles(files.slice(currentPage * numPerPage - numPerPage, currentPage * numPerPage));
  }, [files]);

  return (
    <div className="file-list-section">
      <div className="file-list-container">
        <div className="file-list-header-row">
          <div className="file-list-name-col">
            <SortArrow
              text={"Name"}
              column={"name"}
              sortMethod={sortMethod}
              sortDirection={sortDirection}
              onClick={(direction) => {
                setSortMethod("name");
                setSortDirection(direction);
                sortFiles(direction, "name");
              }}
            />
          </div>

          <div className="file-list-added-date-col">
            <SortArrow
              text={"Date added"}
              column={"date"}
              sortMethod={sortMethod}
              sortDirection={sortDirection}
              onClick={(direction) => {
                setSortMethod("date");
                setSortDirection(direction);
                sortFiles(direction, "date");
              }}
            />
          </div>
          <div className="file-list-status-header-col">
            <p className={"file-list-status-header-text"}>Status</p>
          </div>
        </div>

        {displayedFiles.map((file, index) => (
          <div className={`file-list-row-${theme.theme}`} key={index}>
            <div>
              <Checkbox
                checked={
                  selectedFiles[file.id] === undefined
                    ? false
                    : selectedFiles[file.id]
                }
                sx={{
                    color: 'grey', // Color of the Checkbox when it's not checked
                    '&.Mui-checked': {
                      color: '#fff', // Color of the Checkbox when it's checked
                    },
                  }}
                onChange={() => handleCheckboxClick(file.id)}
              />
            </div>

            <div className="file-list-name-col">
              <p className={`file-list-name-text-${theme.theme}`}>
                {file.document_type === "url"
                  ? file.link_to_source
                  : file.title}
              </p>
            </div>
            <div className="file-list-added-date-col">
              <p className={`medium-font-${theme.theme}`}>
                {formatEpochToDate(file.created_on)}
              </p>
            </div>
            <div className="file-list-status-col">
              <p className={`medium-font-${theme.theme}`}>
                {formatStatus(file.vectorization_status)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

/*
<Pagination
                numDocuments={files.length}
                numPerPage={numPerPage}
                currentPage={currentPage}
                onNextClick={() => showNextPage()}
                onBackClick={() => showPreviousPage()}
                onPageNumberClick={(pageNumber) => showSpecificPage(pageNumber)}
            />
            */

export const NewFileList = ({ files, fileUploadStatuses, removeFile }) => {
  const theme = { theme: "light" };

  return (
    <div className="file-list-section">
      {files.map((file, index) => (
        <div className={`new-file-list-row-${theme.theme}`} key={index}>
          <div className="new-file-list-name-col">
            {fileUploadStatuses[index] !== "uploaded" && (
              <div className="new-file-list-status-col">
                {fileUploadStatuses[file.name] === "error" && (
                  <IconContext.Provider
                    value={{ className: "new-file-error-icon" }}
                  >
                    <MdError />
                  </IconContext.Provider>
                )}

                {(fileUploadStatuses[file.name] === "loading" ||
                  fileUploadStatuses[file.name] === "processing") && (
                  <div className="new-file-spinner-container">
                    <div className="new-file-loading-spinner"></div>
                  </div>
                )}

                {fileUploadStatuses[file.name] === "success" && (
                  <IconContext.Provider
                    value={{ className: "new-file-success-icon" }}
                  >
                    <AiFillCheckCircle />
                  </IconContext.Provider>
                )}
              </div>
            )}
            <p className={`file-list-name-text-${theme.theme}`}>{file.name}</p>
          </div>

          <div className="file-list-select-col"></div>

          <div className="file-list-remove-col">
            {(fileUploadStatuses[file.name] === "uploaded" ||
              fileUploadStatuses[file.name] === "error") && (
              <IconContext.Provider
                value={{ className: `new-file-delete-icon-${theme.theme}` }}
              >
                <IoCloseOutline onClick={() => removeFile(file)} />
              </IconContext.Provider>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

/*
{props.fileUploadStatus === 'uploaded' &&
                            <IconContext.Provider
                                value={{ className: "new-file-delete-icon" }}>
                                <IoCloseOutline onClick={() => props.removeFile(props.file)} />
                            </IconContext.Provider>
                        }

                        {props.fileUploadStatus === 'processing' &&
                                <IconContext.Provider
                                    value={{ className: "new-file-success-icon" }}>
                                    <AiFillCheckCircle />
                                </IconContext.Provider>
                            }
                        */
