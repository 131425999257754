import { PayloadAction, createSlice } from "@reduxjs/toolkit";


export interface KnowledgeState {
    knowledgeBases: any,
    knowledgeBaseFiles: any,
}

const initialState: KnowledgeState = {
    knowledgeBases: null,
    knowledgeBaseFiles: null,
}

export const knowledgeSlice = createSlice({
    name: 'knowledge',
    initialState,
    reducers: {
        setKnowledgeBases: (state, action: PayloadAction<any>) => {
            state.knowledgeBases = action.payload
        },
        setKnowledgeBaseFiles: (state, action: PayloadAction<any>) => {
            state.knowledgeBaseFiles = action.payload
        },
    },
})


export const { setKnowledgeBases, setKnowledgeBaseFiles } = knowledgeSlice.actions


export default knowledgeSlice.reducer