import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "./BrowsePrompts.css";

Modal.setAppElement("#root"); // Replace '#root' with your app root element ID

const BrowsePrompts = ({ isOpen, onRequestClose, categories, onUsePrompt }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  useEffect(() => {
    // Select the first category and its first prompt by default
    if (categories && categories.length > 0) {
      const firstCategory = categories[0];
      setSelectedCategory(firstCategory);
      setSelectedPrompt(firstCategory.categories[0].prompts[0]);
      setSelectedSubcategory(firstCategory.categories[0]);
    }
  }, [categories]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Prompts Dialog"
    >
      <div className="prompts-dialog">
        <div className="prompts-header-wrapper">
          <span>Prompts</span>
        </div>

        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <div className="prompts-sidebar">
            <div className="prompts-selected-wrapper">
              <span className="prompts-selected-prompt">
                Preview (Selected Prompt)
              </span>
              <div className="prompts-selected-title">
                {selectedPrompt && selectedPrompt.title}
              </div>
            </div>

            <div
              className="prompts-button"
              onClick={() => onUsePrompt(selectedPrompt)}
            >
              Use Prompt
            </div>
          </div>

          <div className="prompts-main">
            <div className="prompts-prompts">
              {selectedSubcategory &&
                selectedSubcategory.prompts.map((prompt) => (
                  <button
                    key={prompt.id}
                    onClick={() => setSelectedPrompt(prompt)}
                    className={`prompt-button ${
                      selectedPrompt === prompt ? "selected" : ""
                    }`}
                  >
                    {prompt.title}
                  </button>
                ))}
            </div>
          </div>
          <div className="prompts-subcategory">
            {selectedCategory &&
              selectedCategory.categories.map((prompt) => (
                <button
                  key={prompt.id}
                  onClick={() => {
                    setSelectedSubcategory(prompt);

                    setSelectedPrompt(prompt.prompts[0]);
                  }}
                  className={`prompt-button ${
                    selectedSubcategory === prompt ? "selected" : ""
                  }`}
                >
                  {prompt.title}
                </button>
              ))}
          </div>

          <div className="prompts-sidebar">
            {categories.map((category) => (
              <button
                key={category.id}
                onClick={() => {
                  setSelectedCategory(category);

                  setSelectedSubcategory(category.categories[0]);
                  setSelectedPrompt(category.categories[0].prompts[0]);
                }}
                className={`category-button ${
                  selectedCategory === category ? "selected" : ""
                }`}
              >
                {category.title}
              </button>
            ))}
          </div>

          <button onClick={onRequestClose} className="close-modal-button">
            X
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default BrowsePrompts;
