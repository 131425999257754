import { useState, useEffect } from "react";

import { formatEpochToDate } from '../utils/dateFormatting';
import * as documentReviewServices from '../services/documentReview';
import { useAppDispatch, useAppSelector } from "../hooks.ts";
import { setFinalReview, setReadingInstructions, setReviewInstructions } from "../features/document/documentSlice.ts";


export function getDocumentReviewParams(useCase) {

    const FIRNA_REP_REF_KB_ID = '49fb1557-dc03-49a7-9f31-cdaf059fb43b'    // FINRA Registered Representative Rules and Regulations (FINRA-only Compliance)
    const RIA_REF_KB_ID = '64be5a17-d2b0-4ca8-951a-eeced0a0b9d0'          // Registered Investment Advisor (or RIA) Rules and Regulations (SEC-only Compliance)
    const HYBRID_RIA_REF_KB_ID = '7df1f6b3-7f22-419e-b1bf-faaad7acbe1f'   // Hybrid Registered Investment Advisor (or Hybrid RIA) Rules and Regulations (FINRA and SEC Compliance)

    const RIA_REVIEW_ARGS = {
        reference_knowledge_base_ids: [RIA_REF_KB_ID],
        active_reading_instructions: `You are a compliance expert tasked with reviewing marketing text written by a Registered Investment Advisor (RIA) to ensure it complies with SEC rules and regulations. Your goal is to identify any potential issues or violations that may conflict with the SEC marketing compliance rules and provide clear, actionable feedback.

        When analyzing the marketing text, follow this chain of thought:
        
        Understand the Context: Begin by understanding the purpose of the marketing text. What is the advisor trying to convey? Who is the target audience?
        
        Identify Potential Claims: Look for any statements or claims made in the text. This includes performance results, guarantees, or any other assertions about the advisor’s services.
        
        Check for Substantiation: Determine if the claims made in the text are substantiated. Is there evidence provided to back up the claims? Are the claims realistic and reasonable?
        
        Review Disclosures: Check if the text includes necessary disclosures. This might include disclaimers about past performance not being indicative of future results, risks associated with investments, and other standard regulatory disclosures.
        
        Evaluate the Tone and Language: Assess the tone and language used in the text. Is it misleading, exaggerated, or overly promotional? Does it create unrealistic expectations for potential clients?
        
        Compliance with Specific SEC Rules: Ensure the text complies with specific SEC rules, such as:
        
        Rule 206(4)-1: Governing advertising by investment advisers.
        Prohibition on Testimonials: Ensure no client testimonials are used unless they comply with the amended Marketing Rule requirements.
        Performance Advertising: Verify that performance results are presented fairly and are not misleading.
        Provide Clear Feedback: Identify any potential issues or violations and explain why they may conflict with SEC rules. Offer clear, actionable recommendations for how to correct these issues.
        
        Summarize Key Findings: Conclude with a summary of the key findings and a revised version of the uploaded text that considers the most critical areas for revision, if necessary.
        `,

        final_review_instructions: `You are a compliance expert conducting a final review of marketing text written by a Registered Investment Advisor (RIA) to ensure it complies with SEC rules and regulations. Your task is to clearly list any statements or potential issues that are not compliant with SEC marketing rules and regulations. You must state what part of the text is not compliant with SEC regulations, why that part of the original text is not compliant, and suggest a solution for every flagged issue. If there are no issues, state why the document is in compliance with SEC marketing regulations for a Registered Investment Advisor. Finally, always conclude with a revised version of the text you are reviewing, if necessary.

        When conducting your final review, follow this chain of thought:
        
        Identify the Key Statements: Review the marketing text and list all key statements, especially those related to performance, guarantees, client testimonials, or services offered.
        
        Assess Compliance: Evaluate each statement for compliance with SEC rules and regulations, focusing on:
        
        Rule 206(4)-1: Governing advertising by investment advisers.
        Prohibition on Testimonials: Ensuring client testimonials comply with the amended Marketing Rule requirements.
        Performance Advertising: Verifying that performance results are presented fairly and are not misleading.
        Identify Non-Compliant Statements: For each non-compliant statement, specify what part of the text is not compliant, why it is not compliant, and the specific SEC regulation it violates.
        
        Suggest Solutions: Provide clear, actionable solutions for each non-compliant statement. Suggest how to revise the text to ensure compliance while maintaining the original intent as much as possible.
        
        Confirm Compliance: If there are no issues, explain why the document is in compliance with SEC marketing regulations. Highlight the adherence to key compliance principles..
        
        Revised text: Remember to always write a revised text if necessary with the necessary disclaimers, solutions  and modifications you've recommended.
        `,
        auto_query_guidance: 'Look for SEC marketing rules and regulations applicable to a Registered Investment Advisor who must comply with SEC rules and regulations.'
    }

    // FINRA Registered Representative Rules and Regulations (FINRA-only Compliance)
    const FIRNA_REP_REVIEW_ARGS = {
        reference_knowledge_base_ids: [FIRNA_REP_REF_KB_ID],
        active_reading_instructions: `You are a compliance expert tasked with reviewing marketing text written by a FINRA Registered Representative to ensure it complies with FINRA rules and regulations. Your goal is to identify any potential issues or violations that may conflict with FINRA marketing compliance rules and provide clear, actionable feedback.

        When analyzing the marketing text, follow this chain of thought:
        
        Understand the Context: Begin by understanding the purpose of the marketing text. What is the representative trying to convey? Who is the target audience?
        
        Identify Key Statements: Look for any statements or claims made in the text. This includes performance results, guarantees, client testimonials, or any other assertions about the representative’s services.
        
        Evaluate for Misleading Information: Determine if any statements could be considered misleading, exaggerated, or unsubstantiated. Ensure that all claims are realistic and backed by evidence.
        
        Check for Required Disclosures: Ensure that the text includes necessary disclosures. This might include disclaimers about past performance not being indicative of future results, risks associated with investments, and other standard regulatory disclosures.
        
        Assess Tone and Language: Evaluate the tone and language used in the text. Is it balanced and fair, or does it create unrealistic expectations for potential clients?
        
        Compliance with Specific FINRA Rules: Ensure the text complies with specific FINRA rules, such as:
        
        Rule 2210: Governing communications with the public.
        Rule 3220: Prohibiting the use of false, exaggerated, or misleading statements or claims.
        Performance Advertising: Verify that performance results are presented fairly and are not misleading.
        Provide Clear Feedback: Identify any potential issues or violations and explain why they may conflict with FINRA rules. Offer clear, actionable recommendations for how to correct these issues.
        
        Summarize Key Findings: Conclude with a summary of the key findings and a revised version of the uploaded text that considers the most critical areas for revision, if necessary.
        `,
        final_review_instructions: `You are a compliance expert conducting a final review of marketing text written by a FINRA Registered Representative to ensure it complies with FINRA rules and regulations. Your task is to clearly list any statements or potential issues that are not compliant with FINRA marketing rules and regulations. You must state what part of the text is not compliant with FINRA regulations, why it is not compliant, and suggest a solution for every flagged issue. If there are no issues, state why the document is in compliance with FINRA marketing regulations for a FINRA Registered Representative. Finally, always conclude with a revised version of the text you are reviewing, if necessary.

        When conducting your final review, follow this chain of thought:
        
        Identify the Key Statements: Review the marketing text and list all key statements, especially those related to performance, guarantees, client testimonials, or services offered.
        
        Assess Compliance: Evaluate each statement for compliance with FINRA rules and regulations, focusing on:
        
        Rule 2210: Governing communications with the public.
        Rule 3220: Prohibiting the use of false, exaggerated, or misleading statements or claims.
        Performance Advertising: Verifying that performance results are presented fairly and are not misleading.
        Identify Non-Compliant Statements: For each non-compliant statement, specify what part of the text is not compliant, why it is not compliant, and the specific FINRA regulation it violates.
        
        Suggest Solutions: Provide clear, actionable solutions for each non-compliant statement. Suggest how to revise the text to ensure compliance while maintaining the original intent as much as possible.
        
        Confirm Compliance: If there are no issues, explain why the document is in compliance with FINRA marketing regulations. Highlight the adherence to key compliance principles.
        
        Revised text: Remember to always write a revised text if necessary with the necessary disclaimers, solutions  and modifications you've recommended.
        
        
        AutoQuery guidance:
        
        
        Look for FINRA marketing rules and regulations applicable to a Registered Representative who must comply with FINRA rules and regulations.
        `,
        auto_query_guidance: 'Look for FINRA marketing rules and regulations applicable to a Registered Representative who must comply with FINRA rules and regulations.'
    }

    // Hybrid Registered Investment Advisor(or Hybrid RIA) Rules and Regulations(FINRA and SEC Compliance)
    const HYBRID_RIA_REVIEW_ARGS = {
        reference_knowledge_base_ids: [HYBRID_RIA_REF_KB_ID],
        active_reading_instructions: `You are a compliance expert tasked with reviewing marketing text written by a Hybrid Registered Investment Advisor who must comply with both FINRA and SEC rules and regulations. Your goal is to identify any potential issues or violations that may conflict with either the SEC marketing compliance rules or the FINRA marketing compliance rules. Provide clear, actionable feedback for ensuring compliance.

        When analyzing the marketing text, follow this chain of thought:
        
        Understand the Context: Begin by understanding the purpose of the marketing text. What is the advisor trying to convey? Who is the target audience?
        
        Identify Key Statements: Look for any statements or claims made in the text. This includes performance results, guarantees, client testimonials, or any other assertions about the advisor’s services.
        
        Evaluate for Misleading Information: Determine if any statements could be considered misleading, exaggerated, or unsubstantiated. Ensure that all claims are realistic and backed by evidence.
        
        Check for Required Disclosures: Ensure that the text includes necessary disclosures. This might include disclaimers about past performance not being indicative of future results, risks associated with investments, and other standard regulatory disclosures.
        
        Assess Tone and Language: Evaluate the tone and language used in the text. Is it balanced and fair, or does it create unrealistic expectations for potential clients?
        
        Compliance with Specific SEC and FINRA Rules: Ensure the text complies with specific rules, such as:
        
        SEC Rule 206(4)-1: Governing advertising by investment advisers.
        FINRA Rule 2210: Governing communications with the public.
        Prohibition on Testimonials: Ensuring client testimonials comply with both SEC and FINRA requirements.
        Performance Advertising: Verifying that performance results are presented fairly and are not misleading under both SEC and FINRA standards.
        Provide Clear Feedback: Identify any potential issues or violations and explain why they may conflict with SEC or FINRA rules. Offer clear, actionable recommendations for how to correct these issues.
        Summarize Key Findings: Conclude with a summary of the key findings and a revised version of the uploaded text that considers the most critical areas for revision.
        `,
        final_review_instructions: `You are a compliance expert conducting a final review of marketing text written by a Hybrid Registered Investment Advisor to ensure it complies with both SEC and FINRA rules and regulations. Your task is to clearly list any potential issues from the original text that are not compliant with SEC marketing rules and regulations for a Hybrid Registered Investment Advisor or potential issues from the original text that are not compliant with FINRA marketing rules and regulations for a Hybrid Registered Investment Advisor. You must state what part of the text is non-compliant with the SEC or FINRA rules and regulations, why the original text is not compliant with the SEC or FINRA rules and regulations, and suggest a solution for every flagged issue. If there are no issues, state why the document is in compliance with SEC marketing regulations and FINRA marketing regulations for a Hybrid Registered Investment Advisor. Finally, always conclude with a revised version of the text you are reviewing, if necessary.

        When conducting your final review, follow this chain of thought:
        
        Identify the Key Statements: Review the marketing text and list all key statements, especially those related to performance, guarantees, client testimonials, or services offered.
        
        Assess Compliance: Evaluate each statement for compliance with SEC and FINRA rules and regulations, focusing on:
        
        SEC Rule 206(4)-1: Governing advertising by investment advisers.
        FINRA Rule 2210: Governing communications with the public.
        Prohibition on Testimonials: Ensuring client testimonials comply with both SEC and FINRA requirements.
        Performance Advertising: Verifying that performance results are presented fairly and are not misleading under both SEC and FINRA standards.
        Identify Non-Compliant Statements: For each non-compliant statement, specify what part of the text is not compliant, why it is not compliant, and the specific SEC or FINRA regulation it violates.
        
        Suggest Solutions: Provide clear, actionable solutions for each non-compliant statement. Suggest how to revise the text to ensure compliance while maintaining the original intent as much as possible.
        
        Confirm Compliance: If there are no issues, explain why the document is in compliance with SEC and FINRA marketing regulations. Highlight the adherence to key compliance principles.
        
        Revised text: Remember to always write a revised text if necessary. with the necessary disclaimers, solutions and modifications you've recommended.
        `,
        auto_query_guidance: 'Look for SEC and FINRA marketing rules and regulations applicable to a Hybrid Registered Investment Advisor who must comply with both SEC and FINRA rules and regulations.'
    }

    switch (useCase) {
        case "ria":
            return [
                RIA_REVIEW_ARGS['reference_knowledge_base_ids'],
                RIA_REVIEW_ARGS['active_reading_instructions'],
                RIA_REVIEW_ARGS['final_review_instructions'],
                "medium",
                "claude-3-haiku",
                "gpt-4",
                RIA_REVIEW_ARGS['auto_query_guidance']
            ];
        case "finra":
            return [
                FIRNA_REP_REVIEW_ARGS['reference_knowledge_base_ids'],
                FIRNA_REP_REVIEW_ARGS['active_reading_instructions'],
                FIRNA_REP_REVIEW_ARGS['final_review_instructions'],
                "medium",
                "claude-3-haiku",
                "gpt-4",
                FIRNA_REP_REVIEW_ARGS['auto_query_guidance']
            ];

        case "hybrid":
            return [
                HYBRID_RIA_REVIEW_ARGS['reference_knowledge_base_ids'],
                HYBRID_RIA_REVIEW_ARGS['active_reading_instructions'],
                HYBRID_RIA_REVIEW_ARGS['final_review_instructions'],
                "medium",
                "claude-3-haiku",
                "gpt-4",
                HYBRID_RIA_REVIEW_ARGS['auto_query_guidance']
            ];
        default:
            return [
                RIA_REVIEW_ARGS['reference_knowledge_base_ids'],
                RIA_REVIEW_ARGS['active_reading_instructions'],
                RIA_REVIEW_ARGS['final_review_instructions'],
                "medium",
                "claude-3-haiku",
                "gpt-4",
                RIA_REVIEW_ARGS['auto_query_guidance']
            ];
    }

}


export function formatPreviousReviews(previousReviews) {

    // First sort the reviews by created_on timestamp
    previousReviews.sort((a, b) => (a.created_on > b.created_on) ? -1 : 1)

    // First format the timestamp from epoch to a human-readable format
    let formattedReviews = []
    previousReviews.forEach((review) => {
        let timestamp = review['created_on'];
        let formattedDate = formatEpochToDate(timestamp, true);
        const title = review["response"]["title"];
        if (title === undefined || title === null || title === "") {
            // Just ignore this
            return;
        }
        formattedReviews.push({
            id: review['id'],
            date: formattedDate,
            status: review['status'],
            request: review["request"],
            response: review["response"]
        })
    })

    console.log("formattedReviews", formattedReviews)

    return formattedReviews;
}


export function usePreviousReviewsHandler(idToken, username) {

    // Get the api key and secret from session storage
    let data = sessionStorage.getItem('data');
    if (data === undefined || data === null) {
        data = null;
    } else {
        data = JSON.parse(data);
    }

    const kbID = "239aa451-cc85-447a-aa1d-4464d2f813a5"

    // const idToken = data["idToken"]
    // const username = sessionStorage.getItem('username');

    const [isLoading, setIsLoading] = useState(true);
    const [previousReviews, setPreviousReviews] = useState([]);

    let nextPageToken = null;

    async function getDocumentContent(documentId) {
        setIsLoading(true);
        const [resData, status] = await documentReviewServices.getDocument(idToken, kbID, documentId);
        console.log("resData", resData)
        setIsLoading(false);
        return [resData, status];
    }

    async function getPreviousReviews() {
        setIsLoading(true);
        const [resData, status] = await documentReviewServices.requestPreviousReviews(idToken, nextPageToken, username);
        if (status === 200) {
            const formattedReviews = formatPreviousReviews(resData['document_review_jobs']);
            setPreviousReviews(formattedReviews);
        } else {
            alert("Sorry, there was an error getting your previous reviews. Please try again later.")
        }

        setIsLoading(false);
    }

    useEffect(() => {
        getPreviousReviews()
    }, [])

    return { previousReviews, isLoading, getDocumentContent }

}


export function useDocumentReviewHandler(setIsLoading) {
    const dispatch = useAppDispatch();

    const [progress, setProgress] = useState(0);
    const [resultData, setResultData] = useState(null);
    const [showProgress, setShowProgress] = useState(false);

    // const username = sessionStorage.getItem('username');
    const username = useAppSelector(state => state.auth.user._id);


    async function runDocumentAnalysis(
        idToken, knowledgeBaseId, documentId, readingInstructions, reviewInstructions, referenceKnowledgeBaseIds = []
    ) {

        // Reset these
        setIsLoading(true);
        setResultData(null);
        setProgress(0);

        const segmentLength = "medium";
        const readingModel = "gpt-4";
        const reviewModel = "gpt-4";
        const autoQueryGuidance = "";

        const [resData, status] = await documentReviewServices.reviewDocument(
            idToken, knowledgeBaseId, documentId, referenceKnowledgeBaseIds, readingInstructions,
            reviewInstructions, segmentLength, readingModel, reviewModel, autoQueryGuidance, ""
        )

        console.log("resData", resData)
        setIsLoading(false);
        setShowProgress(true);

        const pollURL = resData.id;
        let pollCount = 0;
        if (status === 200 || status === 202) {
            // Poll for a completed status

            let documentReviewStatus = "PENDING"
            while (pollCount < 900 && documentReviewStatus !== "COMPLETE") {

                const [pollResData, pollStatus] = await documentReviewServices.pollDocumentReviewStatus(idToken, pollURL);
                console.log("pollResData", pollResData)

                if (pollStatus === 200 || pollStatus === 202) {
                    setProgress(pollResData.response.active_reading_progress_pct * 100);
                }

                // Sleep for 1 second
                await new Promise(r => setTimeout(r, 250));
                pollCount += 1;
                documentReviewStatus = pollResData["status"];

                if (documentReviewStatus === "FAILED") {
                    alert("Sorry, this document review failed. Please try again later or contact support.")
                    setShowProgress(false);
                    setIsLoading(false);
                    break;
                }

                if (pollResData.response !== undefined && pollResData.response !== null) {
                    setResultData(pollResData.response.final_review);
                }
                if (pollResData.response.final_review !== null && pollResData.response.final_review !== "") {
                    setShowProgress(false);
                }

                if (documentReviewStatus === "COMPLETE") {
                    console.log("pollResData.response.final_review", pollResData.response.final_review)
                    setResultData(pollResData.response.final_review);
                    setShowProgress(false);
                    setIsLoading(false);

                    console.log("Setting readingInstructions", readingInstructions)

                    dispatch(
                        setReadingInstructions(readingInstructions)
                    );
                    dispatch(
                        setReviewInstructions(reviewInstructions)
                    );
                    dispatch(
                        setFinalReview(pollResData.response.final_review)
                    );

                }

            }

            if (documentReviewStatus !== "COMPLETE" && documentReviewStatus !== "FAILED") {
                alert("Sorry, this document review is taking longer than expected. Please try again later.")
            }

        } else {
            setShowProgress(false);
        }

    }

    async function reviewDocument(
        knowledgeBaseId, documentId, useCase, idToken, authToken
    ) {

        const [
            referenceKnowledgeBaseIds, readingInstructions, reviewInstructions, segmentLength, readingModel, reviewModel, autoQueryGuidance
        ] = getDocumentReviewParams(useCase);

        let formattedDocumentId = documentId;
        if (documentId == "all") {

            formattedDocumentId = null;
        }

        // Reset these
        setIsLoading(true);
        setResultData(null);
        setProgress(0);

        const [resData, status] = await documentReviewServices.reviewDocument(
            idToken, knowledgeBaseId, formattedDocumentId, referenceKnowledgeBaseIds, readingInstructions,
            reviewInstructions, segmentLength, readingModel, reviewModel, autoQueryGuidance, username
        )

        setIsLoading(false);
        setShowProgress(true);

        let pollCount = 0;
        if (status === 202) {
            // Poll for a completed status

            let documentReviewStatus = "PENDING"
            while (pollCount < 900 && documentReviewStatus !== "COMPLETE") {

                const [pollResData, pollStatus] = await documentReviewServices.pollDocumentReviewStatus(idToken, resData.id);
                console.log("pollResData", pollResData)

                if (pollStatus === 200) {
                    setProgress(pollResData.response.active_reading_progress_pct * 100);
                }

                // Sleep for 1 second
                await new Promise(r => setTimeout(r, 250));
                pollCount += 1;
                documentReviewStatus = pollResData["status"];

                if (pollResData.response !== undefined && pollResData.response !== null) {
                    setResultData(pollResData.response.final_review);
                }
                if (pollResData.response.final_review !== null && pollResData.response.final_review !== "") {
                    setShowProgress(false);
                }

                if (documentReviewStatus === "FAILED") {
                    alert("Sorry, this document review failed. Please try again later or contact support.")
                    setShowProgress(false);
                    setIsLoading(false);
                    break;
                }

                if (documentReviewStatus === "COMPLETE") {
                    console.log("pollResData.response.final_review", pollResData.response.final_review)
                    setResultData(pollResData.response.final_review);
                    setShowProgress(false);
                    setIsLoading(false);
                }

            }

            if (documentReviewStatus !== "COMPLETE" && documentReviewStatus !== "FAILED") {
                alert("Sorry, this document review is taking longer than expected. Please try again later.")
            }

        } else {
            setShowProgress(false);
        }

    }

    return { reviewDocument, runDocumentAnalysis, progress, showProgress, resultData, setResultData }

}

