import { configureStore } from '@reduxjs/toolkit'
import authSlice from './features/auth/authSlice.ts'
import complianceSlice from './features/compliance/complianceSlice.ts'
// ...
import  documentAnalysisSlice  from './features/document/documentSlice.ts'


// add storage to redux
import { combineReducers } from 'redux'
import { persistReducer ,} from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import knowledgeSlice from './features/knowledge/knowledgeSlice.ts'
import chartSlice from './features/chart/chartSlice.ts'


// ...
const reducers = combineReducers({
  auth: authSlice,
  compliance: complianceSlice,
  documentAnalysis: documentAnalysisSlice,
  knowledge: knowledgeSlice,
  chart: chartSlice,
})

const persist = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persist, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // thunk: true,
      serializableCheck: false,
    }),
})



// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch