import React from 'react';
import { IconContext } from 'react-icons';
import { BiDownArrowAlt, BiUpArrowAlt } from 'react-icons/bi';

import './SortArrow.css';

const SortArrow = ({ text, column, sortMethod, sortDirection, onClick }) => {
    // column will be title, date, or size

    const isSortByColumn = sortMethod === column;
    const upOrDown = sortDirection === "ascending" ? "up" : "down";

    function handleClick() {
        if (isSortByColumn) {
            if (sortDirection === "ascending") {
                onClick("descending")
            } else {
                onClick("ascending")
            }
        } else {
            onClick("descending")
        }
    }

    return (
        <div className="sort-arrow-row" onClick={() => handleClick()}>
            <p className={`sort-by-header-text-light${isSortByColumn ? ` sort-by-header-text-selected-light` : ""}`}>
                {text}
            </p>
            <IconContext.Provider
                value={{ className: (isSortByColumn ? `sort-arrow-selected-light` : `sort-arrow-light`) }}>
                <div>
                    {(upOrDown == 'up' && isSortByColumn) ? <BiUpArrowAlt /> : <BiDownArrowAlt />}
                </div>
            </IconContext.Provider>
        </div>
    )
}

export default SortArrow;