import React, { useEffect, useState, useRef } from "react";
import { IconContext } from 'react-icons';
import { AiOutlineInfoCircle } from "react-icons/ai";

import InfoBox from './InfoBox';

import './InfoIcon.css';

const InfoIcon = () => {

    const theme = {theme: "light"}

    return (
        <div className="info-icon-container">
            <IconContext.Provider
                value={{ className: `info-icon-${theme.theme}` }}>
                <AiOutlineInfoCircle />
            </IconContext.Provider>
        </div>
    )

}

export const InfoIconWithText = ({ text, helpBoxTitle, helpBoxText, boldText, customInfoBoxStyle }) => {

    const theme = {theme: "light"}

    const boxAreaRef = useRef();
    const textAreaRef = useRef();
    const [showHelpText, setShowHelpText] = useState(false);

    useEffect(() => {
        function handleClickOutside(event) {
            if (boxAreaRef.current && (!boxAreaRef.current.contains(event.target) && !textAreaRef.current.contains(event.target))) {
                // Hide the help box when the user clicks outside of it, as long as it isn't the ellipsis
                // The box is already hidden when the ellipsis is clicked
                setShowHelpText(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [boxAreaRef, textAreaRef]);

    return (
        <div className="info-icon-container">
            <div className="info-icon-row" onClick={() => setShowHelpText(!showHelpText)} ref={textAreaRef}>
                <p className={`regular-font-${theme.theme}`}>{text}</p>
                <IconContext.Provider
                    value={{ className: `info-icon-${theme.theme}` }}>
                    <AiOutlineInfoCircle />
                </IconContext.Provider>
            </div>
            <div ref={boxAreaRef}>
                {showHelpText && <InfoBox
                    title={helpBoxTitle}
                    text={helpBoxText}
                    boldText={boldText}
                    customStyle={customInfoBoxStyle}
                />}
            </div>
        </div>
    )

}

export default InfoIcon;