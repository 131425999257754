import axios from "axios";
const baseURL = process.env.REACT_APP_BACKEND_MAIN_BASE_URL;

const token = localStorage.getItem("token");

export const generateChart = async (data) => {
  const response = await axios.post(`${baseURL}chart/generate`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};
