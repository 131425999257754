import { Bar, Line, Pie, Scatter } from "react-chartjs-2";
import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from "chart.js";
import ChartjsPluginWatermark from 'chartjs-plugin-watermark'

import { CustomButton } from "../components/CustomButton";

const RenderChart = ({
  chartData,
  chartType,
  font_color,
  background_color,
  brand_logo,
}) => {
  if (!chartData) return null;

  const downloadChart = () => {
    const canvas = document.getElementById("chart");
    const url = canvas.toDataURL("image/png");
    const a = document.createElement("a");
    a.href = url;
    a.download = "chart.png";
    a.click();
  };

  ChartJS.defaults.color = font_color || "#ffffff";

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    ChartjsPluginWatermark,
    LineElement,
    ArcElement
  );

  const logo = new Image();
  // logo.src = chartData.logo || "";

  const chartProps = {
    data: chartData,
    options: {
      // watermark: {
      //   image: "https://picsum.photos/200/300",
      //   x: 20,
      //   y: 10,
      //   width: 50,
      //   height: 50,
      //   opacity: 0.25,
      //   alignX: "left",
      //   alignY: "top",
      //   alignToChartArea: true,
      //   position: "back",
      // },
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: chartData.title || "Generated Chart",
        },
      },
    },
    plugins: [
      {
        id: "customCanvasBackgroundColor",
        beforeDraw: (chart) => {
          const { ctx } = chart;
          ctx.save();
          ctx.globalCompositeOperation = "destination-over";
          ctx.fillStyle = background_color || "#ffffff";
          ctx.fillRect(0, 0, chart.width, chart.height);
          ctx.restore();
        },
      },
    ],
  };

  console.log("brand_logo 1", brand_logo);
  if (brand_logo) {
    chartProps.options["watermark"] = {
      image: brand_logo,
      x: 20,
      y: 10,
      width: 50,
      height: 50,
      opacity: 0.25,
      alignX: "left",
      alignY: "top",
      alignToChartArea: true,
      position: "back",
    }
  }

  return (
    <div className="document-review-result-container">
      <div className="chart-header">
        <div className="chart-header-text">
          <p className={`semi-bold-font-light`}>Generated Chart</p>
        </div>

        <div style={{ display: "flex", gap: "10px" }}>
          <CustomButton
            buttonText={"Download"}
            onClick={() => downloadChart()}
            customStyle={{ width: "100px", height: "30px" }}
          />
        </div>
      </div>

      {chartType === "area" ? (
        <Line {...chartProps} id="chart" />
      ) : chartType === "bar" ? (
        <Bar {...chartProps} id="chart" />
      ) : chartType === "bubble" ? (
        <Scatter {...chartProps} id="chart" />
      ) : chartType === "doughnut" ? (
        <Pie {...chartProps} id="chart" />
      ) : chartType === "Pie" ? (
        <Pie {...chartProps} id="chart" />
      ) : chartType === "line" ? (
        <Line {...chartProps} id="chart" />
      ) : chartType === "mixed" ? (
        <Line {...chartProps} id="chart" />
      ) : chartType === "polarArea" ? (
        <Pie {...chartProps} id="chart" />
      ) : chartType === "radar" ? (
        <Line {...chartProps} id="chart" />
      ) : chartType === "scatter" ? (
        <Scatter {...chartProps} id="chart" />
      ) : null}
    </div>
  );
};

export default RenderChart;
