
export const delay = ms => new Promise(res => setTimeout(res, ms));

export function removeNonAsciiCharacters(string) {
    return string.replace(/[^\x00-\x7F]/g, "");
}

export function convertBytesToTokens(bytes) {
    // 1 token = 4 bytes
    var tokens = bytes / 4;

    // convert it to an int
    tokens = Math.floor(tokens);

    // Format it with commas
    return tokens.toLocaleString();
}

export function capitalizeFirstLetter(text) {
    // This will capitalize the first letter in each word if there are multiple
    let formattedText = "";
    let splitWords = text.split(" ");
    for (let i=0; i<splitWords.length; i++) {
        let word = splitWords[i];
        word = word.slice(0,1).toUpperCase() + word.slice(1,word.length).toLowerCase()
        formattedText += word;
        if (i < splitWords.length-1) {
            formattedText += " "
        }
    }

    return formattedText;

}

export function formatModelName(model) {
    let models = {
        "gpt-4": "GPT-4o",
        "claude-3-haiku": "Claude-3 Haiku",
        "claude-3-sonnet": "Claude-3 Sonnet",
        "claude-3-opus": "Claude-3 Opus",
        "mixtral": "Mixtral",
        "mistral-small": "Mistral Small",
        "mistral-medium": "Mistral Medium",
        "mistral-large": "Mistral Large",
    }

    return models[model];
}